import { Checkbox as ChakraCheckbox, Text } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"
import { getCheckboxStyles } from ".."

/*
    This Checkbox to be used when utilizing react-hook-form. If you are not using react-hook-form, use the components/ui/checkbox/index.tsx component instead.
*/

type _Props = {
    text?: string
    defaultChecked?: boolean
}

type Props = _Props & UseControllerProps<any>

// NOTE error state is not implemented in this component. There should always be a string above a CTA button somewhere else

export default function ControlledCheckbox({ text, defaultChecked, name, control, rules, disabled }: Props) {
    const { field } = useController<any>({
        name,
        control,
        rules,
    })

    const styles = getCheckboxStyles()

    return (
        <ChakraCheckbox defaultChecked={defaultChecked ?? false} disabled={disabled} {...field} {...styles}>
            <Text textStyle="InterBody" color="black">
                {text}
            </Text>
        </ChakraCheckbox>
    )
}
