import { Box, Image, Text } from "@chakra-ui/react"
import useImage from "@hooks/useImage"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { FC, useMemo } from "react"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { formatDollar } from "@util/stringFormatting"
import { formatShortDateTime } from "@util/dateFormating"

export const RewardDetails: FC<{ transaction: Transaction }> = ({ transaction }) => {
    const { asset, created_at_pst, increase_currency, increase_amount, action, rate, counter_asset } = transaction

    const isIssuedKohoReward = action === "koho_reward"

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const { symbol } = selectedAsset || {}

    const { image } = useImage("common/rewards.png")

    const { t } = useTranslation(["common", "transactions"])

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.youEarned")}</Text>
            <Box borderBottom={"1px solid"} borderColor={"grey.dark.10"} paddingTop={3} />
            <Box
                display={"flex"}
                flexDirection={"row"}
                py={6}
                alignItems={"center"}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                {image && (
                    <Box h="62px" w="62px" borderRadius="31px" overflow="hidden">
                        <Image src={image} alt={"rewards-icon"} height={"100%"} width={"100%"} />
                    </Box>
                )}
                <Box display={"flex"} flexDirection={"column"} flex={1} mx={3}>
                    <Text textStyle={"ManropeMediumXLarge"}>
                        {isIssuedKohoReward
                            ? t("transactions:transactionDetails.netcoinsPayRewards")
                            : t("transactions:transactionDetails.rewards")}
                    </Text>
                </Box>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.amount")}</Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                        {`${formatNumberFixedPrecision(increase_amount, Number(selectedAsset?.precision), true)} ${increase_currency}`}
                    </Text>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} mt={3}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("transactions:transactionDetails.fiatValue")}
                    </Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {`${formatDollar(String(Number(rate) * Number(increase_amount)))} ${counter_asset}`}
                    </Text>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} py={6}>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("common:date")}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} textAlign={"right"}>
                        {formatShortDateTime(new Date(created_at_pst))}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
