import { defineStyleConfig } from "@chakra-ui/react"
import { SolidVariant, SubtleVariant } from "./alerts"

const Skeleton = defineStyleConfig({
    baseStyle: {
        animationDuration: "10s",
        transitionDuration: "10s",
        //@ts-ignore
        fadeDuration: 10,
    },
})

export const Components = {
    Alert: {
        // Toasts are considered Alerts under the hood
        variants: {
            subtle: SubtleVariant,
            solid: SolidVariant,
        },
    },
    Button: {
        sizes: {
            xl: {
                h: "56px",
                fontSize: "lg",
                px: "32px",
            },
        },
    },
    Skeleton,
}
