export type PriceChangeData = {
    instrument: string
    buyPrice: string
    sellPrice: string
    channel: string
}

export enum COIN_SORT {
    PRICE_ASC = "price",
    PRICE_DESC = "-price",
    HR24PERCENT_ASC = "hr24Percent",
    HR24PERCENT_DESC = "-hr24Percent",
    MARKETCAP_ASC = "marketCap",
    MARKETCAP_DESC = "-marketCap",
}
