import { UserContext } from "@util/authUserProvider"
import { Box, Text, useTheme } from "@chakra-ui/react"
import { useContext } from "react"
import NetcoinsLogo from "@assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "@assets/svgs/netcoins/netcoins-logo-text"
import { FramerBox } from "../motion"
import { useTranslation } from "react-i18next"
import { MdArrowBack } from "react-icons/md"

type Props = {
    progress: number
    goBack: () => void
    isBackVisible: boolean
}

const HEADER_SIDES_WIDTH = "75px"

export default function Header({ progress, goBack, isBackVisible }: Props) {
    const { t } = useTranslation("common")

    const theme = useTheme()
    const { logout } = useContext(UserContext)

    return (
        <Box data-testid="header" display="flex" flexDir="column" w="full" mb="1.5rem">
            <Box
                px={{ base: "0.75rem", md: "1.5rem" }}
                py="2rem"
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
            >
                <Box
                    data-testid="back-button"
                    display="flex"
                    flexDir="row"
                    alignItems={"center"}
                    columnGap="0.75rem"
                    onClick={isBackVisible ? goBack : undefined}
                    cursor={isBackVisible ? "pointer" : "default"}
                    w={HEADER_SIDES_WIDTH}
                >
                    {isBackVisible && (
                        <>
                            <MdArrowBack size={24} color={theme.colors.bluePurple[100]} />
                            <Text
                                display={{ base: "none", md: "inline-block" }}
                                color="bluePurple.100"
                                textStyle="InterSemiboldBody"
                            >
                                {t("back")}
                            </Text>
                        </>
                    )}
                </Box>
                <Box display="flex" alignItems={"center"} columnGap="1rem">
                    <NetcoinsLogo width={31} color={theme.colors.darkBlue[100]} />
                    <Box display={{ base: "none", sm: "flex" }}>
                        <NetcoinsLogoText width={124} color={theme.colors.darkBlue[100]} />
                    </Box>
                </Box>
                <Box
                    onClick={async () => await logout()}
                    textStyle="InterSemiboldBody"
                    color="bluePurple.10"
                    _hover={{ color: "bluePurple.100" }}
                    cursor="pointer"
                    w={HEADER_SIDES_WIDTH}
                >
                    {t("logout")}
                </Box>
            </Box>
            <Box w="full" bgColor={"bluePurple.10"} h="10px" data-testid="progress-bar-container">
                <FramerBox
                    h="full"
                    bgColor="blue.100"
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    // @ts-expect-error as stated by Chakra UI v1
                    transition={{ duration: 1 }}
                />
            </Box>
        </Box>
    )
}
