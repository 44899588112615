import { TAG_TYPES as BALANCE_TAG_TYPES } from "@redux/balances/const"
import { TAG_TYPES as TRANSACTIONS_TAG_TYPES } from "@redux/transactions/const"
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@util/RTKApi"
import { API_PATH, API_SLICE_NAME, TAG_TYPES } from "./const"
import {
    CancelOrderParams,
    InstantOrderParams,
    InstantOrderResponse,
    LimitOrderParams,
    LimitOrderResponse,
    LimitQuoteParams,
    LimitQuoteResponse,
    OrderParams,
    QuoteParams,
    QuoteResponse,
    TOrderList,
} from "./types"

export const ordersApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(""),
    tagTypes: Object.values({ ...TAG_TYPES, ...BALANCE_TAG_TYPES, ...TRANSACTIONS_TAG_TYPES }),
    endpoints: (builder) => ({
        getOrders: builder.query<TOrderList, OrderParams>({
            query: ({ status, assets = "", offset = 0, limit = 10, sort = "desc" }) => {
                const params = new URLSearchParams()

                if (status) params.append("status", status)
                if (assets) params.append("assets", assets)
                if (offset !== 0) params.append("offset", offset.toString())
                if (limit !== 0) params.append("limit", limit.toString())
                if (sort) params.append("sort", sort)

                return `${API_PATH.ORDERS}?${params.toString()}`
            },
            providesTags: [{ type: TAG_TYPES.ORDERS }],
        }),
        deleteOpenOrder: builder.mutation<LimitOrderResponse, CancelOrderParams>({
            query: (params) => ({
                url: `${API_PATH.ORDERS}/${params.order_id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: TAG_TYPES.LIMIT_QUOTE },
                { type: TAG_TYPES.ORDERS },
                { type: BALANCE_TAG_TYPES.BALANCES },
                { type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS },
            ],
        }),
        getInstantQuote: builder.query<QuoteResponse, QuoteParams>({
            query: (params) => ({
                url: API_PATH.QUOTE,
                method: "POST",
                body: params,
            }),
            providesTags: [TAG_TYPES.QUOTE],
        }),
        getLimitQuote: builder.query<LimitQuoteResponse & { fee: string }, LimitQuoteParams>({
            query: (params) => ({
                url: API_PATH.LIMIT_QUOTE,
                method: "POST",
                body: params,
            }),
            transformResponse: (response: LimitQuoteResponse) => {
                return {
                    ...response,
                    fee: response.netcoins_fee,
                }
            },
            providesTags: [TAG_TYPES.LIMIT_QUOTE],
        }),
        postLimitOrder: builder.mutation<LimitOrderResponse, LimitOrderParams>({
            query: (params) => ({
                url: API_PATH.CREATE_LIMIT_ORDER,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [
                { type: TAG_TYPES.LIMIT_QUOTE },
                { type: TAG_TYPES.ORDERS },
                { type: BALANCE_TAG_TYPES.BALANCES },
                { type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS },
            ],
        }),
        postInstantOrder: builder.mutation<InstantOrderResponse, InstantOrderParams>({
            query: (params) => ({
                url: API_PATH.CREATE_INSTANT_ORDER,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [
                { type: TAG_TYPES.QUOTE },
                { type: TAG_TYPES.ORDERS },
                { type: BALANCE_TAG_TYPES.BALANCES },
                { type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS },
            ],
        }),
    }),
})

export const {
    useGetOrdersQuery,
    useLazyGetInstantQuoteQuery,
    useLazyGetLimitQuoteQuery,
    usePostLimitOrderMutation,
    usePostInstantOrderMutation,
    useDeleteOpenOrderMutation,
} = ordersApi
