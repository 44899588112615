import useColorFormatConverter from "@theme/useColorFormatConverter"
import React from "react"

export type IconProps = {
    size: number
    color: string
}

export type IconComponent = React.ComponentType<IconProps>

export type IconWrapperProps = {
    icon: IconComponent
    size?: number
    color?: string
}

const IconWrapper: React.FC<IconWrapperProps> = ({ icon: Icon, size = 14, color = "black" }) => {
    const colorConverter = useColorFormatConverter()

    return <Icon size={size} color={colorConverter(color)} />
}

export default IconWrapper
