import { Box } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"

const MotionBox = motion(Box)

type ProgressBarProps = {
    interval?: number
    bgColor?: string
    fillColor?: string
    onTimerComplete?: () => Promise<any>
}

const ProgressBar = ({
    interval = 6,
    bgColor = "grey.light.5",
    fillColor = "blue.100",
    onTimerComplete,
}: ProgressBarProps) => {
    const [key, setKey] = useState(0)

    const handleAnimationComplete = async () => {
        onTimerComplete && (await onTimerComplete())
        setKey((prev) => prev + 1)
    }

    return (
        <Box w="full" h="1.5" bg={bgColor} borderRadius="full" overflow="hidden">
            <AnimatePresence>
                <MotionBox
                    key={key}
                    h="full"
                    bg={fillColor}
                    borderRadius="full"
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{
                        duration: interval,
                        ease: "linear",
                    }}
                    onAnimationComplete={handleAnimationComplete}
                />
            </AnimatePresence>
        </Box>
    )
}

export default ProgressBar
