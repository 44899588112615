import MarketDetails from "@screens/dashboard/marketDetails"
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom"
import Dashboard from "screens/dashboard"
import NetcoinsPay from "screens/dashboard/netcoinsPay"
import Trade from "screens/dashboard/trade"
import Transfer from "screens/dashboard/transfer"
import OnBoarding from "screens/onboarding"
import TwoFa from "screens/twoFa"
import Landing from "../screens/landing"
import AuthRedirect from "./AuthRedirect"
import PrivateRoutes from "./PrivateRoutes"
import { ROUTE_SEGMENTS, RouteParams, ROUTES } from "./routes"
import { Transactions } from "@screens/dashboard/transactions"
import { OpenOrders } from "@screens/dashboard/openOrders"
import { getRelativePathAfterSegment } from "./route-utils"
import Profile from "@screens/dashboard/profile"
import Notifications from "@screens/dashboard/notifications"
import Settings from "@screens/dashboard/settings"
import FAQ from "@screens/dashboard/FAQ"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"

const CanadianRoute = ({ children }: { children: React.ReactElement }) => {
    const { data: userData } = useGetAccountDetailsQuery()

    if (userData?.country !== "CA") {
        return <Navigate to={ROUTES.DASHBOARD} />
    }

    return children
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={ROUTE_SEGMENTS.ROOT}>
            <Route
                path={ROUTE_SEGMENTS.LANDING}
                element={
                    <AuthRedirect>
                        <Landing />
                    </AuthRedirect>
                }
            />
            <Route
                path={ROUTE_SEGMENTS.TWO_FA}
                element={
                    <AuthRedirect>
                        <TwoFa />
                    </AuthRedirect>
                }
            />
            <Route element={<PrivateRoutes />}>
                <Route index element={<Navigate to={ROUTE_SEGMENTS.DASHBOARD} />} />
                <Route path={ROUTE_SEGMENTS.DASHBOARD} element={<Dashboard />}>
                    <Route path={ROUTE_SEGMENTS.TRADE} element={<Trade />} />
                    <Route path={`${ROUTE_SEGMENTS.TRADE}/:${RouteParams.ASSET}`} element={<MarketDetails />} />
                    <Route
                        path={getRelativePathAfterSegment(ROUTES.ASSET_TRANSACTIONS, ROUTE_SEGMENTS.DASHBOARD)}
                        element={<Transactions />}
                    />
                    <Route
                        path={getRelativePathAfterSegment(ROUTES.ASSET_OPEN_ORDERS, ROUTE_SEGMENTS.DASHBOARD)}
                        element={<OpenOrders />}
                    />
                    <Route path={ROUTE_SEGMENTS.TRANSFER} element={<Transfer />} />
                    <Route path={ROUTE_SEGMENTS.PROFILE} element={<Profile />} />
                    <Route path={ROUTE_SEGMENTS.NOTIFICATIONS} element={<Notifications />} />
                    <Route path={ROUTE_SEGMENTS.SETTINGS} element={<Settings />} />
                    <Route path={ROUTE_SEGMENTS.FAQ} element={<FAQ />} />
                    <Route
                        path={ROUTE_SEGMENTS.NETCOINS_PAY}
                        element={
                            <CanadianRoute>
                                <NetcoinsPay />
                            </CanadianRoute>
                        }
                    />
                </Route>
                <Route path={ROUTE_SEGMENTS.ONBOARDING} element={<OnBoarding />} />
                {/* TODO determine here if user should be redirected to dashboard or onboarding. base this off of user/validation data */}
                <Route path="*" element={<Navigate to={ROUTE_SEGMENTS.DASHBOARD} />} />{" "}
                {/* Redirect unknown routes to dashboard */}
            </Route>
        </Route>
    )
)

export default router
