import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { SubScreenProps } from "screens/onboarding"
import { useTranslation } from "react-i18next"
import TextInput from "components/ui/textInput"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import DetailsTypeCard from "components/ui/breadcrumbs/detailsType"
import AmplitudeClient from "sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"

type Props = {} & SubScreenProps

type FormData = {
    legal_name: string
    alt_name?: string
}

export default function BusinessName({ submitSubForm, isLoading, setUserObj, userObj }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            legal_name: userObj?.business?.legal_name || undefined,
            alt_name: userObj?.business?.alt_name || undefined,
        },
    })

    async function onSubmitForm(values: FormData) {
        await submitSubForm("/api/v2/account/business", values, () => {
            setUserObj((prev) => (prev ? { ...prev, business: { ...prev.business, ...values } } : prev))
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.BusinessName, REGISTRATION_STEP.BUSINESS_NAME)
        })
    }

    return (
        <Box w="full">
            <DetailsTypeCard isPersonal={false} />
            <Box display="flex" flexDir="column" rowGap="0.75rem" mb="1.5rem">
                <Text textStyle="ManropeMediumXLarge">{t("businessName.title")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("businessName.desc")}</Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Box display="flex" flexDir="column" rowGap="1.5rem" mb="3rem">
                    <TextInput
                        name="legal_name"
                        control={control}
                        label={t("businessName.name")}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                        placeholder={t("businessName.placeholders.name")}
                    />
                    <TextInput
                        name="alt_name"
                        control={control}
                        label={t("businessName.alternate")}
                        placeholder={t("businessName.placeholders.alternate")}
                    />
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    isLoading={isLoading}
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    w="full"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
