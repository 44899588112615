import { Box, Flex, Text } from "@chakra-ui/react"
import { Pill } from "@components/ui/pill"
import { commaStringToFloat, formatStringWithCommas } from "@util/numericalFormatting"
import React, { FormEvent, useState } from "react"
import { MdAttachMoney } from "react-icons/md"
import { FormField } from ".."

type CurrencyData = {
    symbol: string
    precision: string
}

export type CurrencySwitchFormFieldProps = {
    currencyPair: [CurrencyData, CurrencyData]
    exchangeRate: number
    balance: { quantity: number; focus: "currencyOne" | "currencyTwo" }
    placeholder?: string
    errorMessage?: string
    label?: string
    value?: { currencyOne: string; currencyTwo: string }
    onTextChange: (values: { currencyOne: string; currencyTwo: string; focus: "currencyOne" | "currencyTwo" }) => void
    required?: boolean
}

export const CurrencySwitchFormField: React.FC<CurrencySwitchFormFieldProps> = ({
    currencyPair,
    balance,
    label,
    value,
    exchangeRate,
    placeholder = "0",
    errorMessage,
    onTextChange,
    required,
}) => {
    const [currencyOne, currencyTwo] = currencyPair

    const convertCryptoToDollar = (amount: number) => {
        return amount * exchangeRate
    }

    const convertDollarToCrypto = (amount: number) => {
        return amount / exchangeRate
    }

    const formatFieldText = (value: string, focusField: "currencyOne" | "currencyTwo") => {
        if (value === "") {
            return {
                currencyOne: "",
                currencyTwo: "",
            }
        }
        const currency = focusField === "currencyOne" ? currencyOne : currencyTwo
        const parsedVal = commaStringToFloat(value, Number(currency.precision))
        let newState = { currencyOne: "", currencyTwo: "" }

        if (focusField === "currencyOne") {
            const convertedVal = convertCryptoToDollar(parsedVal)
            newState = {
                currencyOne: formatStringWithCommas(value, Number(currencyOne.precision)) ?? "",
                currencyTwo: formatStringWithCommas(String(convertedVal), Number(currencyTwo.precision)) ?? "",
            }
        } else {
            const convertedVal = convertDollarToCrypto(parsedVal)
            newState = {
                currencyOne: formatStringWithCommas(String(convertedVal), Number(currencyOne.precision)) ?? "",
                currencyTwo: formatStringWithCommas(value, Number(currencyTwo.precision)) ?? "",
            }
        }
        return newState
    }

    const handleChange = (e: FormEvent, field: "currencyOne" | "currencyTwo") => {
        const element = e.currentTarget as HTMLInputElement
        const val = element.value
        const newState = formatFieldText(val, field)
        onTextChange({ ...newState, focus: field })
    }

    const onPillPress = (percent: number) => {
        const amount = balance.quantity * percent
        const newState = formatFieldText(String(amount), balance.focus)
        onTextChange({ ...newState, focus: balance.focus })
    }

    return (
        <Box>
            <FormField
                size={"lg"}
                isRequired={required}
                mb={2}
                placeholder={placeholder}
                value={value?.currencyOne}
                label={label}
                onChange={(e) => handleChange(e, "currencyOne")}
                rightElement={
                    <Text as="span" textStyle="ManropeTiny">
                        {currencyOne.symbol}
                    </Text>
                }
                isInvalid={!!errorMessage}
            />
            <FormField
                size={"lg"}
                placeholder={placeholder}
                value={value?.currencyTwo}
                onChange={(e) => handleChange(e, "currencyTwo")}
                leftElement={<MdAttachMoney />}
                rightElement={
                    <Text as="span" textStyle="ManropeTiny">
                        {currencyTwo.symbol}
                    </Text>
                }
                errorMessage={errorMessage}
            />
            <Flex mt={6} gap={3} justifyContent={"space-between"}>
                <Pill text="25%" width={"100%"} onClick={() => onPillPress(0.25)} />
                <Pill text="50%" width={"100%"} onClick={() => onPillPress(0.5)} />
                <Pill text="75%" width={"100%"} onClick={() => onPillPress(0.75)} />
                <Pill text="Max" width={"100%"} onClick={() => onPillPress(1)} />
            </Flex>
        </Box>
    )
}
