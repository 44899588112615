import { Box, ListItem, Text, UnorderedList, useTheme, VStack } from "@chakra-ui/react"
import React from "react"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import TextInput from "components/ui/textInput"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { SMALL_SCREEN_WIDTH } from "theme/consts"
import usePasswordValidation from "hooks/usePasswordValidation"
import { MdLockOutline, MdOutlineEmail } from "react-icons/md"

type FormData = {
    email: string
    password: string
}

type Props = {
    transition: () => void
    onSubmit: (email: string, password: string) => void
    isLoading: boolean
}

export default function Signup({ transition, onSubmit, isLoading }: Props) {
    const theme = useTheme()
    const { t } = useTranslation(["login", "common"])
    const { handleSubmit, control, setError, clearErrors } = useForm<FormData>()
    const passwordValidation = usePasswordValidation()

    function submitForm(values: FormData) {
        clearErrors("password")
        const err = passwordValidation(values.password)
        if (err) {
            setError("password", { type: "custom", message: err })
        } else {
            onSubmit(values.email, values.password)
        }
    }

    return (
        <>
            <Box
                columnGap="1rem"
                paddingY="2rem"
                justifyContent={"center"}
                alignItems="center"
                w="full"
                maxW={SMALL_SCREEN_WIDTH}
                display={{ base: "flex", xl: "none" }}
                m="auto"
            >
                <NetcoinsLogo color={`${theme.colors.darkBlue[100]}`} width={31} />
                <NetcoinsLogoText color={theme.colors.darkBlue[100]} width={123} />
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center" mt={{ base: 0, sm: "6rem" }}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <VStack
                        spacing="1.5rem"
                        display="flex"
                        maxW={SMALL_SCREEN_WIDTH}
                        flexGrow={1}
                        w="full"
                        justifyContent={"center"}
                        mt={{ base: 0, xl: "4.5rem" }}
                        alignItems="flex-start"
                    >
                        <Box w="full">
                            <Text textStyle="ManropeMedium5xLarge" alignSelf="flex-start" mb="0.75rem">
                                {t("signUp.welcome")}
                            </Text>
                            <Text textStyle="InterRegularBodySmall">{t("signUp.blurb")}</Text>
                        </Box>
                        <VStack w="full" alignItems={"start"} spacing="0.75rem">
                            <TextInput
                                baseColor="white"
                                control={control}
                                name="email"
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: t("error.invalidEmail", { ns: "common" }),
                                    },
                                }}
                                placeholder={t("signUp.emailPlaceholder")}
                                size="lg"
                                leftIcon={<MdOutlineEmail color="black" size={20} />}
                            />
                            <TextInput
                                baseColor="white"
                                control={control}
                                name="password"
                                maxLength={32}
                                placeholder={t("signUp.passwordPlaceholder")}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    minLength: {
                                        value: 10,
                                        message: t("signUp.error.min"),
                                    },
                                    maxLength: {
                                        value: 32,
                                        message: t("signUp.error.max"),
                                    },
                                }}
                                size="lg"
                                leftIcon={<MdLockOutline color="black" size={20} />}
                                isPassword
                            />
                            <UnorderedList
                                textStyle="InterRegularNano"
                                listStylePos={"inside"}
                                alignSelf={"flex-start"}
                            >
                                <ListItem>{t("signUp.passMinimum")}</ListItem>
                                <ListItem>{t("signUp.passCapital")}</ListItem>
                                <ListItem>{t("signUp.passLower")}</ListItem>
                                <ListItem>{t("signUp.passNumber")}</ListItem>
                                <ListItem>{t("signUp.passSpecial")}</ListItem>
                            </UnorderedList>
                        </VStack>
                        <StandardButton
                            data-testid="create-account-button"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            isLoading={isLoading}
                        >
                            {t("signUp.createAccount")}
                        </StandardButton>
                        <Text textStyle="InterRegularBodySmall" alignSelf="flex-start">
                            {t("signUp.alreadyAccount")}&nbsp;
                            <Text as="span" onClick={transition} color="blue.100" cursor="pointer">
                                {t("signUp.signIn")}
                            </Text>
                        </Text>
                        <Box
                            p="1.5rem"
                            borderRadius="4px"
                            display="flex"
                            flexDir="column"
                            rowGap="0.75rem"
                            bgColor="grey.dark.10"
                        >
                            <Text textStyle="InterSemiboldBodySmall">{t("signUp.fraudsters")}</Text>
                            <Text textStyle="InterRegularXSmall">{t("signUp.moreFraudsters")}</Text>
                        </Box>
                    </VStack>
                </form>
            </Box>
        </>
    )
}
