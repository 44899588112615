import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Flex,
    Text,
} from "@chakra-ui/react"
import StandardButton, { StandardButtonProps } from "@components/ui/buttons/standard"
import React from "react"

export type Props = {
    isOpen: boolean
    onClose: () => void
    title: string
    children: React.ReactElement
    cancelRef: React.RefObject<HTMLButtonElement>
    buttons: StandardButtonProps[]
    err?: string
}

export default function PlainModal({ isOpen, onClose, title, cancelRef, buttons, children, err }: Props) {
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
            <AlertDialogOverlay bg="rgba(0, 0, 0, 0.20)">
                <AlertDialogContent
                    maxW="sm"
                    borderRadius="md"
                    p={3}
                    boxShadow={"0px 4px 12px 0px rgba(0, 0, 0, 0.12)"}
                    borderWidth={1}
                    borderColor="grey.light.20"
                >
                    <AlertDialogHeader padding={3} paddingBottom={0}>
                        <Text textStyle={"ManropeSemiboldBody"}>{title}</Text>
                    </AlertDialogHeader>

                    <AlertDialogBody p={3}>{children}</AlertDialogBody>

                    <AlertDialogFooter p={3} w="full" flexDir="column">
                        {err && (
                            <Text
                                w="full"
                                textStyle={"InterRegularBody"}
                                color="red.light.100"
                                textAlign="end"
                                mb={"1rem"}
                            >
                                {err}
                            </Text>
                        )}
                        <Flex
                            w="full"
                            justifyContent={buttons.length === 1 ? "flex-end" : "space-between"}
                            alignItems="center"
                        >
                            {buttons.map((button, index) => (
                                <StandardButton
                                    key={index}
                                    size={"sm"}
                                    variant={button.variant}
                                    onClick={button.onClick}
                                    type="button"
                                    isLoading={button.isLoading}
                                    isNoPadding={button.isNoPadding}
                                >
                                    {button.children}
                                </StandardButton>
                            ))}
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
