import React from "react"
import { PinInput as ChakraPinInput, HStack, PinInputField, FormControl, FormLabel } from "@chakra-ui/react"
import colors from "./theme.json"

type Props = {
    numOfFields: number
    name: string
    value: string
    onChange: (value: string) => void
    baseColor: string
    onComplete?: (value: string) => void
    size?: "xs" | "sm" | "md" | "lg"
    isAlphaNumeric?: boolean
    isHidden?: boolean
    label?: string
    error?: boolean
    otp?: boolean
}

export default function PinInput({
    numOfFields,
    name,
    value,
    baseColor,
    onChange,
    onComplete,
    size,
    isAlphaNumeric,
    isHidden,
    label,
    error,
    otp,
}: Props) {
    const palette = React.useMemo(() => {
        return colors[baseColor as keyof typeof colors]
    }, [])

    return (
        <FormControl isInvalid={!!error} w="full" py="1.5rem">
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <HStack spacing="1.5rem" justifyContent={"center"}>
                <ChakraPinInput
                    data-testid={`pin-input-${name}`}
                    size={size ?? "lg"}
                    type={isAlphaNumeric ? "alphanumeric" : "number"}
                    mask={isHidden}
                    autoFocus
                    errorBorderColor={palette.error}
                    focusBorderColor="blue.100"
                    placeholder="-"
                    onChange={onChange}
                    onComplete={(val) => onComplete && onComplete(val)}
                    otp={otp}
                >
                    {Array(numOfFields)
                        .fill(null)
                        .map((_, index) => (
                            <PinInputField
                                key={index}
                                value={value[index] || ""}
                                bgColor={error ? palette.errorBg : baseColor}
                                borderColor={error ? palette.error : "inherit"}
                                textColor={palette.text}
                                borderWidth={error ? "2px" : "1px"}
                            />
                        ))}
                </ChakraPinInput>
            </HStack>
        </FormControl>
    )
}
