import React from "react"
import { Box, BoxProps, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

type Props = {
    isPersonal: boolean
} & BoxProps

export default function DetailsTypeCard({ isPersonal, ...rest }: Props) {
    const { t } = useTranslation("onboarding", { keyPrefix: "extra" })

    return (
        <Box
            display="inline-block"
            py="0.25rem"
            px="0.75rem"
            borderRadius="0.25rem"
            bgColor="darkBlue.10"
            mb="0.75rem"
            {...rest}
        >
            <Text textStyle="ManropeSemiboldXSmall" color="darkBlue.80">
                {isPersonal ? t("personalDetails") : t("businessDetails")}
            </Text>
        </Box>
    )
}
