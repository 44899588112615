// Centralized route segments for easier lookup and debugging
export const ROUTE_SEGMENTS = {
    ROOT: "/",
    DASHBOARD: "dashboard",
    LANDING: "landing",
    TWO_FA: "twofa",
    TRADE: "trade",
    TRANSFER: "transfer",
    NETCOINS_PAY: "netcoins-pay",
    ONBOARDING: "onboarding",
    TRANSACTIONS: "transactions",
    OPEN_ORDERS: "open-orders",
    PROFILE: "profile",
    NOTIFICATIONS: "notifications",
    SETTINGS: "settings",
    FAQ: "faq",
}

export enum RouteParams {
    ASSET = "asset",
    TRANSACTION_ID = "transactionId",
}

// Compose routes using segments:
const DASHBOARD = `/${ROUTE_SEGMENTS.DASHBOARD}`
const LANDING = `/${ROUTE_SEGMENTS.LANDING}`
const TWO_FA = `/${ROUTE_SEGMENTS.TWO_FA}`
const ONBOARDING = `/${ROUTE_SEGMENTS.ONBOARDING}`
const DASHBOARD_TRADE = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}`
const DASHBOARD_TRANSFER = `${DASHBOARD}/${ROUTE_SEGMENTS.TRANSFER}`
const DASHBOARD_NETCOINS_PAY = `${DASHBOARD}/${ROUTE_SEGMENTS.NETCOINS_PAY}`
const DASHBOARD_PROFILE = `${DASHBOARD}/${ROUTE_SEGMENTS.PROFILE}`
const DASHBOARD_NOTIFICATIONS = `${DASHBOARD}/${ROUTE_SEGMENTS.NOTIFICATIONS}`
const DASHBOARD_SETTINGS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}`
const DASHBOARD_FAQ = `${DASHBOARD}/${ROUTE_SEGMENTS.FAQ}`
const MARKET_DETAILS = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}/:${RouteParams.ASSET}`
const ASSET_TRANSACTIONS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.TRANSACTIONS}`
const ASSET_OPEN_ORDERS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.OPEN_ORDERS}`

// Export routes for use in components
export const ROUTES = {
    DASHBOARD,
    LANDING,
    TWO_FA,
    ONBOARDING,
    DASHBOARD_TRADE,
    DASHBOARD_TRANSFER,
    DASHBOARD_NETCOINS_PAY,
    MARKET_DETAILS,
    ASSET_TRANSACTIONS,
    ASSET_OPEN_ORDERS,
    DASHBOARD_PROFILE,
    DASHBOARD_NOTIFICATIONS,
    DASHBOARD_SETTINGS,
    DASHBOARD_FAQ,
}
