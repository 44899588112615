import { Box, Flex, Text } from "@chakra-ui/react"
import INFO_ALERT_VARIANTS, { ENUM_INFO_ALERT_VARIANT } from "./types"
import ReactIcon, { ReactIconWrapperProps } from "@assets/svgs/ReactIconWrapper"
import { MdCheck } from "react-icons/md"

export type InfoAlertProps = {
    children: string
    variant?: ENUM_INFO_ALERT_VARIANT
    icon?: ReactIconWrapperProps
}

const InfoAlert = ({ children, variant = ENUM_INFO_ALERT_VARIANT.DEFAULT, icon }: InfoAlertProps) => {
    const styles = INFO_ALERT_VARIANTS[variant]

    return (
        <Flex w="full" borderRadius="md" alignItems="center" gap={3}>
            <Box p={2} bg={styles.bg} borderRadius="md" display="flex" alignItems="center" alignSelf="stretch">
                <ReactIcon icon={MdCheck} size={24} color={styles.color} {...icon} />
            </Box>
            <Text color="black" textStyle="InterRegularBody">
                {children}
            </Text>
        </Flex>
    )
}

export default InfoAlert
