import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Flex,
    Text,
    useTheme,
} from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { MdInfoOutline } from "react-icons/md"

export type ErrorModalProps = {
    isOpen: boolean
    onClose: () => void
    title?: string
    description?: string
}

export const ErrorModal = ({
    isOpen,
    onClose,
    title = "There was an error",
    description = "Something went wrong.",
}: ErrorModalProps) => {
    const { t } = useTranslation("common")
    const theme = useTheme()
    const internalRef = useRef<HTMLButtonElement>(null)
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={internalRef} isCentered>
            <AlertDialogOverlay bg="rgba(0, 0, 0, 0.20)">
                <AlertDialogContent maxW="sm" borderRadius="md" p={3}>
                    <AlertDialogHeader padding={3} paddingBottom={0}>
                        <Flex alignItems="center" gap={2}>
                            <Box
                                bg="red.50"
                                p={2.5}
                                borderRadius="md"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <MdInfoOutline
                                    style={{
                                        color: theme.colors.red.light["100"],
                                        verticalAlign: "middle",
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </Box>
                            <Text textStyle={"ManropeSemiboldBody"}>{title}</Text>
                        </Flex>
                    </AlertDialogHeader>

                    <AlertDialogBody px={4} py={8} borderBottomColor="grey.light.10" borderBottomWidth={1}>
                        <Text textStyle={"InterRegularBody"}>{description}</Text>
                    </AlertDialogBody>

                    <AlertDialogFooter p={3}>
                        <StandardButton
                            size={"sm"}
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            onClick={onClose}
                            type="button"
                        >
                            {t("common:ok")}
                        </StandardButton>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
