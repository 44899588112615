import { useTheme } from "@chakra-ui/react"

export default function useColorFormatConverter() {
    const theme = useTheme()

    return (colorCode: string) => {
        const strs = colorCode.split(".")
        try {
            switch (strs.length) {
                case 0:
                    return "black"
                case 1:
                    return theme.colors[strs[0]]
                case 2:
                    return theme.colors[strs[0]][strs[1]]
                case 3:
                    return theme.colors[strs[0]][strs[1]][strs[2]]
                default:
                    return "black"
            }
        } catch (e) {
            return "black"
        }
    }
}
