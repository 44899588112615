type Props = {
    width: number
    color: string
}

export default function NetcoinsLogo({ width, color }: Props) {
    return (
        <svg width={width} height={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_261_239)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3426 10.377L19.6372 15.174L22.0565 16.5565V4.59146C24.5911 6.02405 26.5779 8.25827 27.7045 10.943C28.831 13.6276 29.0336 16.6106 28.2802 19.4229C27.5269 22.2352 25.8604 24.7175 23.5427 26.4796C21.225 28.2416 18.3874 29.1836 15.4761 29.1574C14.6058 29.1568 13.7376 29.0712 12.884 28.9016V31.3002C13.7401 31.4476 14.6073 31.5216 15.4761 31.5213C19.2267 31.5129 22.8476 30.1476 25.6699 27.6775C28.4923 25.2074 30.3256 21.7995 30.8311 18.0831C31.3367 14.3667 30.4803 10.5929 28.4202 7.4587C26.3601 4.32449 23.2355 2.04161 19.6234 1.03168V12.3884L13.3126 8.74568L11.3081 7.59135L8.8819 6.20891V8.99452L11.3081 10.377H11.3426ZM11.3426 15.015L19.6372 19.7775L22.0565 21.16V18.3951L19.6372 17.0127L11.3426 12.2156L8.91641 10.8332V13.6326L11.3426 15.015ZM19.6372 21.5816L11.3426 16.7846L8.91641 15.4021V27.4017C6.38177 25.9691 4.39502 23.7349 3.26844 21.0502C2.14186 18.3655 1.93932 15.3826 2.69265 12.5702C3.44598 9.75791 5.11247 7.27565 7.43018 5.51358C9.7479 3.75151 12.5855 2.80955 15.4968 2.83576C16.3694 2.83629 17.2399 2.92195 18.0958 3.09152V0.699894C17.2373 0.552501 16.3679 0.478499 15.4968 0.478699C11.7462 0.487132 8.12534 1.85247 5.30295 4.32254C2.48055 6.79261 0.647272 10.2006 0.141754 13.917C-0.363764 17.6334 0.49267 21.4071 2.55275 24.5413C4.61283 27.6756 7.73742 29.9584 11.3495 30.9684V19.5978L19.6441 24.3949L22.0634 25.7773V22.9779L19.6441 21.5954L19.6372 21.5816Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_261_239">
                    <rect width="31.0426" height="31.0426" fill="white" transform="translate(0 0.478699)" />
                </clipPath>
            </defs>
        </svg>
    )
}
