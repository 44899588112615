type Props = {
    width: number
    color: string
}

export default function Alert({ width, color }: Props) {
    return (
        <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.41699 8.74984H7.58366V9.9165H6.41699V8.74984ZM6.41699 4.08317H7.58366V7.58317H6.41699V4.08317ZM7.00033 1.1665C3.77449 1.1665 1.16699 3.7915 1.16699 6.99984C1.16699 8.54693 1.78157 10.0307 2.87554 11.1246C3.41721 11.6663 4.06027 12.096 4.76801 12.3891C5.47574 12.6823 6.23428 12.8332 7.00033 12.8332C8.54742 12.8332 10.0312 12.2186 11.1251 11.1246C12.2191 10.0307 12.8337 8.54693 12.8337 6.99984C12.8337 6.23379 12.6828 5.47525 12.3896 4.76752C12.0965 4.05978 11.6668 3.41672 11.1251 2.87505C10.5834 2.33337 9.94038 1.90369 9.23265 1.61054C8.52491 1.31739 7.76637 1.1665 7.00033 1.1665ZM7.00033 11.6665C5.76265 11.6665 4.57566 11.1748 3.70049 10.2997C2.82532 9.4245 2.33366 8.23751 2.33366 6.99984C2.33366 5.76216 2.82532 4.57518 3.70049 3.70001C4.57566 2.82484 5.76265 2.33317 7.00033 2.33317C8.238 2.33317 9.42499 2.82484 10.3002 3.70001C11.1753 4.57518 11.667 5.76216 11.667 6.99984C11.667 8.23751 11.1753 9.4245 10.3002 10.2997C9.42499 11.1748 8.238 11.6665 7.00033 11.6665Z"
                fill={color}
            />
        </svg>
    )
}
