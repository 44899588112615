import { useCallback, useRef, useState } from "react"

/*
    Hook used for queuing AJAX requests. Does two things:
        1. Throttles requests to prevent multiple requests from being sent simultaneously
        2. Ensures that requests are processed in the order and without unnecessary duplicates
*/

interface QueueItem<T extends string> {
    type: T
    action: () => Promise<any>
}

export default function useAjaxQueue() {
    const queueRef = useRef<QueueItem<string>[]>([])
    const processingRef = useRef(false)

    const processNextRequest = async () => {
        if (processingRef.current || queueRef.current.length === 0) {
            return
        }
        const queueItemRequest = queueRef.current.shift()
        processingRef.current = true

        try {
            await queueItemRequest?.action()
        } catch (error) {
            // no op
        }

        processingRef.current = false

        if (queueRef.current.length > 0) {
            processNextRequest()
        }
    }

    const addToQueue = <T extends string>(type: T, action: () => Promise<any>) => {
        const existingRequestIndex = queueRef.current.findIndex((request) => request.type === type)
        if (existingRequestIndex) {
            queueRef.current = queueRef.current.splice(existingRequestIndex, 1)
        }
        queueRef.current.push({ type, action })
        processNextRequest()
    }

    return { addToQueue }
}
