import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import { TransactionStatus } from "@redux/transactions/types"

export const getProcessingStateVariant = (state: TransactionStatus): ENUM_CHIP_VARIANTS | undefined => {
    if (!state) {
        return undefined
    }

    if (isProcessingState(state)) {
        return ENUM_CHIP_VARIANTS.YELLOW
    }

    return undefined
}

export const isProcessingState = (state: TransactionStatus): boolean => {
    return state.toLowerCase() === "pending" || state.toLowerCase() === "processing"
}
