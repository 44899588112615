import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@util/RTKApi"
import { API_PATH, API_PATH_FAVOURITES, API_SLICE_NAME, TAG_TYPES } from "./const"
import { AssetFavourite } from "./types"

export const assetApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(API_PATH),
    tagTypes: [TAG_TYPES.ASSET_FAVOURITES],
    endpoints: (builder) => ({
        getAssetFavourites: builder.query<{ data: AssetFavourite[] }, undefined>({
            query: () => API_PATH_FAVOURITES.ASSETS_FAVOURITES,
            providesTags: (result) =>
                result?.data
                    ? [
                          ...result.data.map((assetFavourite) => ({
                              type: TAG_TYPES.ASSET_FAVOURITES,
                              id: assetFavourite.asset.symbol,
                          })),
                          TAG_TYPES.ASSET_FAVOURITES,
                      ]
                    : [TAG_TYPES.ASSET_FAVOURITES],
        }),
        addAssetFavourite: builder.mutation<{ data: AssetFavourite }, { asset_symbol: string }>({
            query: (body) => ({
                url: API_PATH_FAVOURITES.ASSETS_FAVOURITES,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: TAG_TYPES.ASSET_FAVOURITES }],
        }),

        deleteAssetFavourite: builder.mutation<{ data: AssetFavourite }, string>({
            query: (symbol) => ({
                url: API_PATH_FAVOURITES.ASSETS_FAVOURITES + `/${symbol}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: TAG_TYPES.ASSET_FAVOURITES, id: result?.data.asset.symbol }],
        }),
    }),
})

export const { useGetAssetFavouritesQuery, useAddAssetFavouriteMutation, useDeleteAssetFavouriteMutation } = assetApi
