export const IS_PROD = process.env.APP_ENV === "production"
export const POLLING_INTERVAL = 30000

export const TERMS_OF_SERVICE_URL_CA = "https://disclosures.netcoins.app/netcoins-terms-of-service-2024-05-01.pdf"
export const TERMS_OF_SERVICE_URL_US = "https://www.netcoins.com/en-us/files/terms-conditions-us.pdf"
export const PRIVACY_POLICY_URL_CA = "https://www.netcoins.com/en/files/privacy-policy-ca.pdf"
export const PRIVACY_POLICY_URL_US = "https://www.netcoins.com/files/privacy-policy-us.pdf"

export const ZENDESK_CA_LINKS = {
    ROOT: "https://netcoins.zendesk.com/hc/en-us",
    ACQUISITION_LIMITS: "https://netcoins.zendesk.com/hc/en-us/articles/31902817278484-What-are-Net-Acquisition-Limits",
}
