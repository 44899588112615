import { IconType } from "react-icons"
import { Box, Button, ButtonProps, Text } from "@chakra-ui/react"
import { useMemo } from "react"
import BUTTON_VARIANTS, { ENUM_BUTTON_VARIANTS } from "./types"
import { FIELD_ICON_SIZE } from "@components/ui/types"
import ReactIcon from "@assets/svgs/ReactIconWrapper"

export type StandardButtonProps = {
    children: string
    variant: ENUM_BUTTON_VARIANTS
    onClick?: () => void
    size?: "sm" | "md" | "lg" | "xl"
    disabled?: boolean
    isLoading?: boolean
    rightIcon?: IconType
    leftIcon?: IconType
    isNoPadding?: boolean
} & Omit<ButtonProps, "variant" | "leftIcon" | "rightIcon">

export type StandardButtonOptionalVariant = Omit<StandardButtonProps, "variant"> & {
    variant?: ENUM_BUTTON_VARIANTS
}

export default function StandardButton({
    children,
    onClick,
    type,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    size = "lg",
    variant,
    disabled = false,
    isLoading,
    isNoPadding,
    ...rest
}: StandardButtonProps) {
    const palette = useMemo(() => BUTTON_VARIANTS[variant], [variant])

    const { normal, hover, disabled: disabledColor, active, textStyleWeight, borderWidth } = palette

    const textStyle = useMemo(() => {
        if (size === "lg") {
            return `Manrope${textStyleWeight}Body`
        }

        return `Manrope${textStyleWeight}BodySmall`
    }, [size])

    return (
        <Button
            onClick={onClick}
            bg={normal.bg}
            isDisabled={disabled || isLoading}
            _disabled={{
                ...disabledColor,
                cursor: "not-allowed",
            }}
            _hover={!disabled ? hover : {}}
            _active={!disabled ? active : {}}
            borderWidth={`${borderWidth}px`}
            borderColor={normal.borderColor}
            size={size}
            type={type}
            textStyle={textStyle}
            color={normal.color}
            isLoading={isLoading}
            _loading={normal}
            px={isNoPadding ? 0 : "auto"}
            {...rest}
        >
            {LeftIcon && (
                <Box mr={2}>
                    <ReactIcon icon={LeftIcon} size={FIELD_ICON_SIZE[size ?? "md"]} />
                </Box>
            )}
            <Text textStyle={textStyle}>{children}</Text>
            {RightIcon && (
                <Box ml={2}>
                    <ReactIcon icon={RightIcon} size={FIELD_ICON_SIZE[size ?? "md"]} />
                </Box>
            )}
        </Button>
    )
}
