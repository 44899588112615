export default function CheckCircle({
    size = 48,
    bgColor = "#2CA900",
    checkColor = "white",
}: {
    size?: number
    bgColor?: string
    checkColor?: string
}) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill={bgColor} />
            <path d="M33 19L21 31L15.5 25.5L16.91 24.09L21 28.17L31.59 17.59L33 19Z" fill={checkColor} />
        </svg>
    )
}
