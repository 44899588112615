import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import {
    PRIVACY_POLICY_URL_CA,
    PRIVACY_POLICY_URL_US,
    TERMS_OF_SERVICE_URL_CA,
    TERMS_OF_SERVICE_URL_US,
} from "@util/config"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import Link from "@components/ui/link"

type Props = { [rest: string]: any }

export default function DashboardFooter({ ...rest }: Props) {
    const { t } = useTranslation("common")
    const { data } = useGetAccountDetailsQuery()

    return (
        <Box
            display="flex"
            flexDir={{ base: "column", md: "row" }}
            justifyContent={"space-between"}
            borderTopColor="grey.light.10"
            borderTopWidth="1px"
            alignItems={{ base: "flex-start", md: "center" }}
            pt="1.5rem"
            pb="3rem"
            columnGap="2rem"
            rowGap="0.75rem"
            {...rest}
        >
            <Text textStyle="InterRegularXSmall" color={"grey.light.80"}>
                {`@${new Date(Date.now()).getFullYear()} Netcoins - BIGG Digital Assets Company (CSE : BIGG) MSB: M15560893`}
            </Text>
            <Box display="flex" columnGap="0.75rem" alignItems="center">
                <Link
                    href={data?.country === "CA" ? TERMS_OF_SERVICE_URL_CA : TERMS_OF_SERVICE_URL_US}
                    text={t("terms")}
                />
                <Text textStyle="ManropeMediumXSmall" color="grey.light.70">
                    |
                </Text>
                <Link
                    href={data?.country === "CA" ? PRIVACY_POLICY_URL_CA : PRIVACY_POLICY_URL_US}
                    text={t("privacyPolicy")}
                />
            </Box>
        </Box>
    )
}
