export const formatDollar = (value: string) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(Number(value))
}

export function formatPostalCode(country: string, postalCode: string): string {
    let _postalCode = postalCode.trim()
    if (country === "CA") {
        _postalCode = postalCode.toUpperCase().replace(/-/g, " ") // remove hyphens and make it uppercase
        if (postalCode.length === 6) {
            return _postalCode.substring(0, 3) + " " + _postalCode.substring(3) // add a space after the 3rd character
        }
        return _postalCode
    } else {
        return _postalCode // here its a zip code that doesn't need to be formatted
    }
}

export function addThousandSeparator(num: string): string {
    return num ? num.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
}

export function addCommasToNumber(
    num: number | string,
    options?: { addDecimalZeroes?: boolean; precision?: number; addDollarSign?: boolean }
): string {
    const numStr = typeof num === "number" ? num.toString() : num
    const parts = numStr.split(".")
    parts[0] = addThousandSeparator(parts[0])
    if (options?.addDollarSign) {
        parts[0] = `$${parts[0]}`
    }
    if (options?.addDecimalZeroes && options?.precision) {
        if (parts.length === 1) {
            parts.push("0".repeat(options.precision))
        } else {
            parts[1] = parts[1].padEnd(options.precision, "0")
        }
    }
    return parts.join(".")
}

export function removeTrailingZeros(str: string): string {
    return str.replace(/(\.\d*?[1-9])0+$/g, "$1").replace(/\.0+$/, "")
}

export function shortenLargeNumber(num: number): string {
    const abbreviations = [
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
    ]

    const absNum = Math.abs(num)

    for (const { value, symbol } of abbreviations) {
        if (absNum >= value) {
            // Divide by the value and round to 3 decimal places
            const shortened = (num / value).toFixed(2)
            // Remove trailing zeros after the decimal point
            const formatted = parseFloat(shortened).toString()
            return `${formatted}${symbol}`
        }
    }

    // If the number is smaller than 1M, return it as is with potentially some commas
    return addCommasToNumber(num)
}
