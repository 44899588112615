import { Box, BoxProps, Text } from "@chakra-ui/react"

type PillProps = {
    text: string
} & BoxProps

export const Pill = ({ text, ...rest }: PillProps) => {
    return (
        <Box
            as={"button"}
            display="inline-block"
            px={3}
            py={1.5}
            transition="all 0.2s"
            _active={{ bg: "blue.100", color: "white" }}
            borderRadius="full"
            bg={"grey.light.5"}
            color={"grey.light.90"}
            {...rest}
        >
            <Text textStyle="ManropeSemiboldBodySmall">{text}</Text>
        </Box>
    )
}
