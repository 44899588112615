import { Icon, Input, InputGroup, InputLeftAddon, InputRightAddon } from "@chakra-ui/react"
import { FIELD_ICON_SIZE, FIELD_SIZE } from "@components/ui/types"
import React, { useMemo } from "react"
import useColorFormatConverter from "theme/useColorFormatConverter"
import SEARCHBAR_VARIANTS, { ENUM_SEARCHBAR_VARIANTS } from "./types"
import { MdClose, MdOutlineSearch } from "react-icons/md"

export type Props = {
    value?: string
    onChange: (_: string) => void
    variant?: ENUM_SEARCHBAR_VARIANTS
    placeholder?: string
    size?: "sm" | "md" | "lg"
    removeBorder?: boolean
    disabled?: boolean
    [rest: string]: any
}

export function Searchbar({
    value,
    onChange,
    variant = ENUM_SEARCHBAR_VARIANTS.PRIMARY,
    placeholder,
    size,
    disabled,
    removeBorder,
    ...rest
}: Props) {
    const colorConverter = useColorFormatConverter()

    const palette = useMemo(() => SEARCHBAR_VARIANTS[variant], [variant])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [palette]
    )

    return (
        <InputGroup
            _focusWithin={{
                borderColor: getBorderColor(true),
                ".chakra-input__left-addon": {
                    borderColor: `${palette.normal.borderColor} !important`,
                },
                ".chakra-input": {
                    borderTopColor: `${palette.normal.borderColor} !important`,
                    borderBottomColor: `${palette.normal.borderColor} !important`,
                    borderLeftColor: `transparent !important`,
                    borderRightColor: `${palette.normal.borderColor} !important`,
                },
            }}
            _hover={{
                borderColor: palette.hover.borderColor,
            }}
            borderColor={getBorderColor(false)}
            borderWidth={removeBorder ? 0 : "1px"}
            borderRadius="8px"
            h={`${FIELD_SIZE[size ?? "lg"]}px`}
            {...rest}
        >
            <InputLeftAddon
                pointerEvents="none"
                borderColor={palette?.normal.borderColor}
                h={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                borderTopWidth={removeBorder ? 0 : "1px"}
                borderBottomWidth={removeBorder ? 0 : "1px"}
                borderLeftWidth={removeBorder ? 0 : "1px"}
                borderRightWidth={0}
                bgColor={palette.normal.bg}
                paddingRight={"0 !important"}
            >
                <MdOutlineSearch size={FIELD_ICON_SIZE[size ?? "lg"]} color="black" />
            </InputLeftAddon>
            <Input
                h={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                data-testid={`input-${name}`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                placeholder={placeholder ?? "Search"}
                _placeholder={{ color: palette?.normal.placeholder }}
                fontFamily="Manrope"
                fontSize={size === "lg" ? "16px" : "14px"}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="150%"
                letterSpacing="0.15px"
                bgColor={palette.normal.bg}
                color={palette.normal.color}
                borderColor={palette.normal.borderColor}
                _disabled={{
                    borderColor: palette?.disabled.borderColor,
                }}
                borderTopWidth={removeBorder ? 0 : "1px"}
                borderBottomWidth={removeBorder ? 0 : "1px"}
                borderLeftWidth={0}
                borderRightWidth={removeBorder || value?.length ? 0 : "1px"}
                focusBorderColor="transparent"
                errorBorderColor="transparent"
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                borderBottomRightRadius={"8px"}
                borderTopRightRadius={"8px"}
                _autofill={{
                    WebkitTextFillColor: colorConverter(palette.normal.color),
                    WebkitBoxShadow: `inset 0 0 20px 20px ${
                        palette.normal.bg === "white" || palette.normal.bg === "black"
                            ? palette.normal.bg
                            : colorConverter(palette.normal.bg)
                    }`,
                }}
            />
            {!!value?.length && (
                <InputRightAddon
                    borderColor={palette?.normal.borderColor}
                    h={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                    bgColor={palette.normal.bg}
                    paddingLeft={"0 !important"}
                    onClick={() => onChange("")}
                    cursor="pointer"
                >
                    <Icon as={MdClose} size={20} color="black" />
                </InputRightAddon>
            )}
        </InputGroup>
    )
}

export default Searchbar
