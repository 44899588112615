import { format } from "date-fns"
import { formatDollar } from "@util/stringFormatting"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useMemo } from "react"
import OpenOrderRow from "@components/openOrders/openOrderRow"
import { IOrder } from "@redux/orders/types"
import { formatShortDate } from "@util/dateFormating"

export interface OpenOrdersCardProps {
    order: IOrder
    handleViewMore: () => void
    isLast: boolean
}

export const OpenOrdersCard: React.FC<OpenOrdersCardProps> = ({ order, handleViewMore, isLast }) => {
    const { date_created, total, asset, quantity, price: order_price } = order || {}

    const formattedDate = formatShortDate(new Date(date_created))

    const fiatValue = formatDollar(total)
    const price = formatDollar(order_price)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const formattedCryptoAmount = formatNumberFixedPrecision(quantity, Number(data?.precision), true)

    const isBuy = order.side.toLocaleLowerCase() === "buy"

    return (
        <OpenOrderRow
            order={{
                date: formattedDate,
                description: isBuy ? `Bought ${asset} at` : `Sold ${asset} at`,
                price,
                secAmount: fiatValue,
                mainCurrency: asset,
                secCurrency: order.counter_asset,
                mainAmount: formattedCryptoAmount,
            }}
            handleViewMore={handleViewMore}
            isLast={isLast}
        />
    )
}
