import { Box, Image, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import CopyComponent from "@components/ui/copyField"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { useGetDepositAddressQuery } from "@redux/fund/apiSlice"
import { DrawerFundContext } from "@screens/dashboard/transfer/drawerFund/DrawerFundContext"
import { useTranslation } from "react-i18next"
import { MdInfoOutline } from "react-icons/md"
import QRCode from "react-qr-code"

export const CryptoWallet: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const isSmall = useBreakpointValue({ base: true, sm: false })

    const { selectedCoin, selectedCoinData } = DrawerFundContext.useContainer()

    const { data } = useGetDepositAddressQuery({
        currency: selectedCoin?.symbol || "",
    })

    const warningMessage = selectedCoinData?.warnings?.funding

    return (
        <Box mt={7} flex={1}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithCrypto", { ns: "transfer" })}</Text>
            <Box
                display={"flex"}
                flexDirection={"row"}
                pb={3}
                borderBottomWidth={1}
                borderBottomColor={"grey.light.10"}
                mt={7}
            >
                <VStack align="flex-start" spacing={0} flex={1} alignItems={"flex-start"}>
                    <Text>{t("fund.youAreDepositing", { ns: "transfer" })}</Text>
                </VStack>
                <VStack align="flex-end" spacing={0} flex={1} mr={3}>
                    <Text textStyle={"ManropeSemiboldSmall"}>{selectedCoin?.name}</Text>
                    <Text textStyle={"ManropeSemiboldXSmall"} color="grey.light.50" _groupHover={{ color: "white" }}>
                        {selectedCoin?.symbol}
                    </Text>
                </VStack>
                <Image
                    align={"flex-end"}
                    src={selectedCoin?.imageUrl}
                    alt={selectedCoin?.name}
                    boxSize="48px"
                    borderRadius="full"
                    loading="lazy"
                />
            </Box>
            <Text mt={4}>{t("fund.depositCryptoInstr", { ns: "transfer", asset: selectedCoin?.symbol })}</Text>
            <Box display="flex" justifyContent={"center"} alignItems={"center"} py={6} mt={6}>
                <QRCode value={data?.deposit_address.address || ""} size={isSmall ? 160 : 200} />
            </Box>
            <CopyComponent text={data?.deposit_address.address || ""} />
            <Box mt={6}>
                {warningMessage && (
                    <InfoAlert
                        icon={{
                            icon: MdInfoOutline,
                        }}
                        variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                    >
                        {warningMessage}
                    </InfoAlert>
                )}
            </Box>
        </Box>
    )
}
