import countries from "@json/countries.json"

export type LiteralUnion<T extends U, U = string> = T | (U & { IGNORE_ME?: never })

export enum AmplitudeEvent {
    SCREEN_EVENT = "SCREEN_EVENT",
    BUTTON_EVENT = "BUTTON_EVENT",
    ERROR_EVENT = "ERROR_EVENT",
    REGISTRATION_EVENT = "REGISTRATION_EVENT",
    VERIFIED_EVENT = "VERIFIED_EVENT",
}

export type AmplitudeEventProps = {
    event_desc?: string
} & Record<string, unknown>

export type ScreenEventProps = {
    action_type?: LiteralUnion<"mounted">
} & AmplitudeEventProps

export type ButtonEventProps = {
    button_name: string
    action_type?: LiteralUnion<"tap">
} & AmplitudeEventProps

export type ErrorEventProps = {
    error: string
} & AmplitudeEventProps

export type RegistrationEventProps = {
    step: string
    country?: keyof typeof countries
} & AmplitudeEventProps
