import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"

export default function OnMailingList() {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("whereIncorporated.onMailingList")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                {t("whereIncorporated.onMailingListDesc")}
            </Text>
            <StandardButton
                data-testid="done-button"
                type="button"
                onClick={() => window.open("/logout", "_self")}
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="3rem"
                w="full"
            >
                {t("logout", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
