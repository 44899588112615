export const SLICE_NAME = "accountDetails"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    accountDetails: "accountDetails",
} as const

export const API_PATH = {
    account: "account",
    accountDetails: "/account",
    accountRestrictions: "/restrictions",
} as const
