import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import Select from "components/ui/select"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import AmplitudeClient from "sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import { useTranslation } from "react-i18next"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import { SubScreenProps } from "screens/onboarding"
import { Option } from "@components/ui/types"

type FormData = {
    occupation: Option<string>
}

export default function Occupation({ submitSubForm, isLoading, userObj, setUserObj }: SubScreenProps) {
    const { handleSubmit, control, setValue } = useForm<FormData>()
    const { t } = useTranslation(["onboarding", "common"])
    const { fetchWrapper } = useFetchWrapper()

    const [occupationOptions, setOccupationOptions] = React.useState<Option<string>[]>([])

    React.useEffect(() => {
        ;(async () => {
            const res = await fetchWrapper(
                `/api/v3/account/options/occupation/?country=${userObj?.country}&state=${userObj?.region}`,
                Method.GET
            )
            if (res?.success) {
                const arr: Option<string>[] = []
                for (const key in res) {
                    if (key !== "success") {
                        const value = res[key].code ?? res[key].noc
                        const label = res[key].occupation ?? res[key].title
                        arr.push({ value, label })
                    }
                }
                setOccupationOptions(arr)
                setDefaultValue(arr)
            }
        })()
    }, [])

    // Setting default values is done differently here than the other screens because the occupation options are fetched from the server
    function setDefaultValue(arr: Option<string>[]) {
        if (!userObj?.identity.occupation || !userObj.identity.occupation) return

        const _option = arr.find((occ) => {
            const val = userObj?.identity.occupation
            return val === occ.value
        })

        if (_option) {
            setValue("occupation", { value: _option.value, label: _option.label })
        }
    }

    async function onSubmit(values: FormData) {
        const { label, value } = values.occupation
        await submitSubForm(
            "/api/v2/account/identity",
            {
                occupation_title: label,
                occupation_type: value,
            },
            () => {
                setUserObj((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        identity: {
                            ...prev.identity,
                            occupation_type: label,
                            occupation: value,
                        },
                    }
                })
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.Occupation, REGISTRATION_STEP.OCCUPATION)
            }
        )
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("occupation.header")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Select
                    name="occupation"
                    control={control}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                    label={t("occupation.occupation")}
                    placeholder={occupationOptions.length ? undefined : t("loading", { ns: "common" })}
                    disabled={!occupationOptions.length}
                    options={occupationOptions}
                    size="lg"
                />
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="2.5rem"
                    w="full"
                    isLoading={isLoading}
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
