import TransactionsRow from "@components/transactions/transactionsRow"
import { TransactionCardProps } from ".."
import { formatDollar } from "@util/stringFormatting"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useMemo } from "react"
import { isFiatCurrency } from "@util/currencyHelpers"

export const Withdraw: React.FC<TransactionCardProps> = ({ transaction, viewMore, isLast }) => {
    const { decrease_amount, decrease_currency, asset, status, created_at_pst } = transaction || {}

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const decAmount = useMemo(() => {
        if (isFiatCurrency(decrease_currency)) {
            return formatDollar(decrease_amount)
        }

        const precisionToUse = data?.precisions.withdrawal || data?.precision

        return formatNumberFixedPrecision(decrease_amount, Number(precisionToUse), true)
    }, [decrease_amount, decrease_currency, data])

    return (
        <TransactionsRow
            transaction={{
                date: created_at_pst,
                description: `Withdraw ${asset}`,
                status: status,
                mainCurrency: decrease_currency,
                mainAmount: decAmount,
            }}
            handleViewMore={viewMore}
            isLast={isLast}
        />
    )
}
