import { Navigate, Outlet } from "react-router-dom"
import { ROUTES } from "./routes"
import { useAuth } from "@hooks/useAuth"

const PrivateRoutes = () => {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
        return <Navigate to={ROUTES.LANDING} />
    }

    return <Outlet />
}

export default PrivateRoutes
