import { Box, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { SubScreenProps } from ".."
import { useTranslation } from "react-i18next"
import { REG_SCREENS, REGISTRATION_STEP } from "../types"

export default function RiskStatement({ submitSubForm, isLoading, setUserObj }: SubScreenProps) {
    const { t } = useTranslation("onboarding", { keyPrefix: "riskStatement" })
    const toast = useToast()
    const [riskStatement, setRiskStatement] = React.useState<string>("")

    React.useEffect(() => {
        fetchContent()
    }, [])

    async function fetchContent() {
        try {
            const response = await fetch("/api/v3/risk-statement")
            const data = await response.json()
            const parsedContent = parseContent(data.content)
            setRiskStatement(parsedContent)
        } catch (e) {
            toast({
                title: t("error"),
                description: t("errorDesc"),
                status: "error",
            })
        }
    }

    function parseContent(htmlString: string) {
        htmlString = htmlString.replace(/<a href="([^"]*)">/g, '<a href="$1" target="_blank">')
        return htmlString
    }

    function handleAcknowledge() {
        submitSubForm(
            "/api/v2/account/disclaimer",
            {
                content_key: "risk_statement_en_ca",
            },
            () => {
                setUserObj((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        disclaimers: {
                            ...prev.disclaimers,
                            risk_statement: {
                                name: "risk_statement",
                                content_key: "risk_statement_en_ca",
                                accepted_at: new Date(Date.now()),
                            },
                        },
                    }
                })
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.RiskStatement, REGISTRATION_STEP.RISK_STATEMENT)
            }
        )
    }

    return (
        <Box>
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("header")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                {t("title")}
            </Text>
            <VStack
                display="flex"
                rowGap="0.75rem"
                alignItems="flex-start"
                overflowY={"auto"}
                height="500px"
                borderRadius={"6px"}
                borderWidth={"1px"}
                borderColor={"gray.20"}
                py="1.25rem"
                px="1.75rem"
            >
                {!!riskStatement && (
                    <div
                        dangerouslySetInnerHTML={{ __html: riskStatement }}
                        className="disclaimers-text"
                        style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            listStylePosition: "outside",
                        }}
                    />
                )}
            </VStack>
            <StandardButton
                data-testid="continue-button"
                onClick={handleAcknowledge}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="3rem"
                w="full"
                isLoading={isLoading}
            >
                {t("acknowledge")}
            </StandardButton>
        </Box>
    )
}
