import React from "react"
import { Box, Text, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { SubScreenProps } from ".."
import TextInput from "@/components/ui/textInput"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import DetailsTypeCard from "@components/ui/breadcrumbs/detailsType"
import useFetchWrapper, { Method } from "@/hooks/useFetchWrapper"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { AccountType } from "@redux/account/types"

type FormData = {
    countryCode: string
    phone: string
}

const TOAST_ID = "verification-code-error"

export default function WhatPhone({ submitSubForm, userObj, setUserObj, isLoading }: SubScreenProps) {
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            countryCode: "1",
            /* 
        this +1 replace clearly won't work when we accept more country codes. Backend will need to differentiate between phone and country code
        since I can't guess if its meant to be 10 or 11 digits long and if they are using a country code or not.
      */
            phone: userObj?.identity?.phone ? userObj?.identity.phone.replace("+1", "") : undefined,
        },
    })
    const { t } = useTranslation(["onboarding", "common"])
    const { fetchWrapper } = useFetchWrapper()
    const toast = useToast()

    function afterOnSubmit(submitTelephone: string) {
        setUserObj((prev) => (!prev ? prev : { ...prev, identity: { ...prev.identity, phone: submitTelephone } }))
        AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhatPhone, REGISTRATION_STEP.PHONE)
    }

    async function onSubmit(values: FormData) {
        const formattedTelephone = `${
            values.countryCode.substring(0, 1) === "+" ? "" : "+"
        }${values.countryCode} ${values.phone}`

        // format for api
        let submitTelephone = ""
        for (let i = 0; i < formattedTelephone.length; i++) {
            const pattern = /[0-9+]/
            const char = formattedTelephone.charAt(i)
            if (char.match(pattern)) {
                submitTelephone += char
            }
        }
        await submitSubForm(
            "/api/v2/account/identity/phone",
            { phone: submitTelephone },
            async () => {
                if (userObj?.type === AccountType.INDIVIDUAL) {
                    await fetchWrapper("/api/v2/account/identity/phone/verification", Method.GET)
                        .then(() => afterOnSubmit(submitTelephone))
                        .catch(
                            () =>
                                !toast.isActive(TOAST_ID) &&
                                toast({
                                    id: TOAST_ID,
                                    title: t("error.server", { ns: "common" }),
                                    description: t("whatPhone.error.verificationCode"),
                                    status: "error",
                                    duration: 5000,
                                })
                        )
                } else {
                    afterOnSubmit(submitTelephone)
                }
            },
            Method.PUT
        )
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("whatPhone.header")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("whatPhone.title")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Box display="flex" gap="1.5rem" alignItems={"flex-start"}>
                        <TextInput
                            name="countryCode"
                            staticText="+"
                            label={t("whatPhone.countryCode")}
                            control={control}
                            rules={{
                                required: "Invalid",
                                pattern: {
                                    value: /^\+?[0-9]+$/,
                                    message: t("error.numbersOnly", { ns: "common" }),
                                },
                            }}
                            size="lg"
                            width="6.5rem"
                            maxLength={3}
                            disabled
                        />
                        <TextInput
                            name="phone"
                            label={t("whatPhone.phone")}
                            placeholder="Ex. 416-234-2344"
                            control={control}
                            rules={{
                                required: t("error.required", { ns: "common" }),
                                pattern: {
                                    value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                    message: t("whatPhone.error.phone"),
                                },
                            }}
                            size="lg"
                        />
                    </Box>
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        mt="2.5rem"
                        w="full"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </Box>
            </form>
        </Box>
    )
}
