import { Box, Text } from "@chakra-ui/layout"
import React from "react"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"

export default function ThirdParty() {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <>
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("safetyDisclaimer.thirdParty")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("safetyDisclaimer.yourSecurity")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="2.5rem">
                {t("safetyDisclaimer.youreDisabled")}
            </Text>
            <StandardButton
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                onClick={() => window.open("mailto:support@gonetcoins.com?subject=Third Party Inquiry", "_blank")}
                w="full"
            >
                {t("contactSupport", { ns: "common" })}
            </StandardButton>
        </>
    )
}
