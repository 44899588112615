import { useAuth } from "hooks/useAuth"
import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { ROUTES } from "./routes"

interface AuthRedirectProps {
    children: React.ReactElement
}

const AuthRedirect: React.FC<AuthRedirectProps> = ({ children }) => {
    const { isAuthenticated } = useAuth()
    const location = useLocation()

    if (isAuthenticated) {
        // Redirect to the dashboard if authenticated
        return <Navigate to={ROUTES.DASHBOARD} state={{ from: location }} replace />
    }

    return children
}

export default AuthRedirect
