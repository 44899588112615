import { Box, useMediaQuery } from "@chakra-ui/react"
import LoadingSideDrawer from "@components/loading"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import StandardButton from "@components/ui/buttons/standard"
import { ErrorModal } from "@components/ui/modals/errorModal"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import Complete from "@screens/dashboard/buysell/complete"
import Landing from "@screens/dashboard/buysell/landing"
import Preview from "@screens/dashboard/buysell/preview"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { DrawerCoinTradeContext } from "./DrawerCoinTradeContext"

export type TradeAsset = Pick<AssetDetailsFull, "precision" | "symbol" | "name" | "price" | "price_precision">

export type DrawerCoinTradeProps = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    initialAsset: TradeAsset
    showCoinSelect?: boolean
    isOverlay?: boolean
    tab: BUYSELL_TAB
    setTab: (tab: BUYSELL_TAB) => void
}

export enum BUYSELL_TAB {
    BUY = "buy",
    SELL = "sell",
}

export enum ORDER_TYPE {
    MARKET = "Instant",
    LIMIT = "Limit",
}
export const SIDE_DRAWER_MARKET_DETAILS_WIDTH = 440

export enum BUYSELL_VIEW {
    LANDING = 0,
    PREVIEW = 1,
    COMPLETE = 2,
}

const DrawerCoinTrade: React.FC<DrawerCoinTradeProps> = ({
    isOpen,
    toggleSideDrawerOpen,
    showCoinSelect = false,
    isOverlay = false,
    tab,
    setTab,
}: DrawerCoinTradeProps) => {
    const {
        isAssetsLoading,
        isBalancesLoading,
        isTransitioning,
        orderType,
        isOrderLoading,
        isQuoteLoading,
        fetchQuote,
        validateQuote,
        isModalOpen,
        onClose,
        executeOrder,
        resetQuoteParams,
        error,
    } = DrawerCoinTradeContext.useContainer()
    const { t } = useTranslation(["app", "common"])
    const [isDesktop] = useMediaQuery("(min-width: 1022px)")
    const [view, setView] = useState(BUYSELL_VIEW.LANDING)
    const toggleDrawerOpen = () => {
        toggleSideDrawerOpen()
        if (view !== BUYSELL_VIEW.LANDING) {
            resetQuoteParams()
            setView(BUYSELL_VIEW.LANDING)
        }
    }

    useEffect(() => {
        resetQuoteParams(tab)
    }, [tab])

    const handleButtonClick = async () => {
        switch (view) {
            case BUYSELL_VIEW.LANDING:
                if (!validateQuote()) {
                    return
                }
                if (!(await fetchQuote(true))) {
                    return
                }
                setView(BUYSELL_VIEW.PREVIEW)
                return
            case BUYSELL_VIEW.PREVIEW:
                if (!(await executeOrder())) {
                    return
                }
                setView(BUYSELL_VIEW.COMPLETE)
                return
            case BUYSELL_VIEW.COMPLETE:
                return toggleDrawerOpen()
        }
    }

    const isButtonLoading = useMemo(() => {
        switch (view) {
            case BUYSELL_VIEW.LANDING:
                return isQuoteLoading
            case BUYSELL_VIEW.PREVIEW:
                return isOrderLoading || isQuoteLoading
            case BUYSELL_VIEW.COMPLETE:
                return false
        }
    }, [isQuoteLoading, isOrderLoading, view])

    const isLoadingTransitionToPreview = useMemo(() => {
        return isQuoteLoading && isTransitioning && view === BUYSELL_VIEW.LANDING
    }, [isTransitioning, view])

    const renderView = () => {
        switch (view) {
            case BUYSELL_VIEW.LANDING:
                return (
                    <Landing
                        tab={tab}
                        setTab={setTab}
                        key={`landing-${view}-${isOpen}`}
                        showCoinSelect={showCoinSelect}
                    />
                )
            case BUYSELL_VIEW.PREVIEW:
                return <Preview tab={tab} key={`preview-${view}-${isOpen}`} />
            case BUYSELL_VIEW.COMPLETE:
                return <Complete tab={tab} />
        }
    }

    const renderContent = () => {
        if (isBalancesLoading || isAssetsLoading) {
            return <LoadingSideDrawer text={t("buysell.loadingAssets")} />
        }
        if (isLoadingTransitionToPreview) {
            return <LoadingSideDrawer text={t("buysell.loadingQuoteTransition")} />
        }
        return <Box flex={1}>{renderView()}</Box>
    }

    const buttonText = useMemo(() => {
        switch (view) {
            case BUYSELL_VIEW.LANDING:
                return t("continue", { ns: "common" })
            case BUYSELL_VIEW.PREVIEW:
                return orderType === ORDER_TYPE.LIMIT
                    ? t("buysell.setLimitOrder")
                    : tab === BUYSELL_TAB.BUY
                      ? t("buysell.buyNow")
                      : t("buysell.sellNow")
            case BUYSELL_VIEW.COMPLETE:
                return t("done", { ns: "common" })
        }
    }, [view])

    if (isOverlay)
        return (
            <>
                <SideDrawer
                    isOpen={isOpen}
                    toggleSideDrawerOpen={toggleDrawerOpen}
                    size="md"
                    header={
                        <StandardDrawerHeader
                            onBack={view === BUYSELL_VIEW.PREVIEW ? () => setView((prev) => prev - 1) : undefined}
                            onClose={toggleDrawerOpen}
                        />
                    }
                    footerButton={
                        isLoadingTransitionToPreview
                            ? undefined
                            : {
                                  variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                                  onClick: handleButtonClick,
                                  children: buttonText,
                                  isLoading: isButtonLoading,
                                  type: "button",
                              }
                    }
                >
                    {renderContent()}
                </SideDrawer>
                <ErrorModal description={error.dialogError} isOpen={isModalOpen} onClose={onClose} />
            </>
        )
    else
        return (
            <Box
                h="full"
                display={isOpen ? "flex" : "none"}
                flexDir="column"
                position="absolute"
                top="0"
                bottom="0"
                right="0"
                w={isDesktop ? `${SIDE_DRAWER_MARKET_DETAILS_WIDTH}px` : "full"}
                borderLeft="1px solid #D3D3D3"
                p={{ base: "1rem", sm: "1.5rem" }}
                bgColor="white"
            >
                <StandardDrawerHeader
                    onBack={view === BUYSELL_VIEW.PREVIEW ? () => setView((prev) => prev - 1) : undefined}
                    onClose={toggleDrawerOpen}
                />
                {renderContent()}
                {isLoadingTransitionToPreview ? null : (
                    <StandardButton
                        isLoading={isButtonLoading}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        onClick={handleButtonClick}
                        children={buttonText}
                        type="button"
                    />
                )}
                <ErrorModal description={error.dialogError} isOpen={isModalOpen} onClose={onClose} />
            </Box>
        )
}

const DrawerCoinTradeHOC: React.FC<DrawerCoinTradeProps> = (props) => (
    <DrawerCoinTradeContext.Provider initialState={props}>
        <DrawerCoinTrade {...props} />
    </DrawerCoinTradeContext.Provider>
)

export default DrawerCoinTradeHOC
