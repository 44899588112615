import { useCallback } from "react"
import { ROUTES } from "./routes"
import { useLocation } from "react-router-dom"

export default function useRoutingUtils() {
    const location = useLocation()

    const isActive = useCallback(
        (path: string) => {
            const split = location.pathname.split("/")
            if (path === ROUTES.DASHBOARD && split[1] === ROUTES.DASHBOARD) {
                return true
            }

            const pathSplit = path.split("/")

            return split.length >= 2 && pathSplit.length >= 2 ? split[2] === pathSplit[2] : false
        },
        [location.pathname]
    )

    return {
        isActive,
    }
}
