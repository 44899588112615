import { createSelector } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery, buildQueryString } from "@util/RTKApi"
import { API_PATH, API_SLICE_NAME, TAG_TYPES } from "./const"
import { AssetDetailsFull, AssetDetailsParams } from "./types"

export const assetDetailsApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(API_PATH.ASSETS_DETAILS),
    tagTypes: [TAG_TYPES.ASSET_DETAILS],
    endpoints: (builder) => ({
        getAssetsDetails: builder.query<Record<string, AssetDetailsFull>, AssetDetailsParams | undefined>({
            query: (params) => {
                return buildQueryString(params)
            },
        }),
    }),
})

export const { useGetAssetsDetailsQuery } = assetDetailsApi

const { getAssetsDetails } = assetDetailsApi.endpoints

// getAssetDetails in array form
export const selectAssetsDetailsAsArray = createSelector(getAssetsDetails.select(undefined), (assetsDetails) =>
    Object.values(assetsDetails)
)
