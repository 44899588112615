import { Box, Flex, HStack, Spacer, Text, theme, VStack } from "@chakra-ui/react"
import Chip, { ChipOptionalVariant } from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import StandardButton, { StandardButtonOptionalVariant } from "@components/ui/buttons/standard"
import Checkbox from ".."
import { ReactNode } from "react"

interface CardCheckBoxProps {
    checked: boolean
    label: string
    primaryText?: string
    secondaryText?: string
    leftButton?: StandardButtonOptionalVariant
    rightButton?: StandardButtonOptionalVariant
    chip?: ChipOptionalVariant
    onPress: () => void
    logo?: ReactNode
}

const CardCheckbox: React.FC<CardCheckBoxProps> = ({
    checked,
    label,
    primaryText,
    secondaryText,
    chip,
    onPress,
    logo,
    leftButton,
    rightButton,
}) => {
    return (
        <Box
            borderWidth={1}
            borderColor={checked ? "blue.100" : "grey.light.10"}
            borderRadius={4}
            onClick={onPress}
            _hover={{
                cursor: "pointer",
                bg: "blue.10",
                borderColor: checked ? "blue.100" : "blue.10",
            }}
            _active={{
                borderColor: "grey.light.10",
            }}
            className="checkbox-ancestor-pressable"
            overflow="hidden"
        >
            <Box borderWidth={1} borderColor={checked ? "blue.100" : "transparent"} p={6} borderRadius={2}>
                <VStack spacing={"0.75rem"} alignItems={"flex-start"}>
                    <Flex width="100%" align="center" justify="space-between">
                        <HStack spacing={3} align="flex-start" flex="1" minW="0" wrap="wrap">
                            <Checkbox pointerEvents="none" isChecked={checked} />
                            <Box flex="1" minW="0">
                                <HStack spacing={1} align="center" wrap="wrap">
                                    <Text textStyle="ManropeSemiboldBody">{label}</Text>
                                    {chip && (
                                        <Box flexShrink={0} ml={{ base: 0, sm: 3 }} alignItems={"center"}>
                                            <Chip variant={ENUM_CHIP_VARIANTS.GREEN} {...chip} />
                                        </Box>
                                    )}
                                </HStack>
                            </Box>
                        </HStack>
                        {logo && (
                            <Box
                                width={{ base: "32px", md: "48px" }}
                                height={{ base: "32px", md: "48px" }}
                                bg="transparent"
                                borderRadius="md"
                                flexShrink={0}
                            />
                        )}
                    </Flex>
                    <Text textStyle={"InterRegularBody"}>{primaryText}</Text>
                    {secondaryText && (
                        <Text textStyle={"InterRegularBodySmall"} color={"grey.light.80"}>
                            {secondaryText}
                        </Text>
                    )}
                    {(leftButton || rightButton) && (
                        <HStack w={"100%"} flexWrap="wrap">
                            {leftButton?.variant && (
                                <StandardButton variant={leftButton.variant} size="sm" {...leftButton}>
                                    {leftButton.children}
                                </StandardButton>
                            )}
                            <Spacer />
                            {rightButton?.variant && (
                                <StandardButton variant={rightButton.variant} size="sm" {...rightButton}>
                                    {rightButton.children}
                                </StandardButton>
                            )}
                        </HStack>
                    )}
                </VStack>
            </Box>
        </Box>
    )
}

export default CardCheckbox
