import { InMemoryWebStorage, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts"

export const getOidcConfig = (): UserManagerSettings => {
    const scope = "default" // todo give this real name
    const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID as string
    const appUrl = window.location.protocol + "//" + window.location.host

    const oauthUrl = process.env.REACT_APP_BASE_URL as string

    return {
        authority: oauthUrl + "/oauth",
        redirect_uri: appUrl,
        post_logout_redirect_uri: oauthUrl + "/logout",
        client_id: clientId,
        scope,
        loadUserInfo: false,
        response_type: "code",
        metadataUrl: oauthUrl + "/.well-known/oauth-authorization-server",
        revokeTokensOnSignout: true,
        // Can be used while backend does not require custom arg (device_id) for web.
        automaticSilentRenew: true,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    }
}

export const userManager = new UserManager(getOidcConfig())
