import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { addCommasToNumber, addThousandSeparator, formatDollar, removeTrailingZeros } from "@util/stringFormatting"
import { IOrder } from "@redux/orders/types"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { FiMoreVertical } from "react-icons/fi"
import _ from "lodash"
import { isFiatCurrency } from "@util/currencyHelpers"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"

/* 
    NOTE not a fan of how this file was written. No need for separate sections for mobile vs desktop rendering
    And the prop drilling of the possibly undefined assetDetailsData is not ideal
    Plus the establshTxAmount should be in a hook. It'll be used a lot and is already duplicated in the Transactions screen
*/

const establishOpenOrderAmount = (order: IOrder) => {
    return order.side === "Buy" ? order.subtotal : order.total
}

const establishTxQuantity = (tx: Transaction, assetDetailsData?: Record<string, AssetDetailsFull>): string => {
    // TODO price precision needs to be written better here. And frankly this whole thing should be a hook.
    const data = assetDetailsData?.[tx.asset]
    switch (tx.action) {
        case TransactionAction.Buy:
            return tx.increase_amount
        case TransactionAction.Sell:
            return tx.decrease_amount
        case TransactionAction.Deposit: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precisions.withdrawal || data?.precision || 2
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        case TransactionAction.Withdraw: {
            if (isFiatCurrency(tx.decrease_currency)) {
                return formatDollar(tx.decrease_amount)
            }
            const precisionToUse = data?.precisions.withdrawal || data?.precision
            return formatNumberFixedPrecision(tx.decrease_amount, Number(precisionToUse), true)
        }
        case TransactionAction.KohoReward:
        case TransactionAction.Reward: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precision
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        case TransactionAction.Stake:
            return formatNumberFixedPrecision(tx.decrease_amount, Number(data?.precision), true)

        case TransactionAction.Unstake:
            return formatNumberFixedPrecision(tx.increase_amount, Number(data?.precision), true)

        case TransactionAction.StakeReward: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precision
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        default:
            return "0"
    }
}

const establishTxAmount = (tx: Transaction, assetDetailsData?: Record<string, AssetDetailsFull>): string => {
    // TOdo should be a hook with the above func included
    const data = assetDetailsData?.[tx.asset]
    switch (tx.action) {
        case TransactionAction.Buy:
        case TransactionAction.Sell:
            return formatDollar(tx.total)
        case TransactionAction.Deposit:
            return ""
        case TransactionAction.Withdraw:
            return ""
        case TransactionAction.KohoReward:
        case TransactionAction.Reward:
            return formatDollar(String(Number(tx.rate) * Number(tx.increase_amount)))
        case TransactionAction.Stake:
            return formatDollar(String(Number(tx.rate) * Number(tx.decrease_amount)))
        case TransactionAction.Unstake:
            return formatDollar(String(Number(tx.rate) * Number(tx.increase_amount)))
        case TransactionAction.StakeReward:
            return tx.decrease_amount
        default:
            return ""
    }
}

export type AssetTableProps<T extends IOrder | Transaction> = {
    arr: T[]
    setSelectedArr: (_: T) => void
    isCurrencyOrderSwapped: boolean
}

export const TableHeader = ({ isOpenOrder }: { isOpenOrder: boolean }) => {
    const { t } = useTranslation(["app", "common"])

    return (
        <Flex backgroundColor={"grey.dark.10"} py={2} px="0.25rem">
            <Box display={{ base: "flex", md: "none" }} w="full">
                {isOpenOrder ? (
                    <Text as="span" textStyle="ManropeSemiboldXSmall" flex={1} fontWeight="normal" color="grey.dark.70">
                        {isOpenOrder ? t("marketDetails.orderType") : t("activity", { ns: "common" })}
                    </Text>
                ) : null}
                <Text
                    as="span"
                    textStyle="ManropeSemiboldXSmall"
                    fontWeight="normal"
                    color="grey.light.90"
                    flex={1}
                    textAlign="end"
                >
                    {t("amount", { ns: "common" })}
                </Text>
                <Box width="40px" />
            </Box>
            <Box display={{ base: "none", md: "flex" }} w="full">
                <Text w="100px" as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                    {t("date", { ns: "common" })}
                </Text>
                <Text w="100px" as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                    {isOpenOrder ? t("type", { ns: "common" }) : t("activity", { ns: "common" })}
                </Text>
                {isOpenOrder ? (
                    <Text as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                        {t("price", { ns: "common" })}
                    </Text>
                ) : null}
                <Text
                    flex={1}
                    textAlign="end"
                    as="span"
                    textStyle="ManropeSemiboldXSmall"
                    fontWeight={600}
                    color="grey.light.90"
                >
                    {t("amount", { ns: "common" })}
                </Text>
                <Box width="40px" />
            </Box>
        </Flex>
    )
}

export const renderMobileTableContent = <T extends IOrder | Transaction>(
    data: T[],
    isOpenOrder: boolean,
    viewMoreText: string,
    handleViewMore: (_: T) => void,
    isCurrencyOrderSwapped: boolean,
    assetDetailsData?: Record<string, AssetDetailsFull>
) => (
    <Box width="100%">
        {data.map((tx, index) => {
            const quantity = isOpenOrder
                ? establishOpenOrderAmount(tx as IOrder)
                : establishTxQuantity(tx as Transaction, assetDetailsData)
            const txAmount = establishTxAmount(tx as Transaction, assetDetailsData)
            const date = new Date("created_at" in tx ? tx.created_at * 1000 : tx.date_created)
            return (
                <Flex
                    key={index}
                    justify="space-between"
                    align="center"
                    py={3}
                    borderBottom="1px solid"
                    borderColor="grey.dark.20"
                >
                    <Box flex={1}>
                        <Text textStyle="ManropeSemiboldXSmall" color="grey.dark.60" mb={2}>
                            {date.toLocaleString("default", { month: "short" })} {date.getDate()}, {date.getFullYear()}
                        </Text>
                        <Box>
                            <Text textStyle="ManropeSemiboldXSmall">
                                {"side" in tx
                                    ? `${tx.side} at`
                                    : tx.action.charAt(0).toUpperCase() + tx.action.slice(1)}
                            </Text>
                            <Text textStyle="ManropeSemiboldXSmall">
                                {"price" in tx ? addCommasToNumber(formatDollar(tx.price)) : ""}
                            </Text>
                        </Box>
                    </Box>
                    <Box textAlign="end" flexGrow={1} alignItems={"center"}>
                        <Text color="black" textStyle="ManropeSemiboldXSmall">
                            {isCurrencyOrderSwapped
                                ? "quantity" in tx
                                    ? tx.quantity
                                    : quantity
                                : "quantity" in tx
                                  ? `$${addCommasToNumber(Number(tx.side === "Buy" ? tx.subtotal : tx.total).toFixed(2))}`
                                  : `${txAmount}`}
                        </Text>
                        <Text color="grey.light.50" textStyle="ManropeMediumBodySmall" fontSize={12}>
                            {!isCurrencyOrderSwapped
                                ? "quantity" in tx
                                    ? tx.quantity
                                    : quantity
                                : "quantity" in tx
                                  ? `$${addCommasToNumber(Number(tx.side === "Buy" ? tx.subtotal : tx.total).toFixed(2))}`
                                  : `${txAmount}`}
                        </Text>
                    </Box>
                    <Box display="inline-block" verticalAlign="middle" ml={2}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                variant="ghost"
                                iconSpacing={0}
                                rightIcon={<FiMoreVertical size={15} />}
                                p={11}
                            />
                            <MenuList padding={0} borderRadius={8}>
                                <MenuItem
                                    onClick={() => handleViewMore(tx)}
                                    px={{ base: "16px" }}
                                    py={{ base: "12px" }}
                                    _hover={{ backgroundColor: "blue.100", color: "white" }}
                                    borderRadius={8}
                                >
                                    {viewMoreText}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>
            )
        })}
    </Box>
)

export const renderDesktopTableContent = <T extends IOrder | Transaction>(
    data: T[],
    isOpenOrder: boolean,
    viewMoreText: string,
    handleViewMore: (_: T) => void,
    isCurrencyOrderSwapped: boolean,
    assetDetailsData?: Record<string, AssetDetailsFull>
) => (
    <Box width="100%">
        {data.map((tx, index) => {
            const quantity = isOpenOrder
                ? establishOpenOrderAmount(tx as IOrder)
                : establishTxQuantity(tx as Transaction, assetDetailsData)
            const txAmount = establishTxAmount(tx as Transaction, assetDetailsData)
            const date = new Date("created_at" in tx ? tx.created_at * 1000 : tx.date_created)
            return (
                <Flex key={index} py={2} borderBottom="1px solid" borderColor="grey.dark.20" alignItems="center">
                    <Text color="grey.dark.60" textStyle="ManropeSemiboldXSmall" width="100px">
                        {date.toLocaleString("default", { month: "short" })} {date.getDate()}, {date.getFullYear()}
                    </Text>
                    <Text textStyle="ManropeSemiboldXSmall" w="100px">
                        {"side" in tx ? `${tx.side} at` : tx.action.charAt(0).toUpperCase() + tx.action.slice(1)}
                    </Text>
                    {isOpenOrder ? (
                        <Text textStyle="ManropeSemiboldXSmall" w="100px">
                            {"price" in tx ? addCommasToNumber(formatDollar(tx.price)) : ""}
                        </Text>
                    ) : null}
                    <Box textAlign="end" flexGrow={1} alignItems={"center"}>
                        <Text color="black" textStyle="ManropeSemiboldXSmall">
                            {isCurrencyOrderSwapped
                                ? "quantity" in tx
                                    ? tx.quantity
                                    : quantity
                                : "quantity" in tx
                                  ? `$${addCommasToNumber(Number(tx.side === "Buy" ? tx.subtotal : tx.total).toFixed(2))}`
                                  : `${txAmount}`}
                        </Text>
                        <Text color="grey.light.50" textStyle="ManropeMediumBodySmall" fontSize={12}>
                            {!isCurrencyOrderSwapped
                                ? "quantity" in tx
                                    ? tx.quantity
                                    : quantity
                                : "quantity" in tx
                                  ? `$${addCommasToNumber(Number(tx.side === "Buy" ? tx.subtotal : tx.total).toFixed(2))}`
                                  : `${txAmount}`}
                        </Text>
                    </Box>
                    <Box display="inline-block" verticalAlign="middle" ml={2}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                variant="ghost"
                                iconSpacing={0}
                                rightIcon={<FiMoreVertical size={15} />}
                                p={11}
                            />
                            <MenuList padding={0} borderRadius={8}>
                                <MenuItem
                                    onClick={() => handleViewMore(tx)}
                                    px={{ base: "16px" }}
                                    py={{ base: "12px" }}
                                    _hover={{ backgroundColor: "blue.100", color: "white" }}
                                    borderRadius={8}
                                >
                                    {viewMoreText}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>
            )
        })}
    </Box>
)

export default function OpenOrdersTab({ arr, setSelectedArr, isCurrencyOrderSwapped }: AssetTableProps<IOrder>) {
    const { t } = useTranslation(["common", "app"])
    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    return (
        <Box minHeight="100px">
            <TableHeader isOpenOrder />
            {arr.length ? (
                <>
                    <Box display={{ base: "block", md: "none" }}>
                        {renderMobileTableContent(
                            arr,
                            true,
                            t("viewMore"),
                            setSelectedArr,
                            isCurrencyOrderSwapped,
                            assetDetailsData
                        )}
                    </Box>
                    <Box display={{ base: "none", md: "block" }}>
                        {renderDesktopTableContent(
                            arr,
                            true,
                            t("viewMore"),
                            setSelectedArr,
                            isCurrencyOrderSwapped,
                            assetDetailsData
                        )}
                    </Box>
                </>
            ) : (
                <Flex
                    pos="absolute"
                    left="50%"
                    top="calc(50% + 34px)"
                    transform="translate(-50%, -50%)"
                    flex={1}
                    justifyContent={"center"}
                    alignItems="center"
                >
                    <Text color="grey.light.50" textStyle="ManropeSemiBold">
                        {t("marketDetails.emptyOrders", { ns: "app" })}
                    </Text>
                </Flex>
            )}
        </Box>
    )
}
