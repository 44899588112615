import * as amplitude from "@amplitude/analytics-browser"
import {
    LOGIN_SCREENS,
    REGISTRATION_STEP,
    REG_SCREENS,
    SCREEN_TO_MOBILE_EVENT_REGISTRY,
} from "@screens/onboarding/types"
import { AmplitudeEvent } from "./types"
import { IS_PROD } from "util/config"

class Amplitude {
    private amplitude: amplitude.Types.BrowserClient
    private isInitialized = false

    constructor() {
        this.amplitude = amplitude.createInstance()
    }

    public init(email?: string) {
        if (!IS_PROD) return
        const API_KEY = process.env.AMPLITUDE_INIT_KEY as string
        this.amplitude.init(API_KEY, {
            defaultTracking: false,
            ...(email ? { userId: email } : {}),
        })
        this.isInitialized = true
    }

    public async logEvent(_event: string, _eventProperties?: any) {
        if (!IS_PROD) return
        if (!this.isInitialized) {
            this.init()
        }
        const res = await this.amplitude.logEvent(_event, {
            environment: process.env.APP_ENV,
            ..._eventProperties,
        }).promise
    }

    public setUserId(email: string) {
        if (!IS_PROD) return
        if (!this.isInitialized) {
            this.init(email)
        } else {
            this.amplitude.setUserId(email)
        }
    }

    public logScreenEvent(screen: LOGIN_SCREENS | REG_SCREENS) {
        if (!IS_PROD) return
        const screenName = SCREEN_TO_MOBILE_EVENT_REGISTRY[screen]
        if (!this.isInitialized) {
            this.init()
        }
        this.logEvent(screenName, {
            screen_name: screenName,
            event_type: AmplitudeEvent.SCREEN_EVENT,
        })
    }

    public logRegistrationEvent(screen: LOGIN_SCREENS | REG_SCREENS, step: REGISTRATION_STEP) {
        if (!IS_PROD) return
        const screenName = SCREEN_TO_MOBILE_EVENT_REGISTRY[screen]
        if (!this.isInitialized) {
            this.init()
        }
        this.logEvent(screenName, {
            screen_name: screenName,
            event_type: AmplitudeEvent.REGISTRATION_EVENT,
            step,
        })
    }
}

const AmplitudeClient = new Amplitude()

export default AmplitudeClient
