import React, { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { addCommasToNumber } from "@util/stringFormatting"
import MarketChange from "@components/ui/marketChange"
import { MdSwapVert } from "react-icons/md"
import IconWrapper from "@assets/svgs/IconWrapper"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { formatForPricePrecision } from "@util/numericalFormatting"

type Props = {
    asset: AssetDetailsFull
    canStake: boolean
    balanceAmount: number
    balanceQuantity: number
    availableAmount: number
    availableQuantity: number
    openOrdersTotalAmount?: number
    openOrdersTotalQuantity?: number
    dayPerf: { amount: number; percentage: number }
    stakedAmount?: number
    stakedQuantity?: number
    isCurrencyOrderSwapped: boolean
    toggleCurrencyOrderSwapped: React.Dispatch<React.SetStateAction<boolean>>
}

const SPACE_BETWEEN_BOXES = 4

export default function HoldingsTab({
    asset,
    canStake,
    balanceAmount,
    balanceQuantity,
    availableAmount,
    availableQuantity,
    stakedAmount,
    stakedQuantity,
    openOrdersTotalAmount,
    openOrdersTotalQuantity,
    dayPerf,
    isCurrencyOrderSwapped,
    toggleCurrencyOrderSwapped,
}: Props) {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })

    const OpenOrdersTotalQuantityFormatted = useMemo(() => {
        if (openOrdersTotalQuantity) {
            return formatForPricePrecision(openOrdersTotalQuantity, asset.precision)
        }
        return 0
    }, [openOrdersTotalAmount, asset])

    const OpenOrderTotalAmountFormatted = useMemo(() => {
        return openOrdersTotalAmount ? `$${addCommasToNumber(openOrdersTotalAmount.toFixed(2))}` : 0
    }, [openOrdersTotalAmount])

    const NotBalancePercentage = useMemo(() => {
        const notBalanceAmount = (stakedAmount ?? 0) + (openOrdersTotalAmount ?? 0) // TODO add frozen funds amount
        return notBalanceAmount ? (notBalanceAmount / balanceAmount) * 100 : 0
    }, [stakedAmount, balanceAmount])

    const NotBalancePercetangeChippedOff = useMemo(
        () => (SPACE_BETWEEN_BOXES * NotBalancePercentage) / 100,
        [NotBalancePercentage]
    )

    const AvailableAmountFormatted = useMemo(() => {
        return formatForPricePrecision(availableAmount, 2)
    }, [availableAmount, asset])

    const AvailableQuantityFormatted = useMemo(() => {
        return formatForPricePrecision(availableQuantity, asset.precision)
    }, [availableQuantity, asset])

    return (
        <Box display="flex" flexDir={"column"} rowGap="1rem" mt="2rem">
            <Box display="flex" flexDir={{ base: "column", md: "row" }} rowGap="1rem">
                <Flex flexDir={"column"} flex={1}>
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                        {t("myBalance")}
                    </Text>
                    <Flex alignItems="center" columnGap="0.5rem" py="0.25rem">
                        <Text textStyle="ManropeSemibold2xLarge">
                            {isCurrencyOrderSwapped
                                ? balanceQuantity
                                : `$${addCommasToNumber(balanceAmount.toFixed(2))}`}
                        </Text>
                        <Box cursor="pointer" onClick={(e) => toggleCurrencyOrderSwapped(!isCurrencyOrderSwapped)}>
                            <IconWrapper icon={MdSwapVert} color={"blue.100"} size={18} />
                        </Box>
                    </Flex>
                    <Text textStyle="ManropeMediumBodySmall">
                        {!isCurrencyOrderSwapped ? balanceQuantity : `$${addCommasToNumber(balanceAmount.toFixed(2))}`}
                    </Text>
                </Flex>
                <Flex flex={1} flexDir={"column"} alignItems={{ base: "flex-start", md: "flex-end" }}>
                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                        {t("available")}
                    </Text>
                    <Text textStyle="ManropeSemiboldBody">
                        {isCurrencyOrderSwapped
                            ? AvailableQuantityFormatted
                            : `$${addCommasToNumber(AvailableAmountFormatted)}`}
                    </Text>
                    <Text textStyle="ManropeMediumXSmall" mb="0.25rem">
                        {!isCurrencyOrderSwapped
                            ? AvailableQuantityFormatted
                            : `$${addCommasToNumber(AvailableAmountFormatted)}`}
                    </Text>
                    <Flex alignItems="center" w="full" h="21px">
                        <Flex h="6px" w="full">
                            <Box
                                bgColor="grey.light.10"
                                h="full"
                                borderRadius="3px"
                                w={`calc(${NotBalancePercentage}% - ${NotBalancePercetangeChippedOff}px)`}
                            />
                            <Box h="full" w={`${SPACE_BETWEEN_BOXES}px`} />
                            <Box
                                h="full"
                                bgColor="blue.100"
                                borderRadius="3px"
                                w={`calc(${100 - NotBalancePercentage}% - ${SPACE_BETWEEN_BOXES - NotBalancePercetangeChippedOff}px)}`}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
            <Flex flexDir={{ base: "column", md: "row" }} columnGap="1rem">
                <Box
                    borderColor="grey.light.10"
                    borderWidth={"1px"}
                    p="1rem"
                    borderRadius={"4px"}
                    justifyContent={"center"}
                    rowGap="0.25rem"
                    display="flex"
                    flexDir="column"
                >
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50" fontSize="12px">
                        {t("dayPerf")}
                    </Text>
                    <Text textStyle="ManropeSemiboldBodySmall" fontSize="16px">
                        ${dayPerf.amount}
                    </Text>
                    <MarketChange percent={dayPerf.percentage} />
                </Box>
                <Box w="full">
                    <Box
                        w="full"
                        display="flex"
                        flexDir="column"
                        borderBottomColor="grey.light.10"
                        borderBottomWidth={"1px"}
                        py="1rem"
                    >
                        <Flex w="full" justifyContent={"space-between"}>
                            <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                                {t("amountOpen")}
                            </Text>
                            <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                                {isCurrencyOrderSwapped
                                    ? OpenOrdersTotalQuantityFormatted
                                    : OpenOrderTotalAmountFormatted}
                            </Text>
                        </Flex>
                        <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                            {!isCurrencyOrderSwapped ? OpenOrdersTotalQuantityFormatted : OpenOrderTotalAmountFormatted}
                        </Text>
                    </Box>
                    {canStake ? (
                        <Box
                            w="full"
                            display="flex"
                            flexDir="column"
                            borderBottomColor="grey.light.10"
                            borderBottomWidth={{ base: 0, lg: "1px" }}
                            py="1rem"
                        >
                            <Flex w="full" justifyContent={"space-between"}>
                                <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                                    {t("amountStaked")}
                                </Text>
                                <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                                    {isCurrencyOrderSwapped
                                        ? stakedQuantity
                                            ? formatForPricePrecision(stakedQuantity, asset.precision)
                                            : 0
                                        : `$${stakedAmount}`}
                                </Text>
                            </Flex>
                            <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                                {!isCurrencyOrderSwapped
                                    ? stakedQuantity
                                        ? formatForPricePrecision(stakedQuantity, asset.precision)
                                        : 0
                                    : `$${stakedAmount}`}
                            </Text>
                        </Box>
                    ) : null}
                </Box>
            </Flex>
        </Box>
    )
}
