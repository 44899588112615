import { Box, Text } from "@chakra-ui/react"

type Props = {}

export default function Profile({}: Props) {
    return (
        <Box>
            <Text>Profile</Text>
        </Box>
    )
}
