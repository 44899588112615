import { useAppSelector } from "@/store/hooks"
import { TCurrencyType } from "@/types"
import { useDisclosure } from "@chakra-ui/react"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import {
    useLazyGetInstantQuoteQuery,
    useLazyGetLimitQuoteQuery,
    usePostInstantOrderMutation,
    usePostLimitOrderMutation,
} from "@redux/orders/apiSlice"
import { MINIMUM_ORDER_FIAT_AMOUNT } from "@redux/orders/const"
import { LimitQuoteParams, LimitQuoteResponse, QuoteResponse } from "@redux/orders/types"
import { formattedNumToFloat } from "@util/numericalFormatting"
import { createContainer } from "@util/UnstatedContext"
import { useEffect, useMemo, useState } from "react"
import { BUYSELL_TAB, ORDER_TYPE, TradeAsset } from "."
import { useGetAccountBalanceRestrictionsQuery } from "@redux/account/apiSlice"

type InitialState = {
    initialAsset: TradeAsset
}

type QuoteResponseType =
    | QuoteResponse
    | (LimitQuoteResponse & {
          fee: string
      })

const useDrawerCoinTrade = ({ initialAsset }: InitialState) => {
    //Data Flow:
    //1. Fetch asset details, account details, balances (useGetAssetsDetailsQuery, useGetBalancesQuery)
    //2. Fetch Mock quote based on asset, account details, balances (fetchInstantQuote/fetchLimitQuote)
    //3. Update quoteParams based on user input (quoteParams) (fetchInstantQuote/fetchLimitQuote)
    //4. Execute order based on quote (currentQuote/quoteParams) (createInstantOrder/createLimitOrder)
    const accountDetails = useAppSelector(selectAccountDetails)
    const [fetchInstantQuote, instantQuoteData] = useLazyGetInstantQuoteQuery()
    const [fetchLimitQuote, limitQuoteData] = useLazyGetLimitQuoteQuery()
    const [createInstantOrder, instantOrderData] = usePostInstantOrderMutation()
    const [createLimitOrder, limitOrderData] = usePostLimitOrderMutation()

    const [selectedAsset, setSelectedAssetState] = useState<TradeAsset>(initialAsset)
    const [currency, setCurrency] = useState<TCurrencyType>(accountDetails?.currency || "CAD")
    const [orderType, setOrderType] = useState<ORDER_TYPE>(ORDER_TYPE.MARKET)
    const { isOpen: isModalOpen, onOpen, onClose: onModalClose } = useDisclosure()
    const [error, setError] = useState<{ currencyField?: string; limitField?: string; dialogError?: string }>({})
    const [finalQuote, setFinalQuote] = useState<Partial<QuoteResponseType> | undefined>(undefined)
    const [isTransitioning, setIsTransitioning] = useState(false)

    const { data: balances, isLoading: isBalancesLoading } = useGetBalancesQuery(undefined)
    const { data: assetDetailsData, isLoading: isAssetsLoading } = useGetAssetsDetailsQuery({ currency })
    const { data: accountBalanceRestrictions, isLoading: isAccountBalancesRestrictionsLoading } =
        useGetAccountBalanceRestrictionsQuery()
    const assetList = Object.values(assetDetailsData ?? []).filter((asset): asset is AssetDetailsFull => !!asset)

    const {
        data: currentQuote,
        isLoading: isQuoteLoading,
        isFetching: isQuoteFetching,
    } = useMemo(
        () => (orderType === ORDER_TYPE.MARKET ? instantQuoteData : limitQuoteData),
        [orderType, instantQuoteData, limitQuoteData]
    )

    const { data: orderResponse, isLoading: isOrderLoading } = useMemo(
        () => (orderType === ORDER_TYPE.MARKET ? instantOrderData : limitOrderData),
        [orderType, instantOrderData, limitOrderData]
    )

    const initialQuoteParams = useMemo(
        () => ({
            price: 0,
            amount: 10,
            side: BUYSELL_TAB.BUY,
            asset: selectedAsset.symbol,
            counter_asset: currency,
        }),
        [selectedAsset, currency]
    )

    const [quoteParams, setQuoteParams] = useState<LimitQuoteParams>(initialQuoteParams)

    const previewQuote = currentQuote && {
        ...currentQuote,
        subtotal: String(
            quoteParams.side === BUYSELL_TAB.SELL && orderType === ORDER_TYPE.LIMIT
                ? currentQuote?.total
                : currentQuote?.subtotal
        ),
        total: String(
            quoteParams.side === BUYSELL_TAB.SELL && orderType === ORDER_TYPE.LIMIT
                ? currentQuote?.subtotal
                : currentQuote?.total
        ),
    }

    //Some values shouldn't be fetched from the response, but from the quote, as padding is applied on the response

    const orderResult = orderResponse &&
        finalQuote &&
        quoteParams && {
            asset: orderResponse.asset,
            price: finalQuote.price,
            quantity: orderResponse.quantity,
            fee: finalQuote.fee,
            subtotal:
                quoteParams.side === BUYSELL_TAB.SELL && orderType === ORDER_TYPE.LIMIT
                    ? finalQuote.total
                    : finalQuote.subtotal,
            total:
                quoteParams.side === BUYSELL_TAB.SELL && orderType === ORDER_TYPE.LIMIT
                    ? finalQuote.subtotal
                    : finalQuote?.total,
        }

    useEffect(() => {
        if (orderType === ORDER_TYPE.MARKET) {
            fetchInstantQuote(quoteParams)
        }
    }, [selectedAsset, orderType, quoteParams.side])

    const setSelectedAsset = ({ symbol }: Pick<TradeAsset, "symbol">) => {
        if (!assetDetailsData || !assetDetailsData[symbol]) {
            return
        }
        setSelectedAssetState(assetDetailsData[symbol])
        setQuoteParams((prev) => ({ ...prev, asset: symbol }))
    }

    const fetchQuote = async (setTransitioning = false) => {
        try {
            if (setTransitioning) {
                setIsTransitioning(true)
            }
            if (orderType === ORDER_TYPE.MARKET) {
                await fetchInstantQuote(quoteParams).unwrap()
            } else {
                await fetchLimitQuote(quoteParams).unwrap()
            }
            return true
        } catch (error: any) {
            if (error && "data" in error && error.data && "message" in error.data) {
                setError({ dialogError: error.data.message })
                onOpen()
            }
            return false
        } finally {
            setIsTransitioning(false)
        }
    }

    const executeOrder = async () => {
        try {
            if (orderType === ORDER_TYPE.MARKET) {
                if (!instantQuoteData || !instantQuoteData.data) {
                    return false
                }
                await createInstantOrder(instantQuoteData.data)
                setFinalQuote(instantQuoteData.data)
                return true
            }
            setFinalQuote(currentQuote)
            await createLimitOrder({ ...quoteParams })
            return true
        } catch (error: any) {
            if (error && "data" in error && error.data && "message" in error.data) {
                setError({ dialogError: error.data.message })
                onOpen()
            }
            return false
        }
    }

    // UI
    const [fieldStates, setFieldStates] = useState<{ currencyOne: string; currencyTwo: string }>({
        currencyOne: "",
        currencyTwo: "",
    })
    const [limitFieldState, setLimitFieldState] = useState("")

    useEffect(() => {
        resetQuoteParams()
    }, [selectedAsset, currency, orderType])

    const onCurrencyInputTextChange = (values: {
        currencyOne: string
        currencyTwo: string
        focus: "currencyOne" | "currencyTwo"
    }) => {
        resetErrors()
        //Currency One is always asset, Currency Two is always FIAT
        //Might change once we implement coin swaps
        if (values.focus === "currencyOne") {
            //Quantity in crypto
            setQuoteParams((prev) => ({
                ...prev,
                amount: undefined,
                quantity: Number(values.currencyOne),
                initial: false,
            }))
        } else {
            //Amount in FIAT
            setQuoteParams((prev) => ({
                ...prev,
                quantity: undefined,
                amount: formattedNumToFloat(values.currencyTwo),
                initial: false,
            }))
        }
        setFieldStates(values)
    }

    const onLimitPriceTextChange = (value: string) => {
        resetErrors()
        setFieldStates({ currencyOne: "", currencyTwo: "" })
        setLimitFieldState(value)
        setQuoteParams((prev) => ({ ...prev, price: formattedNumToFloat(value) }))
    }

    const resetQuoteParams = (side?: BUYSELL_TAB) => {
        setFieldStates({ currencyOne: "", currencyTwo: "" })
        setLimitFieldState("")
        setQuoteParams((prev) => ({ ...initialQuoteParams, side: side || prev.side }))
        resetErrors()
    }

    const resetErrors = () => {
        setError({})
    }

    const onClose = () => {
        resetErrors()
        onModalClose()
    }

    const validateQuote = () => {
        if (!quoteParams) {
            return false
        }

        if (fieldStates.currencyOne === "" && fieldStates.currencyTwo === "") {
            setError({ currencyField: "Please enter a value" })
            return false
        }
        if (orderType === ORDER_TYPE.LIMIT && quoteParams.price === 0) {
            setError({ limitField: "Please enter a value" })
            return false
        }
        const assetPrice = currentQuote?.price ?? selectedAsset.price
        const fiatValue = quoteParams.quantity ? quoteParams.quantity * Number(assetPrice) : Number(quoteParams.amount)
        if (fiatValue < MINIMUM_ORDER_FIAT_AMOUNT) {
            setError({
                currencyField: `The dollar amount is below the minimum of $${MINIMUM_ORDER_FIAT_AMOUNT} allowed.`,
            })
            return false
        }
        if (quoteParams.side === BUYSELL_TAB.BUY) {
            //Compare against FIAT balance
            const convertedValue = quoteParams.quantity
                ? quoteParams.quantity * Number(assetPrice)
                : (quoteParams.amount ?? 0)
            if (convertedValue > parseFloat(balances?.balances?.[currency] ?? "0")) {
                setError({ currencyField: "Insufficient funds" })
                return false
            }
        } else {
            //Compare against Crypto balance
            const convertedValue = quoteParams.amount
                ? quoteParams.amount / Number(assetPrice)
                : (quoteParams.quantity ?? 0)
            if (convertedValue > parseFloat(balances?.balances?.[selectedAsset.symbol] ?? "0")) {
                setError({ currencyField: "Insufficient funds" })
                return false
            }
        }
        setError({})
        return true
    }

    return {
        selectedAsset,
        error,
        setSelectedAsset,
        currency,
        setCurrency,
        quoteParams,
        setQuoteParams,
        orderType,
        setOrderType,
        fetchQuote,
        executeOrder,
        accountDetails,
        isTransitioning,
        balances,
        orderResponse,
        currentQuote,
        previewQuote,
        assets: assetList,
        orderResult,
        isOrderLoading,
        isQuoteLoading: isQuoteLoading || isQuoteFetching,
        isBalancesLoading,
        isAssetsLoading,
        isAccountBalancesRestrictionsLoading,
        accountBalanceRestrictions,
        validateQuote,
        resetErrors,
        resetQuoteParams,
        fieldStates,
        limitFieldState,
        onCurrencyInputTextChange,
        onLimitPriceTextChange,
        isModalOpen,
        onClose,
    }
}

/*
 * All logic for trading encompassed in this hook
 * @param initialAsset - initial asset to be displayed in the drawer
 * @param initialTab - initial tab to be displayed in the drawer
 */
export const DrawerCoinTradeContext = createContainer(useDrawerCoinTrade)
