export default function ChartUp({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="monitoring_FILL0_wght400_GRAD0_opsz24">
                <path
                    id="Vector"
                    d="M3 21.0061V19.0061L5 17.0061V21.0061H3ZM7 21.0061V15.0061L9 13.0061V21.0061H7ZM11 21.0061V13.0061L13 15.0311V21.0061H11ZM15 21.0061V15.0311L17 13.0311V21.0061H15ZM19 21.0061V11.0061L21 9.0061V21.0061H19ZM3 15.8311V13.0061L10 6.0061L14 10.0061L21 3.0061V5.8311L14 12.8311L10 8.8311L3 15.8311Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
