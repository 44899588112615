import { Box } from "@chakra-ui/react"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import HeaderDashboard, { HEADER_HEIGHT } from "components/header/dashboard"
import Sidebar from "components/sidebar"
import { Outlet } from "react-router-dom"

export default function Dashboard() {
    const { data } = useGetAccountDetailsQuery()
    return (
        <Box h="100vh" display="flex" flexDir="column">
            <HeaderDashboard />
            <Box display="flex" height={`calc(100vh - ${HEADER_HEIGHT}px)`} pos="relative">
                <Sidebar />
                <Box overflowY="auto" height="100%" width="100%" display="block">
                    <Box
                        h="full"
                        maxWidth="1536px"
                        m="0 auto"
                        px={{ base: "1rem", lg: "1.5rem" }}
                        pt={{ base: "1rem", lg: "1.5rem" }}
                        // pb comes from the <DashboardFooter /> on every screen
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
