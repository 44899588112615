export const SLICE_NAME = "asset"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    ASSET_FAVOURITES: "asset_favourites",
} as const

export const API_PATH = "asset"

export const API_PATH_FAVOURITES = {
    ASSETS_FAVOURITES: "/favourite",
} as const
