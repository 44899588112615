import { Box, Button, Text } from "@chakra-ui/react"
import { MdClose } from "react-icons/md"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"

type FilterDrawerHeaderProps = {
    title: string
    onClose: () => void
    onClear?: () => void
    clearButtonLabel?: string
}

export default function FilterDrawerHeader({
    title,
    onClose,
    onClear,
    clearButtonLabel = "Clear",
}: FilterDrawerHeaderProps) {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
                p={2}
                background={"none"}
                onClick={onClose}
                _hover={{ background: "none" }}
                _active={{ background: "none" }}
            >
                <MdClose size={18} color="black" />
            </Button>
            <Text textStyle={"ManropeSemiboldBody"}>{title}</Text>
            <Box display={"flex"} alignItems="center" justifyContent={"center"}>
                {onClear && (
                    <StandardButton
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                        type="button"
                        size="sm"
                        onClick={onClear}
                    >
                        {clearButtonLabel}
                    </StandardButton>
                )}
            </Box>
        </Box>
    )
}
