import { Box, Button, Menu, MenuButton, MenuList, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import { useState, useEffect, useMemo } from "react"
import { Option } from "../../types"
import { FaCheck } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { MdChevronRight } from "react-icons/md"

interface SingleSelectProps<T extends string | number> {
    options: Option<T>[]
    placeholder?: string
    onSelect: (selectedOption: Option<T>) => void
    preSelected?: Option<T>
    isLoading?: boolean
    isDisabled?: boolean
}

const SingleSelect = <T extends string | number>({
    options,
    placeholder = "Select an option",
    onSelect,
    preSelected,
    isLoading,
    isDisabled,
}: SingleSelectProps<T>) => {
    const { t } = useTranslation("common")
    const [selectedOption, setSelectedOption] = useState<Option<T> | null>(preSelected || null)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (preSelected) {
            setSelectedOption(preSelected)
            onSelect(preSelected)
        }
    }, [preSelected, onSelect])

    const handleOptionSelect = (option: Option<T>) => {
        setSelectedOption(option)
        setIsOpen(false)
        onSelect(option)
    }

    // Determine screen size for responsive behavior
    const displayPlaceholder = useBreakpointValue({ base: true, md: true, lg: false })

    const DisplayText = useMemo(() => {
        if (isLoading) {
            return `${t("loading")}...`
        }
        return displayPlaceholder ? placeholder : selectedOption ? selectedOption.label : placeholder
    }, [displayPlaceholder, placeholder, selectedOption, isLoading, t])

    return (
        <Box maxWidth="300px">
            <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <MenuButton
                    as={Button}
                    textStyle={"ManropeSemiboldBody"}
                    fontWeight={500}
                    rightIcon={<MdChevronRight color="black" rotate={90} size={18} />}
                    width="100%"
                    textAlign="left"
                    variant="outline"
                    borderColor="grey.dark.20"
                    onClick={() => setIsOpen(!isOpen)}
                    _hover={{ borderColor: "grey.300" }}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                    }}
                >
                    <Text textStyle={"ManropeMediumBodySmall"} noOfLines={1}>
                        {DisplayText}
                    </Text>
                </MenuButton>
                <MenuList padding={0}>
                    <VStack width="300px" align="stretch" spacing={0} maxHeight="200px" overflowY="auto">
                        {options.map((option) => (
                            <Button
                                key={option.value}
                                onClick={() => handleOptionSelect(option)}
                                variant="ghost"
                                justifyContent="space-between"
                                width="100%"
                                p={2}
                                _hover={{ bg: "gray.100" }}
                            >
                                <Text textStyle={"ManropeMediumBodySmall"}>{option.label}</Text>
                                {selectedOption && selectedOption.value === option.value && (
                                    <FaCheck color="black" size={14} />
                                )}
                            </Button>
                        ))}
                    </VStack>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default SingleSelect
