export const ICON_BUTTON_SIZE = {
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48,
} as const

export const ICON_BUTTON_ICON_SIZE = {
    xs: 12,
    sm: 14,
    md: 18,
    lg: 18,
} as const

export const ICON_BUTTON_ICON_BORDER_RADIUS = {
    xs: 4,
    sm: 6,
    md: 8,
    lg: 8,
} as const

export enum ENUM_ICON_BUTTON_VARIANTS {
    PRIMARY_SOLID = "PRIMARY_SOLID",
    PRIMARY_BLUE_SOLID = "PRIMARY_BLUE_SOLID",
    PRIMARY_GHOST = "PRIMARY_GHOST",
    PRIMARY_BLUE_GHOST = "PRIMARY_BLUE_GHOST",
    SECONDARY_OUTLINE = "SECONDARY_OUTLINE",
    SECONDARY_GHOST = "SECONDARY_GHOST",
    PRIMARY_OUTLINE = "PRIMARY_OUTLINE",
    SECONDARY_SOLID = "SECONADRY_SOLID",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
}

type STRUCTURE_BUTTON_VARIANT = {
    normal: STATEFUL_PROPERTIES
    hover: STATEFUL_PROPERTIES
    active: STATEFUL_PROPERTIES
    disabled: STATEFUL_PROPERTIES
    borderWidth: number
}

const ICON_BUTTON_VARIANTS: Record<ENUM_ICON_BUTTON_VARIANTS, STRUCTURE_BUTTON_VARIANT> = {
    PRIMARY_SOLID: {
        normal: {
            bg: "bluePurple.100",
            borderColor: "bluePurple.100",
            color: "white",
        },
        active: {
            bg: "blue.80",
            borderColor: "blue.80",
            color: "white",
        },
        hover: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        borderWidth: 2,
    },
    PRIMARY_BLUE_SOLID: {
        normal: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        active: {
            bg: "darkBlue.70",
            borderColor: "darkBlue.70",
            color: "white",
        },
        hover: {
            bg: "bluePurple.100",
            borderColor: "bluePurple.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.40",
            borderColor: "bluePurple.40",
            color: "white",
        },
        borderWidth: 2,
    },
    PRIMARY_OUTLINE: {
        normal: {
            bg: "white",
            borderColor: "bluePurple.100",
            color: "bluePurple.100",
        },
        active: {
            bg: "blue.80",
            borderColor: "bluePurple.80",
            color: "white",
        },
        hover: {
            bg: "blue.100",
            borderColor: "bluePurple.80",
            color: "white",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.40",
            color: "bluePurple.40",
        },
        borderWidth: 2,
    },
    SECONDARY_OUTLINE: {
        normal: {
            bg: "white",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.20",
            color: "bluePurple.40",
        },
        borderWidth: 1,
    },
    SECONDARY_GHOST: {
        normal: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.40",
        },
        borderWidth: 2,
    },
    PRIMARY_GHOST: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.100",
        },
        active: {
            bg: "blue.30",
            borderColor: "blue.30",
            color: "bluePurple.60",
        },
        hover: {
            bg: "blue.10",
            borderColor: "blue.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "white",
            color: "bluePurple.40",
        },
        borderWidth: 2,
    },
    PRIMARY_BLUE_GHOST: {
        normal: {
            bg: "white",
            borderColor: "white",
            color: "blue.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.40",
        },
        borderWidth: 2,
    },
    SECONADRY_SOLID: {
        normal: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.10",
            color: "grey.light.90",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.20",
            color: "grey.light.60",
        },
        hover: {
            bg: "bluePurple.30",
            borderColor: "bluePurple.30",
            color: "grey.light.80",
        },
        disabled: {
            bg: "bluePurple.40",
            borderColor: "bluePurple.40",
            color: "bluePurple.10",
        },
        borderWidth: 2,
    },
}

export default ICON_BUTTON_VARIANTS
