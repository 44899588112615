import { useContext } from "react"
import { UserContext } from "../util/authUserProvider"
import { useAppSelector } from "@/store/hooks"

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

type ErrorResponse = {
    message: string
    errors: Record<string, string[]>
}

export default function useFetchWrapper() {
    const user = useAppSelector((state) => state.auth.user)

    async function fetchWrapper<T>(
        url: string,
        method: string,
        data?: T,
        omitAccessToken?: boolean,
        headers: Record<string, string> = {}
    ) {
        let accessToken = user?.access_token
        if (!omitAccessToken) {
            try {
                // TODO refresh token
                // accessToken = (await renewToken())?.access_token
            } catch (e) {
                return Promise.reject(e)
            }
        }

        return fetch(process.env.REACT_APP_BASE_URL + url, {
            method,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                ...(!omitAccessToken && {
                    Authorization: `Bearer ${accessToken}`,
                }),
                ...headers,
            },
            body: data ? JSON.stringify(data) : null,
        })
            .then(async (response) => {
                if (!response.ok) {
                    return Promise.reject(response)
                }
                try {
                    const res = await response.json()
                    return res
                } catch (e) {
                    return true
                }
            })
            .catch(async (e) => {
                try {
                    const json = await e.json()
                    return Promise.reject({ status: e.status, ...json })
                } catch (e) {
                    return Promise.reject(e)
                }
            })
    }

    return { fetchWrapper }
}

export function errorHandler(res: ErrorResponse) {
    try {
        const firstSet = Object.values(res.errors)[0]
        const firstError = firstSet[0]
        return firstError
    } catch (e) {
        return res.message
    }
}
