export enum ENUM_CHIP_VARIANTS {
    BLUE = "BLUE",
    PURPLE = "PURPLE",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
    RED = "RED",
}

export const BADGE_HEIGHT = {
    sm: 23,
    lg: 37,
}

type STATEFUL_PROPERTIES = {
    bg: string
    color: string
}

type STRUCTURE_CHIP_VARIANT = {
    normal: STATEFUL_PROPERTIES
}

const CHIP_VARIANTS: Record<ENUM_CHIP_VARIANTS, STRUCTURE_CHIP_VARIANT> = {
    BLUE: {
        normal: {
            bg: "darkBlue.80",
            color: "white",
        },
    },
    PURPLE: {
        normal: {
            bg: "purple.80",
            color: "white",
        },
    },
    YELLOW: {
        normal: {
            bg: "yellow.light.90",
            color: "black",
        },
    },
    GREEN: {
        normal: {
            bg: "green.light.100",
            color: "white",
        },
    },
    RED: {
        normal: {
            bg: "red.light.100",
            color: "white",
        },
    },
}

export default CHIP_VARIANTS
