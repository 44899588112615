import React, { useEffect, useState } from "react"
import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"

// TODO Chakra keyframe was removed. Find new way to do this
// const colorTransitionToWhite = keyframes`
//   0% { color: rgba(0, 0, 0, 1); }
//   100% { color: rgba(255, 255, 255, 1); }
// `
// const colorTransitionToBlack = keyframes`
//   0% { color: rgba(255, 255, 255, 1); }
//   100% { color: rgba(0, 0, 0, 1); }
// `

const MotionBox = motion(Box)

type SliderOption<T> = {
    value: T
    label: string
    icon?: React.ReactNode
}

type Props<T> = { options: SliderOption<T>[]; activeOption: T; setActiveOption: (_: T) => void }

const TabsSliding = <T,>({ options, activeOption, setActiveOption }: Props<T>) => {
    const [animationComplete, setAnimationComplete] = useState(true)
    const controls = useAnimation()

    const handleOptionClick = (value: T) => {
        if (value !== activeOption) {
            setAnimationComplete(false)
            setActiveOption(value)
            controls.start({
                x: `${options.findIndex((opt) => opt.value === value) * 100}%`,
            })
        }
    }

    // Initialize the animation control with the correct starting position
    useEffect(() => {
        const initialIndex = options.findIndex((opt) => opt.value === activeOption)
        controls.set({ x: `${initialIndex * 100}%` })
    }, []) // Empty dependency array ensures this only runs once on mount

    return (
        <Flex
            width="full"
            height="56px"
            bgColor="grey.light.5"
            borderColor="grey.light.10"
            borderWidth="1px"
            borderRadius="0.5rem"
            position="relative"
            overflow="hidden"
        >
            <MotionBox
                position="absolute"
                top="0"
                left={"0"}
                height="100%"
                width={`${100 / options.length}%`}
                bg="blue.100"
                borderRadius="0.5rem"
                animate={controls}
                transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 30,
                    mass: 0.5,
                    duration: 0.2,
                }}
                onAnimationComplete={() => setAnimationComplete(true)}
            />
            {options.map((option, i) => (
                <Button
                    key={i}
                    flex={1}
                    variant="unstyled"
                    onClick={() => handleOptionClick(option.value)}
                    zIndex={1}
                    _hover={{}}
                    _active={{}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    h="full"
                >
                    <Box
                        id={`slidertab-${i}-${option.value}`}
                        as="span"
                        color={activeOption === option.value && animationComplete ? "white" : "inherit"}
                        transition="color 0.1s"
                        zIndex={2}
                        display="flex"
                        alignItems="center"
                        columnGap="1rem"
                        // animation={`${
                        //     activeOption === option.value ? colorTransitionToWhite : colorTransitionToBlack
                        // } 0.2s linear forwards`}
                    >
                        {option.icon && option.icon}
                        <Text textStyle="ManropeMediumBodySmall">{option.label}</Text>
                    </Box>
                </Button>
            ))}
        </Flex>
    )
}

export default TabsSliding
