import TransactionsRow from "@components/transactions/transactionsRow"
import { TransactionCardProps } from ".."
import { formatDollar } from "@util/stringFormatting"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useMemo } from "react"

export const Sell: React.FC<TransactionCardProps> = ({ transaction, viewMore, isLast }) => {
    const { created_at_pst, decrease_amount, total, decrease_currency, increase_currency, asset, status } =
        transaction || {}

    const fiatValue = formatDollar(total)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const formattedCryptoAmount = formatNumberFixedPrecision(decrease_amount, Number(data?.precision), true)

    return (
        <TransactionsRow
            transaction={{
                date: created_at_pst,
                description: `Sold ${asset}`,
                status: status,
                secAmount: fiatValue,
                mainCurrency: decrease_currency,
                secCurrency: increase_currency,
                mainAmount: formattedCryptoAmount,
            }}
            handleViewMore={viewMore}
            isLast={isLast}
        />
    )
}
