export const SLICE_NAME = "fund"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    DEPOSIT_ADDRESS: "depositAddress",
    CA_WIRE_DEPOSIT: "caWireDeposit",
} as const

export const API_PATH = {
    DEPOSIT_ADDRESS: "deposit",
    CA_WIRE_DEPOSIT: "fund/wire-details",
    US_WIRE_DEPOSIT: "account/bank/deposit",
} as const
