import { Textarea as ChakraTextarea, FormControl, FormControlProps, FormLabel, Text, VStack } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../errorMessage"

type _Props = {
    placeholder?: string
    size?: "xs" | "sm" | "md" | "lg"
    maxLength: number
    currentLength: number
    label?: string
}

type Props = _Props & UseControllerProps<any> & FormControlProps

export default function TextArea(props: Props) {
    const { name, placeholder, size, rules, control, label, maxLength, disabled, currentLength, ...rest } = props

    const {
        field,
        fieldState: { invalid, error },
    } = useController<any>({
        name,
        control,
        rules,
    })
    const { t } = useTranslation("common")

    return (
        <FormControl isInvalid={invalid} isRequired={rules?.hasOwnProperty("required")} {...rest}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <VStack spacing={1} w="full">
                <ChakraTextarea
                    {...field}
                    data-testid={`textarea-${name}`}
                    value={field.value ?? ""}
                    placeholder={placeholder}
                    _placeholder={{ color: "grey.dark.60" }}
                    // _focus={{ borderColor: 'blue.50', borderWidth: '4px', borderRadius: '8px' }}
                    // _active={{ borderWidth: '2px', borderRadius: '8px', borderColor: 'blue.100' }}
                    _hover={{
                        borderColor: !error ? "grey.dark.20" : "transparent",
                    }}
                    size={size}
                    resize="vertical"
                    disabled={disabled}
                    errorBorderColor="alert.error"
                    focusBorderColor="blue.100"
                    maxLength={maxLength ?? 500}
                />
                <Text color="grey.dark.60" textStyle="InterRegularXSmall" alignSelf={"end"}>
                    {`${t("characterCount")}: ${currentLength ?? 0}/${maxLength}`}
                </Text>
                {!!error && <ErrorMessage>{error?.message}</ErrorMessage>}
            </VStack>
        </FormControl>
    )
}
