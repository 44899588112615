import { createApi } from "@reduxjs/toolkit/query/react"
import { SLICE_NAME, API_PATH, TAG_TYPES } from "./const"
import { baseQuery } from "@util/RTKApi"
import { APIStake, APIStakeParams, StakingInfo } from "./types"

export const stakeApi = createApi({
    reducerPath: SLICE_NAME,
    baseQuery: baseQuery(API_PATH.STAKES),
    tagTypes: [TAG_TYPES.STAKING_INFO, TAG_TYPES.STAKES],
    endpoints: (builder) => ({
        getStakingInfo: builder.query<StakingInfo, string>({
            query: (asset) => {
                return `assets/${asset.toLowerCase()}`
            },
        }),
        getStakes: builder.query<Array<APIStake>, APIStakeParams>({
            query: (params) => ({
                url: "",
                method: "GET",
                params,
            }),
        }),
    }),
})

export const { useGetStakingInfoQuery, useGetStakesQuery } = stakeApi
