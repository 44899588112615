import { accountApi } from "@redux/account/apiSlice"
import { assetDetailsApi } from "@redux/assetsDetails/apiSlice"
import { balancesApi } from "@redux/balances/apiSlice"
import { ordersApi } from "@redux/orders/apiSlice"
import { stakeApi } from "@redux/stake/apiSlice"
import { assetApi } from "@redux/asset/apiSlice"
import { transactionsApi } from "@redux/transactions/apiSlice"
import { configureStore } from "@reduxjs/toolkit"
import { fundApi } from "@redux/fund/apiSlice"
import appReducer from "./reducer"
import { limitsApi } from "@redux/limits/apiSlice"

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware()
            .concat(accountApi.middleware)
            .concat(assetDetailsApi.middleware)
            .concat(transactionsApi.middleware)
            .concat(stakeApi.middleware)
            .concat(balancesApi.middleware)
            .concat(ordersApi.middleware)
            .concat(accountApi.middleware)
            .concat(fundApi.middleware)
            .concat(limitsApi.middleware)
            .concat(assetApi.middleware)
        return middleware
    },
})
