import { Box, Button, Flex, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import FilterDrawer from "@components/filterDrawer"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import MultiSelect from "@components/ui/multiselect"
import SingleSelect from "@components/ui/select/singleSelect"
import { Option } from "@components/ui/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useGetOrdersQuery } from "@redux/orders/apiSlice"
import { IOrder } from "@redux/orders/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import { RiFilter3Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import DrawerOpenOrder from "./drawerOpenOrders"
import { OpenOrdersCard } from "./openOrdersCard"

const SortByOptions = [
    { value: "desc", label: "Newest to Oldest" },
    { value: "asc", label: "Oldest to Newest" },
]

export const OpenOrders: React.FC = () => {
    const { asset } = useParams()
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()

    const [sortByOption, setSortByOption] = useState<Option<string>>(SortByOptions[0])
    const [selectedOrderTypeOptions, setSelectedOrderTypeOptions] = useState<Option<string>[]>([])
    const [selectedCoinOptions, setSelectedCoinOptions] = useState<Option<string>[]>([])
    const [selectedOpenOrder, setSelectedOpenOrder] = useState<IOrder | null>(null)
    const [filteredSortedData, setSortedFilteredData] = useState<IOrder[]>([])

    const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

    const onFilterDrawerOpen = () => setFilterDrawerOpen(true)
    const toggleSideDrawerOpen = () => setFilterDrawerOpen(!isFilterDrawerOpen)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const assetFilter = useMemo(() => {
        if (selectedCoinOptions.length === 0) {
            return ""
        }
        return selectedCoinOptions.map((option) => option.value).join(",")
    }, [selectedCoinOptions])

    const { data: openOrdersData, isLoading: ordersLoading } = useGetOrdersQuery({
        status: "open",
        limit: 10,
        offset: 0,
        sort: sortByOption.value as "asc" | "desc",
        assets: assetFilter,
    })

    const sortByCreatedAt = (data: IOrder[] | undefined, order: "asc" | "desc"): IOrder[] => {
        if (!data || data.length < 1) return []

        const updatedData = [...data]

        return updatedData.sort((a, b) => {
            const dateA = new Date(a.date_created)
            const dateB = new Date(b.date_created)

            if (order === "desc") {
                return dateB.getTime() - dateA.getTime()
            }

            if (order === "asc") {
                return dateA.getTime() - dateB.getTime()
            }

            return dateB.getTime() - dateA.getTime()
        })
    }

    useEffect(() => {
        if (openOrdersData && openOrdersData?.length > 0) {
            setSortedFilteredData(openOrdersData)
        }
    }, [openOrdersData])

    useEffect(() => {
        if (selectedCoinOptions.length > 0) {
            const coinOptions = selectedCoinOptions.map((option) => option.value)
            const updatedData = filteredSortedData.filter((entry) => coinOptions.includes(entry.asset))
            const sortedData = sortByCreatedAt(updatedData, sortByOption.value as "asc" | "desc")
            setSortedFilteredData(sortedData)
        } else {
            const sortedData = sortByCreatedAt(openOrdersData, sortByOption.value as "asc" | "desc")
            setSortedFilteredData(sortedData)
        }
    }, [selectedCoinOptions, sortByOption])

    const coinOptions: Option<string>[] = useMemo(() => {
        if (assetDetailsData) {
            return Object.keys(assetDetailsData).map((key) => ({
                value: key,
                label: assetDetailsData[key].name,
                desc: assetDetailsData[key].symbol,
            }))
        }

        return []
    }, [])

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const navigateToTrade = () => {
        navigate(ROUTES.DASHBOARD_TRADE)
    }

    const navigateToMarketDetails = () => {
        navigate(buildRouteWithParams(ROUTES.MARKET_DETAILS, { asset: selectedAsset?.symbol || "" }))
    }

    const clearFilters = () => {
        setSelectedOrderTypeOptions([])
        setSelectedCoinOptions([])
    }

    return (
        <Box>
            <FilterDrawer
                isOpen={isFilterDrawerOpen}
                toggleSideDrawerOpen={toggleSideDrawerOpen}
                coins={coinOptions}
                selectedCoins={selectedCoinOptions}
                setSelectedCoins={setSelectedCoinOptions}
            />
            <BreadCrumbGroup
                mb="0.5rem"
                breadCrumbs={[
                    { text: t("trade.trade"), onClick: navigateToTrade },
                    { text: selectedAsset?.name || "", onClick: navigateToMarketDetails },
                    { text: t("openOrders.openOrders") },
                ]}
            />
            <Box
                display={"block"}
                flexDir={"column"}
                height={"full"}
                gap={{ base: "1.5rem", md: "1.725rem" }}
                overflowY={"auto"}
            >
                <Box
                    display="flex"
                    flexDir={{ base: "column", md: "column", lg: "row" }}
                    rowGap="1rem"
                    columnGap="1.5rem"
                    mb="1.5rem"
                    alignItems={{ base: "normal", md: "flex-end", lg: "flex-end" }}
                    whiteSpace={"normal"}
                >
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection={{ base: "column", md: "column", lg: "row" }}
                        alignItems="center"
                        justifyContent={"space-between"}
                        rowGap={"1rem"}
                    >
                        <Box flex={1}>
                            <Text textStyle="ManropeMedium4xLarge" mb={2}>
                                {t("openOrders.title")}
                            </Text>
                            <Text textStyle={"InterRegularBodySmall"}>{t("openOrders.subTitle")}</Text>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    mb={"1.5rem"}
                    justifyContent={"space-between"}
                >
                    <Box display={{ base: "flex", lg: "none" }}>
                        <Button
                            onClick={onFilterDrawerOpen}
                            variant="outline"
                            borderColor="gray.200"
                            borderRadius="md"
                            py={2}
                            px={4}
                            bg="white"
                            _hover={{ bg: "gray.50" }}
                            width="auto"
                        >
                            <Flex align="center">
                                <RiFilter3Fill size={14} />
                                <Text ml={2} textStyle={"ManropeMediumBodySmall"}>
                                    Filters
                                </Text>
                            </Flex>
                        </Button>
                    </Box>
                    <Box display={{ base: "none", lg: "flex" }} flexDirection={"row"} alignItems={"center"}>
                        <Box mr={6}>
                            <Text textStyle={"ManropeMediumBodySmall"}>Filter By</Text>
                        </Box>
                        <Box mr={3}>
                            <MultiSelect
                                selectedOptions={selectedCoinOptions}
                                options={coinOptions}
                                setSelectedOptions={setSelectedCoinOptions}
                                placeholder="Coin"
                                search
                            />
                        </Box>
                        {(selectedOrderTypeOptions.length > 0 || selectedCoinOptions.length > 0) && (
                            <Box>
                                <StandardButton
                                    flexGrow={1}
                                    type="button"
                                    leftIcon={MdClose}
                                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                                    onClick={clearFilters}
                                    size="md"
                                    textStyle="ManropeMediumBodySmall"
                                >
                                    Clear Filters
                                </StandardButton>
                            </Box>
                        )}
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Box mr={3} display={{ base: "none", lg: "block" }}>
                            <Text textStyle={"ManropeMediumBodySmall"}>Sort By</Text>
                        </Box>
                        <Box>
                            <SingleSelect
                                options={SortByOptions}
                                preSelected={sortByOption}
                                placeholder="Sort By"
                                onSelect={setSortByOption}
                            />
                        </Box>
                    </Box>
                </Box>
                <TableContainer>
                    <Table variant="simple" layout="fixed" w="full" pos="relative">
                        <Thead w="full">
                            <Tr
                                sx={{
                                    "& th": {
                                        textTransform: "none",
                                    },
                                }}
                                borderBottom="1px solid #636366"
                            >
                                <Th display={{ base: "none", md: "table-cell", lg: "table-cell" }}>Date</Th>
                                <Th
                                    py={{ base: "0.25rem", sm: "0.5rem", md: "1rem", lg: "1rem" }}
                                    px={{ base: "0rem", sm: "0rem", md: "1.5rem", lg: "1.5rem" }}
                                >
                                    Order Type
                                </Th>
                                <Th
                                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                                    py={{ base: "0.25rem", sm: "0.5rem", md: "1rem", lg: "1rem" }}
                                    px={{ base: "0rem", sm: "0rem", md: "1.5rem", lg: "1.5rem" }}
                                >
                                    {t("common:price")}
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody w="full" h="full">
                            {filteredSortedData?.map((order, index) => (
                                <OpenOrdersCard
                                    key={index}
                                    order={order}
                                    handleViewMore={() => setSelectedOpenOrder(order)}
                                    isLast={index === filteredSortedData.length - 1}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Box display="block" w="full">
                    {ordersLoading ? (
                        <Text mt="5rem" textAlign="center">
                            {t("common:loading")}...
                        </Text>
                    ) : !filteredSortedData?.length ? (
                        <Box textAlign={"center"} mt="5rem">
                            <Box
                                display="inline-block"
                                p="1rem"
                                borderRadius={"0.5rem"}
                                bgColor="grey.dark.10"
                                mb="0.5rem"
                            >
                                <MdOutlineSearch size={24} color={"#48484A"} />
                            </Box>
                            <Text>{t("common:noResults")}</Text>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <DrawerOpenOrder
                order={selectedOpenOrder}
                isOpen={!!selectedOpenOrder}
                toggleSideDrawerOpen={() => setSelectedOpenOrder(null)}
            />
        </Box>
    )
}
