import { Box, Text, useTheme, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import AmplitudeClient from "sdks/amplitude"
import { LOGIN_SCREENS } from "@screens/onboarding/types"
import { useTranslation } from "react-i18next"
import { SMALL_SCREEN_WIDTH } from "theme/consts"
import PinInput from "components/ui/pinInput"
import { parseErrorMessage } from "util/parser"
import { MdArrowBack } from "react-icons/md"

export default function TwoFa() {
    const theme = useTheme()
    const { t } = useTranslation(["login", "common"])
    const toast = useToast()

    const [code, setCode] = React.useState("")
    const [hasError, setHasError] = React.useState(false)
    const [isLoading, toggleLoading] = React.useState(false)

    async function onSubmit(value: string) {
        toggleLoading(true)
        setHasError(false)
        fetch("/account/two-step-verification/verify/redirect", {
            method: "POST",
            credentials: "include",
            headers: {
                "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ one_time_password: value }),
        })
            .then((response) => {
                if (response.redirected) {
                    window.location.href = response.url
                    return
                }

                if (!response.ok) {
                    setHasError(true)
                    response.json().then((res) => {
                        const msg = parseErrorMessage(res.message)
                        if (!toast.isActive(msg)) {
                            toast({
                                id: msg,
                                title: t("error.title", { ns: "common" }),
                                description: msg,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            })
                        }
                    })
                }
            })
            .catch((error) => {
                setHasError(true)
                const msg = parseErrorMessage(error.message)
                if (!toast.isActive(msg)) {
                    toast({
                        id: msg,
                        title: t("error.title", { ns: "common" }),
                        description: msg,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                }
            })
            .finally(() => toggleLoading(false))
    }

    // analytics
    React.useEffect(() => {
        AmplitudeClient.logScreenEvent(LOGIN_SCREENS.ForgotPassword)
    }, [])

    return (
        <Box w="full" h="full" bgColor="bluePurple.100">
            <Box
                paddingRight="1rem"
                paddingLeft="1rem"
                paddingBottom={"1rem"}
                paddingTop={"6rem"}
                rowGap="1.5rem"
                display="flex"
                flexDir="column"
                flexGrow={1}
                m="auto"
                maxWidth={SMALL_SCREEN_WIDTH}
                w="full"
            >
                <VStack py="1.5rem" spacing="1.5rem">
                    <NetcoinsLogo color="white" width={50} />
                    <NetcoinsLogoText color="white" width={200} />
                </VStack>
                <Box>
                    <Text textStyle="InterSemiboldBodySmall" color="white" mb="0.25rem" alignSelf="flex-start">
                        {t("twoFa.header")}
                    </Text>
                    <Text textStyle="InterRegularBody" color="bluePurple.30" alignSelf="flex-start">
                        {t("twoFa.pleaseEnter")}
                    </Text>
                </Box>
                <Box display="flex" flexDir="column" rowGap="1.5rem" alignItems={"flex-start"} mb="1.5rem">
                    <PinInput
                        numOfFields={6}
                        name="one_time_password"
                        value={code}
                        error={hasError}
                        onChange={setCode}
                        size="lg"
                        onComplete={onSubmit}
                        baseColor="bluePurple.100"
                        otp
                    />
                    <StandardButton
                        data-testid="submit-button"
                        type="button"
                        onClick={() => onSubmit(code)}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                        isLoading={isLoading}
                        disabled={code.length !== 6 || isLoading}
                    >
                        {t("submit", { ns: "common" })}
                    </StandardButton>
                </Box>
                <Box
                    cursor="pointer"
                    w="full"
                    display="flex"
                    columnGap="0.75rem"
                    onClick={() => window.open("/logout", "_self")}
                >
                    <MdArrowBack size={24} color={theme.colors.blue[70]} />
                    <Text color="blue.70" textStyle="InterRegularBody">
                        {t("twoFa.returnToSignIn")}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
