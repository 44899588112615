import React from "react"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { IconType, IconBaseProps } from "react-icons"

export type ReactIconWrapperProps = {
    icon: IconType
    size?: number
    color?: string
} & Omit<IconBaseProps, "size" | "color">

const ReactIcon: React.FC<ReactIconWrapperProps> = ({ icon: Icon, size = 14, color, ...props }) => {
    const colorConverter = useColorFormatConverter()

    const finalColor = color ? colorConverter(color) : "currentColor"

    return <Icon size={size} color={finalColor} {...props} />
}

export default ReactIcon
