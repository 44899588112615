import { RootState } from "@/store/reducer"
import { selectAccessToken } from "@redux/auth/selectors"
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { TApiVersion } from "./types"

export const baseQuery = (path: string = "", apiVersion: TApiVersion = "v2") => {
    return fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}/api/${apiVersion}/${path}`,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState
            headers.set("Authorization", "Bearer " + selectAccessToken(state))
            return headers
        },
    })
}

export const buildQueryString: (params?: Record<string, any>) => string = (params) => {
    if (!params) {
        return ""
    }
    //Removing undefined values from the query string
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key])
    if (Object.keys(params).length === 0) {
        return ""
    }
    const queryString = new URLSearchParams(params).toString()

    return "?" + queryString
}
