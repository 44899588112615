import { Transaction, TransactionAction } from "@redux/transactions/types"
import { StakeCreated } from "./stakeCreated"
import { UnstakeCreated } from "./unstakeCreated"
import { Buy } from "./Buy"
import { Sell } from "./Sell"
import { Deposit } from "./Deposit"
import { Withdraw } from "./Withdraw"
import { Reward } from "./Reward"
import { StakeReward } from "./StakeReward"

export interface TransactionCardProps {
    transaction: Transaction
    viewMore: () => void
    isLast: boolean
}

const actionComponentMap = {
    [TransactionAction.Buy]: Buy,
    [TransactionAction.Sell]: Sell,
    [TransactionAction.Deposit]: Deposit,
    [TransactionAction.Withdraw]: Withdraw,
    [TransactionAction.KohoReward]: Reward,
    [TransactionAction.Reward]: Reward,
    [TransactionAction.StakeReward]: StakeReward,
    [TransactionAction.Stake]: StakeCreated,
    [TransactionAction.Unstake]: UnstakeCreated,
}

// HOC to render the correct component based on the action
export const withTransactionAction = (WrappedComponent: React.ComponentType<TransactionCardProps>) => {
    const HOC: React.FC<TransactionCardProps> = (props) => {
        const { transaction } = props
        const Component = actionComponentMap[transaction.action]

        if (!Component) return null

        return <Component {...props} />
    }

    // Set the displayName for better debugging
    HOC.displayName = `WithTransactionAction(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`

    return HOC
}

const TransactionCard: React.FC<TransactionCardProps> = (props) => {
    const { transaction, viewMore, isLast } = props
    const { action } = transaction || {}
    const Component = actionComponentMap[action]

    if (!Component) return null

    return <Component transaction={transaction} viewMore={viewMore} isLast={isLast} />
}

export default withTransactionAction(TransactionCard)
