import { useState } from "react"
import { Box, IconButton, HStack, Text, useClipboard, Fade } from "@chakra-ui/react"
import { MdContentCopy } from "react-icons/md"
import theme from "@theme/index"

interface CopyComponentProps {
    text: string
}

const CopyToast = () => (
    <Box
        position="absolute"
        bottom="100%"
        left="50%"
        transform="translateX(-50%)"
        mb="2.5"
        p="2"
        bg={theme.colors.grey.light[10]}
        borderRadius="md"
        fontSize="sm"
        whiteSpace="nowrap"
        _after={{
            content: '""',
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            border: "6px solid transparent",
            borderTopColor: "gray.100",
        }}
    >
        <Text textStyle={"InterRegularXSmall"}>Copied</Text>
    </Box>
)

const CopyComponent = ({ text = "ABCDEFG" }: CopyComponentProps) => {
    const { onCopy } = useClipboard(text)
    const [showToast, setShowToast] = useState(false)

    const handleCopy = () => {
        onCopy()
        setShowToast(true)
        setTimeout(() => setShowToast(false), 2000)
    }

    return (
        <HStack
            spacing={0}
            px={4}
            borderWidth="1px"
            borderRadius={6}
            borderColor={"grey.light.10"}
            bg="white"
            justifyContent={"space-between"}
            minH={"48px"}
        >
            <Text textStyle={"ManropeMediumBody"} isTruncated>
                {text}
            </Text>
            <Box position="relative" marginLeft={2}>
                {/* TODO change to use local IconButton instead of directly from Chakra */}
                <IconButton
                    _hover={{
                        background: "none",
                    }}
                    size="sm"
                    icon={<MdContentCopy size={20} />}
                    onClick={handleCopy}
                    aria-label={"copy"}
                    color={"black"}
                    variant="ghost"
                    sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: "auto",
                    }}
                />
                <Fade in={showToast}>
                    <CopyToast />
                </Fade>
            </Box>
        </HStack>
    )
}

export default CopyComponent
