import { Box, Flex, Link, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { AssetStatsTable } from "./table"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useTranslation } from "react-i18next"
import { shortenLargeNumber } from "@util/stringFormatting"
import { IOrder } from "@redux/orders/types"
import { Transaction } from "@redux/transactions/types"
import { useGetStakingInfoQuery } from "@redux/stake/apiSlice"
import { useGetTransactionsQuery } from "@redux/transactions/apiSlice"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import { useGetOrdersQuery } from "@redux/orders/apiSlice"

interface StatItemProps {
    label: string
    value: string | number
}

const StatItem: React.FC<StatItemProps> = ({ label, value }) => (
    <VStack align="flex-start" spacing={1}>
        <Text textStyle="ManropeSemiboldBodySmall" color="grey.dark.60">
            {label}
        </Text>
        <Text textStyle="ManropeSemiboldBodySmall">{value}</Text>
    </VStack>
)

interface AssetStatsProps {
    asset: AssetDetailsFull
    setSelectedTx: (_: Transaction) => void
    setSelectedOrder: (_: IOrder) => void
    isDrawerOpen: boolean
}

const SKELETON_HEIGHT = "168px"

const AboutSection: React.FC<{ assetName?: string; aboutAsset: string; learnMoreLink: string }> = ({
    assetName,
    aboutAsset,
    learnMoreLink,
}) => {
    const { t } = useTranslation(["app", "common"])

    return (
        <Box>
            <Text mb={4} textStyle="ManropeBoldBody">
                {t("marketDetails.about", { asset: assetName })}
            </Text>
            <Text
                overflow="hidden"
                sx={{ WebkitLineClamp: 5, WebkitBoxOrient: "vertical", display: "-webkit-box" }}
                textStyle="InterRegularXSmall"
            >
                {aboutAsset}
            </Text>
            <Link
                textStyle="ManropeSemiboldXSmall"
                color="blue.100"
                href={learnMoreLink}
                target="_blank"
                mt={2}
                display="inline-block"
            >
                {t("marketDetails.learn", { asset: assetName })}
            </Link>
        </Box>
    )
}

const AssetStats: React.FC<AssetStatsProps> = ({ asset, setSelectedTx, setSelectedOrder, isDrawerOpen }) => {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })

    const { data: stakingInfo } = useGetStakingInfoQuery(asset.symbol.toLowerCase(), {
        skip: !asset.restrictions.can_stake,
    })
    const { data: activityData, isLoading: isActivityLoading } = useGetTransactionsQuery({
        assets: asset.symbol,
        types: "",
        page: 1,
        statuses: "",
        limit: 4,
        sort: "desc",
    })
    const { data: balancesData } = useGetBalancesQuery(undefined)
    const { data: openOrdersData, error: openOrdersError } = useGetOrdersQuery({
        status: "open",
        assets: asset.symbol,
        offset: 0,
        limit: 4,
        sort: "desc",
    })

    const [aboutAsset, setAboutAsset] = React.useState("")
    const learnMoreLink = useMemo(() => {
        return `${process.env.REACT_APP_BASE_URL}/crypto-asset-statement/${asset.symbol.toLowerCase()}`
    }, [asset])

    // parses relevant information from the asset's respective 'Asset Statement' page
    useEffect(() => {
        fetch(`${learnMoreLink}/raw`)
            .then(async (data) => {
                const text = await data.text()
                if (text) {
                    let parser = new DOMParser()
                    let doc = parser.parseFromString(text, "text/html")
                    let startElement = doc.querySelector("h4")

                    let currentElement = startElement?.nextElementSibling
                    let paragraphs = []

                    while (currentElement && currentElement.tagName.toLowerCase() === "p") {
                        paragraphs.push(currentElement.textContent)
                        currentElement = currentElement.nextElementSibling
                    }
                    setAboutAsset(paragraphs.join("\n"))
                }
            })
            .catch((err) => console.error(err))
    }, [asset])

    // currently only shows CAD. might be adding market_cap_usd in the future
    const MarketCap = useMemo<number>(() => asset.statistics?.market_cap_cad, [asset])

    const Staked = useMemo<number>(() => {
        return balancesData?.stakes?.[asset.symbol.toUpperCase()]
            ? Number(balancesData.stakes[asset.symbol.toUpperCase()])
            : 0
    }, [balancesData, asset])

    const OpenOrdersTotalAmount = useMemo<number>(() => {
        return openOrdersData
            ? openOrdersData.reduce((acc: number, order: IOrder) => acc + Number(order.subtotal), 0)
            : 0
    }, [openOrdersData])

    const OpenOrderTotalQuantity = useMemo<number>(() => {
        return OpenOrdersTotalAmount / Number(asset.price)
    }, [OpenOrdersTotalAmount, asset])

    const BalanceQuantity = useMemo<number>(() => {
        const startingBal = balancesData?.balances?.[asset.symbol.toUpperCase()]
            ? Number(balancesData.balances[asset.symbol.toUpperCase()])
            : 0
        return startingBal
    }, [balancesData, asset])

    const BalanceAmount = useMemo(() => {
        const str = Number(asset.price) * BalanceQuantity
        return Number(str)
    }, [BalanceQuantity, asset.price])

    // This is because the API returns a 404 error when there are no open orders
    // Still need the UI to be updating when this happens
    const OpenOrdersData = useMemo(() => {
        if (openOrdersError) {
            return []
        } else {
            return openOrdersData ?? []
        }
    }, [openOrdersData, openOrdersError])

    const isShowingTable = useMemo(() => activityData?.total || openOrdersData?.length, [activityData, openOrdersData])

    return (
        <Box maxWidth="100%">
            <Flex
                direction={{ base: "column", lg: isDrawerOpen ? "column" : "row" }}
                justifyContent="space-between"
                gap="1rem"
            >
                <Flex direction="column" flex={1} gap="1rem">
                    <Box>
                        <Text mb={4} textStyle="ManropeBoldBody">
                            {asset?.name} {t("marketStatistics")}
                        </Text>
                        <SimpleGrid columns={[2, 2, 2, 3]} spacing={4}>
                            <StatItem
                                label={t("24HVolume")}
                                value={`$${shortenLargeNumber(asset.statistics.volume)}`}
                            />
                            <StatItem
                                label={t("24HLow")}
                                value={`$${shortenLargeNumber(asset.statistics["24h_low"])}`}
                            />
                            <StatItem
                                label={t("24HHigh")}
                                value={`$${shortenLargeNumber(asset.statistics["24h_high"])}`}
                            />
                            <StatItem label={t("marketCap")} value={`$${shortenLargeNumber(MarketCap)}`} />
                            <StatItem
                                label={t("maxSupply")}
                                value={`${shortenLargeNumber(asset.statistics.max_supply)} ${asset.symbol.toUpperCase()}`}
                            />
                            <StatItem
                                label={t("circulatingSupply")}
                                value={`$${shortenLargeNumber(asset.statistics.circulating_supply)}`}
                            />
                        </SimpleGrid>
                    </Box>
                    <Skeleton
                        display={{ base: "none", lg: "block" }}
                        h={!!aboutAsset ? "fit-content" : SKELETON_HEIGHT}
                        isLoaded={!!aboutAsset}
                    >
                        {isShowingTable ? (
                            <AboutSection
                                assetName={asset.name}
                                aboutAsset={aboutAsset}
                                learnMoreLink={learnMoreLink}
                            />
                        ) : null}
                    </Skeleton>

                    <Box display={{ base: "block", lg: "none" }}>
                        <Skeleton h={!!aboutAsset ? "fit-content" : SKELETON_HEIGHT} isLoaded={!!aboutAsset}>
                            <AboutSection
                                assetName={asset.name}
                                aboutAsset={aboutAsset}
                                learnMoreLink={learnMoreLink}
                            />
                        </Skeleton>

                        {isShowingTable ? (
                            <AssetStatsTable
                                asset={asset}
                                isDrawerOpen={isDrawerOpen}
                                balanceAmount={BalanceAmount}
                                balanceQuantity={BalanceQuantity}
                                openOrdersTotalAmount={OpenOrdersTotalAmount}
                                openOrdersTotalQuantity={OpenOrderTotalQuantity}
                                staked={Staked}
                                stakingInfo={stakingInfo}
                                openOrders={OpenOrdersData}
                                activity={activityData?.transactions ?? []}
                                setSelectedOrder={setSelectedOrder}
                                setSelectedTx={setSelectedTx}
                            />
                        ) : null}
                    </Box>
                </Flex>
                <Box flex={1} display={{ base: "none", lg: "block" }}>
                    {isShowingTable ? (
                        <Skeleton h={!isActivityLoading ? "full" : "fit-content"} isLoaded={!isActivityLoading}>
                            <AssetStatsTable
                                asset={asset}
                                isDrawerOpen={isDrawerOpen}
                                balanceAmount={BalanceAmount}
                                balanceQuantity={BalanceQuantity}
                                openOrdersTotalAmount={OpenOrdersTotalAmount}
                                openOrdersTotalQuantity={OpenOrderTotalQuantity}
                                staked={Staked}
                                stakingInfo={stakingInfo}
                                openOrders={OpenOrdersData}
                                activity={activityData?.transactions ?? []}
                                setSelectedOrder={setSelectedOrder}
                                setSelectedTx={setSelectedTx}
                            />
                        </Skeleton>
                    ) : (
                        <Skeleton
                            h={!aboutAsset || !isActivityLoading ? "full" : "fit-content"}
                            isLoaded={!!aboutAsset && !isActivityLoading}
                            fadeDuration={2}
                        >
                            <AboutSection
                                assetName={asset.name}
                                aboutAsset={aboutAsset}
                                learnMoreLink={learnMoreLink}
                            />
                        </Skeleton>
                    )}
                </Box>
            </Flex>
        </Box>
    )
}

export default AssetStats
