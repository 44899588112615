import { Stack, StackProps } from "@chakra-ui/react"
import BreadCrumb, { BreadCrumbProps } from ".."

type Props = {
    breadCrumbs: Omit<BreadCrumbProps, "hasPrevious">[]
} & StackProps

export default function BreadCrumbGroup({ breadCrumbs, ...rest }: Props) {
    return (
        <Stack h="56px" diplay="flex" alignItems="center" flexWrap={"wrap"} direction={"row"} {...rest}>
            {breadCrumbs.map((breadCrumb, index) => (
                <BreadCrumb
                    key={index + breadCrumb.text}
                    {...breadCrumb}
                    hasPrevious={index !== 0}
                    hasAfter={index !== breadCrumbs.length - 1}
                />
            ))}
        </Stack>
    )
}
