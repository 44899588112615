import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { FieldValues, useForm } from "react-hook-form"
import RadioGroup from "../ui/radio"
import Select from "../ui/select"
import StandardButton from "../ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "../ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import { SurveyQuestionRes } from "@screens/onboarding/surveys/types"

type Props = {
    title: string
    questions: SurveyQuestionRes[]
    currentQuestions: SurveyQuestionRes[]
    currentSurveyAnswersLength: number
    isLoading: boolean
    onSubmit: (answers: number[]) => void
}

const Survey = ({ title, questions, currentQuestions, currentSurveyAnswersLength, isLoading, onSubmit }: Props) => {
    /*
    This chunk of code is a bit complicated so let me explain it.

    The shape of the form data is dynamic because it can be any number of questions,
    yet we need to use react-hook-form to manage the form state and specify what its obj shape is.
    "[key: string]: { value: number; label: string }" is used to specify that the key values don't matter, but the order of the questions do.
    These keys will be autoincrementing integers in string form

    For the part about default values, we need to create the default values object dynamically as well.
    ALL questions will be given a default value here in order to track them. They will only be populated if a default value is given,
    yet they're given a key and a value of undefined if not. They're in the form of { value?: defaultValue, label: defaultValue.toString() || '' } 
    so its accessible to the <RadioGroup> or <Select> components
  */

    const defaultValues = React.useMemo(() => {
        return questions.reduce(
            (acc, question, idx) => {
                if (typeof question.default === "number") {
                    acc[idx.toString()] = {
                        value: question.default,
                        label: question.answers[question.default],
                    }
                } else {
                    acc[idx.toString()] = undefined
                }
                return acc
            },
            {} as { [key: string]: { value: number; label: string } | undefined }
        )
    }, [questions])

    const { handleSubmit, control } = useForm<{
        [key: string]: { value: number; label: string }
    }>({ defaultValues })

    const { t } = useTranslation("common")

    function onSubmitForm(values: FieldValues) {
        // sort the form values by their key (which is the question number) and extract the input values
        // only from the respective chunk of questions being shown at the moment
        const orderedValues = Object.entries(values)
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .reduce((arr: number[], item: [string, { value: number; label: string }]) => {
                const keyNumber = Number(item[0])
                if (
                    keyNumber >= currentSurveyAnswersLength &&
                    keyNumber < currentSurveyAnswersLength + currentQuestions.length
                ) {
                    arr.push(item[1].value)
                }
                return arr
            }, [])
        onSubmit(orderedValues)
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {title}
            </Text>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                {currentQuestions.map((question, idx) => {
                    const isRadio = question.styling?.answers?.type === "radio"
                    const formKey = (idx + currentSurveyAnswersLength).toString()
                    const warnings = question.warning ? question.warning.split("\n") : []
                    return (
                        <Box display="flex" flexDir="column" mb="1.5rem" key={formKey} rowGap="0.5rem">
                            <Box display="flex" flexDir="column" mb="0.75rem">
                                {question?.styling?.question_at_bottom !== true && (
                                    <Text textStyle={question.warning ? "InterSemiboldBody" : "InterRegularBodySmall"}>
                                        {question.question}
                                    </Text>
                                )}
                                {question.note && (
                                    <Text textStyle="InterRegularBodySmall" color="grey.dark.60">
                                        {question.note}
                                    </Text>
                                )}
                                {warnings.map((warning) => (
                                    <Text mb="1.5rem" key={warning} textStyle="InterRegularBodySmall">
                                        {warning}
                                    </Text>
                                ))}
                                {question?.styling?.question_at_bottom === true && (
                                    <Text textStyle={"InterRegularBodySmall"}>{question.question}</Text>
                                )}
                            </Box>
                            {isRadio ? (
                                <RadioGroup
                                    control={control}
                                    isHorizontal={question.styling?.answers?.direction === "row"}
                                    name={formKey}
                                    defaultOption={
                                        question.default
                                            ? {
                                                  value: question.default,
                                                  label: question.answers[question.default],
                                              }
                                            : undefined
                                    }
                                    rules={{ required: t("error.requiredOption") }}
                                    options={
                                        question.answers?.map((answer: string, index: number) => ({
                                            value: index,
                                            label: answer,
                                        })) ?? []
                                    }
                                />
                            ) : (
                                <Select
                                    name={formKey}
                                    defaultValue={
                                        question.default
                                            ? {
                                                  value: question.default,
                                                  label: question.answers[question.default],
                                              }
                                            : undefined
                                    }
                                    options={question.answers?.map((answer: string, index: number) => ({
                                        value: index,
                                        label: answer,
                                    }))}
                                    control={control}
                                    rules={{ required: t("error.requiredOption") }}
                                    size="lg"
                                />
                            )}
                        </Box>
                    )
                })}
                <StandardButton
                    data-testid="continue-survey-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="1.5rem"
                    isLoading={isLoading}
                    w="full"
                >
                    {t("continue")}
                </StandardButton>
            </form>
        </Box>
    )
}

export default Survey
