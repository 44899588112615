import { Box, Image, Text } from "@chakra-ui/react"
import useImage from "@hooks/useImage"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { FC, useMemo } from "react"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { formatDollar } from "@util/stringFormatting"
import { formatShortDateTime } from "@util/dateFormating"

export const StakingRewardsDetails: FC<{ transaction: Transaction }> = ({ transaction }) => {
    const { asset, created_at_pst, increase_currency, increase_amount, fee_currency, fee, total, rate, counter_asset } =
        transaction

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const { symbol } = selectedAsset || {}

    const { image } = useImage("coins/" + symbol?.toLowerCase() + "-mobile.png")

    const { t } = useTranslation(["common", "transactions"])

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.youEarned")}</Text>
            <Box borderBottom={"1px solid"} borderColor={"grey.dark.10"} paddingTop={3} />
            <Box
                display={"flex"}
                flexDirection={"row"}
                py={6}
                alignItems={"center"}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                {image && (
                    <Box h="62px" w="62px" borderRadius="31px" overflow="hidden">
                        <Image src={image} alt={"coin" + symbol?.toLowerCase()} height={"100%"} width={"100%"} />
                    </Box>
                )}
                <Box display={"flex"} flexDirection={"column"} flex={1} mx={3}>
                    <Text textStyle={"ManropeMediumXLarge"}>{t("transactions:transactionDetails.stakingRewards")}</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.dark.70"}>
                        {symbol}
                    </Text>
                </Box>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.amount")}</Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                        {`${formatNumberFixedPrecision(increase_amount, Number(selectedAsset?.precision), true)} ${increase_currency}`}
                    </Text>
                </Box>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box display={"flex"} flexDirection={"column"} paddingBottom={6}>
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                            {t("transactions:transactionDetails.netcoinsFee")}
                        </Text>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        borderBottom={"1px solid"}
                        borderColor={"grey.dark.10"}
                        paddingBottom={6}
                        textAlign={"right"}
                    >
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                            {`${formatNumberFixedPrecision(fee, Number(selectedAsset?.precision), true)} ${fee_currency}`}
                        </Text>
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} paddingTop={6}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.youEarned")}</Text>
                    <Text
                        textAlign={"right"}
                        textStyle={"ManropeSemiboldBody"}
                    >{`${formatNumberFixedPrecision(total, Number(selectedAsset?.precision), true)} ${fee_currency}`}</Text>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} py={6}>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("common:date")}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} textAlign={"right"}>
                        {formatShortDateTime(new Date(created_at_pst))}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
