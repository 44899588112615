import { Link as ChakraLink, Text } from "@chakra-ui/react"

type Props = {
    href: string
    text: string
}

export default function Link({ href, text }: Props) {
    return (
        <ChakraLink
            href={href}
            target="_blank"
            sx={{
                "&:hover": {
                    textDecorationColor: "blue.100 !important",
                    textDecoration: "underline",
                    "& > span": {
                        color: "blue.100",
                    },
                },
                "&:active": {
                    textDecorationColor: "blue.70 !important",
                    textDecoration: "underline",
                    "& > span": {
                        color: "blue.70",
                    },
                },
            }}
        >
            <Text as="span" textStyle="ManropeSemiboldXSmall" color="blue.100">
                {text}
            </Text>
        </ChakraLink>
    )
}
