import React from "react"
import { SubScreenProps } from ".."
import Survey from "@/components/survey"
import AmplitudeClient from "@/sdks/amplitude"
import { SurveyData } from "./types"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import SurveyError from "./surveyError"

type Props = {
    surveys: SurveyData[]
    surveyNames: Array<string>
    surveyErrorCode?: "501" | "502"
    setIsBackVisible: React.Dispatch<React.SetStateAction<boolean>>
    setSurveyErrorCode: React.Dispatch<React.SetStateAction<"501" | "502" | undefined>>
    setCurrentPhase: React.Dispatch<React.SetStateAction<{ name?: REG_SCREENS; num?: number }>>
} & SubScreenProps

export default function Surveys({
    surveys,
    surveyNames,
    setIsBackVisible,
    surveyErrorCode,
    setSurveyErrorCode,
    advancePhase,
    submitSubForm,
    isLoading,
    setCurrentPhase,
    userObj,
}: Props) {
    const [surveyNum, setSurveyNum] = React.useState(0)
    const [surveyCategoryNum, setSurveyCategoryNum] = React.useState(0)
    const [currentSurveyAnswers, setCurrentSurveyAnswers] = React.useState<number[]>([])
    const currentSurveyLength = React.useMemo(() => {
        return Object.keys(surveys[surveyNum].categories).length
    }, [surveyNum])

    const currentQuestionCategory = React.useMemo(
        () => surveys[surveyNum].categories[surveyCategoryNum],
        [surveyCategoryNum, surveyNum, surveys]
    )

    const currentQuestions = React.useMemo(
        () => surveys[surveyNum].questions.filter((question) => question.category === currentQuestionCategory.name),
        [currentQuestionCategory, surveyNum, surveys]
    )

    // cannot go back if failed a survey
    React.useEffect(() => {
        setIsBackVisible(!surveyErrorCode)
    }, [surveyErrorCode])

    async function onSubmit(orderedValues: number[]) {
        // analytics name is dynamic here and can't match enum
        const _analyticsName = `${surveyNames[surveyNum]}_survey_${surveyCategoryNum}` as REGISTRATION_STEP
        AmplitudeClient.logRegistrationEvent(REG_SCREENS.Surveys, _analyticsName)
        // final category of a survey results in submission to server
        if (currentSurveyLength - 1 === surveyCategoryNum) {
            await submitSubForm(
                "/api/v2/survey",
                {
                    type: surveyNames[surveyNum],
                    answers: [...currentSurveyAnswers, ...orderedValues],
                },
                () => {
                    if (surveyNum < surveys.length - 1) {
                        resetSurvey()
                        setSurveyNum(surveyNum + 1)
                        setIsBackVisible(false)
                    }
                }
            )
        } else {
            setCurrentSurveyAnswers((prev) => [...prev, ...orderedValues])
            setSurveyCategoryNum(surveyCategoryNum + 1)
            advancePhase()
            setIsBackVisible(true)
        }
    }

    function resetSurvey() {
        setSurveyCategoryNum(0)
        setCurrentSurveyAnswers([])
        setSurveyErrorCode(undefined)
    }

    function restartSurvey() {
        resetSurvey()
        setCurrentPhase((prev) => {
            const _currentSurveyLength = Object.keys(surveys[surveyNum].categories).length - 1
            const _phaseNumber = prev.num as number
            const newPhaseNumber = _phaseNumber - _currentSurveyLength
            return {
                name: REG_SCREENS.Surveys,
                num: newPhaseNumber,
            }
        })
    }

    const errorMessages = React.useMemo(() => {
        const errorMessagesObject = surveys[surveyNum]?.errorMessages
        if (surveyErrorCode && errorMessagesObject) {
            return errorMessagesObject[surveyErrorCode]
        }
        return undefined
    }, [surveys, surveyNum, surveyErrorCode])

    const errorHeader = React.useMemo(() => {
        const errorHeader = surveys[surveyNum]?.errorHeader
        if (surveyErrorCode && errorHeader) {
            return errorHeader[surveyErrorCode]
        }
        return undefined
    }, [surveys, surveyNum, surveyErrorCode])

    const supportEmail = React.useMemo(() => {
        const supportEmails = surveys[surveyNum]?.supportEmails
        if (supportEmails && userObj?.country) {
            return supportEmails[userObj.country]
        }
        return undefined
    }, [errorMessages, surveys, surveyNum, surveyErrorCode])

    return errorMessages?.length ? (
        <SurveyError
            errorMessages={errorMessages}
            errorCode={surveyErrorCode}
            resetSurvey={restartSurvey}
            errorHeader={errorHeader}
            supportEmail={supportEmail}
        />
    ) : (
        <Survey
            key={`${surveyNames[surveyNum]}-${surveyCategoryNum}`} // need to re-create this component when survey changes
            title={currentQuestionCategory.title}
            questions={surveys[surveyNum].questions}
            currentQuestions={currentQuestions}
            currentSurveyAnswersLength={currentSurveyAnswers.length}
            isLoading={isLoading}
            onSubmit={onSubmit}
        />
    )
}
