import { useState, useEffect, useMemo } from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { Option } from "@components/ui/types"
import OptionCheckBox from "@components/ui/filterOptions/checkBox"
import Searchbar from "@components/ui/searchbar"
import SideDrawer from "@components/sideDrawer"
import FilterDrawerHeader from "@components/sideDrawer/headers/filterDrawerHeader"

type FilterDrawerProps<T extends string | number> = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    orderTypes?: Option<T>[]
    coins: Option<T>[]
    selectedOrderTypes?: Option<T>[]
    setSelectedOrderTypes?: (options: Option<T>[]) => void
    selectedCoins: Option<T>[]
    setSelectedCoins: (options: Option<T>[]) => void
}

export default function FilterDrawer<T extends string | number>({
    isOpen,
    toggleSideDrawerOpen,
    orderTypes,
    coins,
    selectedOrderTypes,
    setSelectedOrderTypes,
    selectedCoins,
    setSelectedCoins,
}: FilterDrawerProps<T>) {
    const { t } = useTranslation(["common"])

    const [localOrderTypes, setLocalOrderTypes] = useState<Option<T>[]>(selectedOrderTypes || [])
    const [localCoins, setLocalCoins] = useState<Option<T>[]>(selectedCoins)
    const [searchTerm, setSearchTerm] = useState<string>("")

    useEffect(() => {
        if (isOpen) {
            setLocalOrderTypes(selectedOrderTypes || [])
            setLocalCoins(selectedCoins)
        }
    }, [isOpen, selectedOrderTypes, selectedCoins])

    const filteredCoins = useMemo(
        () => coins.filter((coin) => coin.label.toLowerCase().includes(searchTerm.toLowerCase())),
        [coins, searchTerm]
    )

    const handleToggleOrderType = (value: Option<T>) => {
        if (localOrderTypes.some((item) => item.value === value.value)) {
            setLocalOrderTypes(localOrderTypes.filter((item) => item.value !== value.value))
        } else {
            setLocalOrderTypes([...localOrderTypes, value])
        }
    }

    const handleToggleCoin = (value: Option<T>) => {
        if (localCoins.some((item) => item.value === value.value)) {
            setLocalCoins(localCoins.filter((item) => item.value !== value.value))
        } else {
            setLocalCoins([...localCoins, value])
        }
    }

    const handleSave = () => {
        if (setSelectedOrderTypes) {
            setSelectedOrderTypes(localOrderTypes)
        }
        setSelectedCoins(localCoins)
        toggleSideDrawerOpen()
    }

    const onClear = () => {
        setLocalOrderTypes([])
        setLocalCoins([])
    }

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            size="full"
            header={
                <FilterDrawerHeader
                    title="Filter Drawer"
                    onClose={toggleSideDrawerOpen}
                    clearButtonLabel="Clear"
                    onClear={onClear}
                />
            }
            footerButton={{
                children: t("save"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE,
                type: "submit",
                onClick: handleSave,
            }}
            contentPadding={4}
        >
            {orderTypes && (
                <Box mb={4} py={3} borderBottom={"1px solid"} borderColor={"grey.light.10"}>
                    <Box px={2} mb={3}>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>Order Type</Text>
                    </Box>
                    <Stack spacing={2}>
                        {orderTypes.map((type) => (
                            <OptionCheckBox
                                key={type.value}
                                option={type}
                                isChecked={localOrderTypes.some((item) => item.value === type.value)}
                                onToggle={() => handleToggleOrderType(type)}
                            />
                        ))}
                    </Stack>
                </Box>
            )}

            <Box as="fieldset" mb={4}>
                <Box px={2} py={3}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>Coin</Text>
                </Box>
                <Box px={2} mb={3}>
                    <Searchbar placeholder="Search Coin" value={searchTerm} onChange={setSearchTerm} />
                </Box>
                <Stack spacing={2}>
                    {filteredCoins.length > 0 ? (
                        filteredCoins.map((coin) => (
                            <OptionCheckBox
                                key={coin.value}
                                option={coin}
                                isChecked={localCoins.some((item) => item.value === coin.value)}
                                onToggle={() => handleToggleCoin(coin)}
                            />
                        ))
                    ) : (
                        <Text>No coins found</Text>
                    )}
                </Stack>
            </Box>
        </SideDrawer>
    )
}
