import React from "react"
import { Box, Text } from "@chakra-ui/react"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import { SubScreenProps } from ".."
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import DetailsTypeCard from "@components/ui/breadcrumbs/detailsType"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { AccountType } from "@redux/account/types"

type FormData = {
    day: number
    month: number
    year: number
}

export default function WhenBorn({ submitSubForm, setUserObj, userObj, isLoading, setIsBackVisible }: SubScreenProps) {
    const { handleSubmit, control } = useForm<FormData>(formatDefaultValue())
    const { t } = useTranslation(["onboarding", "common"])

    const [isUnder18, setIsUnder18] = React.useState(false)

    function formatDefaultValue() {
        if (userObj?.identity?.dob) {
            const dob = userObj.identity.dob.split("-")
            return {
                defaultValues: {
                    day: parseInt(dob[2]),
                    month: parseInt(dob[1]),
                    year: parseInt(dob[0]),
                },
            }
        }
        return undefined
    }

    async function onSubmit(values: FormData) {
        if (determineIfChild(values.year, values.month, values.day)) {
            setIsUnder18(true)
            setIsBackVisible(false)
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhenBorn, REGISTRATION_STEP.UNDER_18)
        } else {
            const day = values.day < 10 && values.day.toString().substring(0, 1) !== "0" ? `0${values.day}` : values.day
            const month =
                values.month < 10 && values.month.toString().substring(0, 1) !== "0" ? `0${values.month}` : values.month
            const dob = `${values.year}-${month}-${day}`
            await submitSubForm("/api/v2/account/identity", { dob }, () => {
                setUserObj((prev) => (!prev ? prev : { ...prev, identity: { ...prev.identity, dob } }))
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhenBorn, REGISTRATION_STEP.DOB)
            })
        }
    }

    function determineIfChild(year: number, month: number, day: number) {
        const today = new Date()
        const birthDate = new Date(year, month - 1, day)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age < 18
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            {isUnder18 ? (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                        {t("whenBorn.under18.title")}
                    </Text>
                    <Text textStyle="InterRegularBody">{t("whenBorn.under18.mainText")}</Text>
                    <StandardButton
                        data-testid="under-18-support-button"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        onClick={() => window.open("mailto:support@gonetcoins.com?subject=Under 18 Account", "_blank")}
                        type="button"
                        w="full"
                        mt="3rem"
                        mb="0.75rem"
                    >
                        {t("contactSupport", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        data-testid="under-18-button"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_OUTLINE}
                        onClick={() => {
                            setIsBackVisible(true)
                            setIsUnder18(false)
                        }}
                        type="button"
                        w="full"
                    >
                        {t("goBack", { ns: "common" })}
                    </StandardButton>
                </>
            ) : (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                        {t("whenBorn.header")}
                    </Text>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box w="full" display="flex" flexDir={{ base: "column", lg: "row" }} gap="1.5rem">
                            <TextInput
                                name="day"
                                label={t("day", { ns: "common" })}
                                placeholder="DD"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: { value: 1, message: t("whenBorn.dayZero") },
                                    max: { value: 31, message: t("whenBorn.day31") },
                                    maxLength: { value: 2, message: t("whenBorn.day31") },
                                }}
                                maxLength={2}
                                size="lg"
                            />
                            <TextInput
                                name="month"
                                label={t("month", { ns: "common" })}
                                placeholder="MM"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: { value: 1, message: t("whenBorn.monthZero") },
                                    max: { value: 12, message: t("whenBorn.month12") },
                                    maxLength: { value: 2, message: t("whenBorn.month12") },
                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: t("error.numbersOnly", { ns: "common" }),
                                    },
                                }}
                                maxLength={2}
                                size="lg"
                            />
                            <TextInput
                                name="year"
                                label={t("year", { ns: "common" })}
                                placeholder="YYYY"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: {
                                        value: 1900,
                                        message: t("extra.past"),
                                    },
                                    max: {
                                        value: new Date().getFullYear(),
                                        message: t("extra.future"),
                                    },
                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: t("error.numbersOnly", { ns: "common" }),
                                    },
                                }}
                                size="lg"
                            />
                        </Box>
                        <StandardButton
                            data-testid="continue-button"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            mt="2.5rem"
                            w="full"
                            isLoading={isLoading}
                        >
                            {t("continue", { ns: "common" })}
                        </StandardButton>
                    </form>
                </>
            )}
        </Box>
    )
}
