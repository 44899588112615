import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import { UserContext } from "@/util/authUserProvider"

export default function NoMoreAttempts() {
    const { t } = useTranslation("onboarding", { keyPrefix: "surveys" })
    const userProvider = React.useContext(UserContext)

    const [loading, setLoading] = React.useState(false)

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("failedTitle")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1rem">
                {t("noMoreAttempts")}
            </Text>
            <Text textStyle="InterRegularBodySmall">
                {t("failedLine2Part1")}
                <Text
                    as="span"
                    color="blue.100"
                    cursor={"pointer"}
                    onClick={() =>
                        window.open("mailto:support@gonetcoins.com?subject=Failed Risk Survey Assessment", "_blank")
                    }
                >
                    support@gonetcoins.com&nbsp;
                </Text>
                <Text as="span">{t("failedLine2Part2")}</Text>
            </Text>
            <StandardButton
                data-testid="restart-button"
                mt="1.5rem"
                w="full"
                type="button"
                isLoading={loading}
                onClick={async () => {
                    setLoading(true)
                    await userProvider.logout()
                    setLoading(false)
                }}
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
            >
                {t("ok")}
            </StandardButton>
        </Box>
    )
}
