export enum ENUM_TAB_VARIANTS {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
}

type STRUCTURE_TAB_VARIANT = {
    normal: STATEFUL_PROPERTIES
    hover: STATEFUL_PROPERTIES
    active: STATEFUL_PROPERTIES
    disabled: STATEFUL_PROPERTIES
    gap: number
    borderRadius: number
    borderWidth: number
    isBottomBorderOnly: boolean
    py?: number
    isFlexGrowing?: boolean
    textWrap?: "wrap" | "nowrap"
}

const TAB_VARIANTS: Record<ENUM_TAB_VARIANTS, STRUCTURE_TAB_VARIANT> = {
    PRIMARY: {
        normal: {
            bg: "white",
            borderColor: "white",
            color: "black",
        },
        hover: {
            bg: "grey.dark.10",
            borderColor: "blue.50",
            color: "black",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "black",
        },
        active: {
            bg: "blue.10",
            borderColor: "blue.10",
            color: "blue.100",
        },
        gap: 0.75,
        borderRadius: 4,
        borderWidth: 2,
        isBottomBorderOnly: false,
        isFlexGrowing: false,
        textWrap: "nowrap",
    },
    SECONDARY: {
        normal: {
            bg: "white",
            borderColor: "grey.light.20",
            color: "black",
        },
        hover: {
            bg: "white",
            borderColor: "blue.100",
            color: "black",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.60",
            color: "black",
        },
        active: {
            bg: "white",
            borderColor: "blue.100",
            color: "black",
        },
        gap: 0,
        borderRadius: 0,
        borderWidth: 3,
        isBottomBorderOnly: true,
        py: 1,
        isFlexGrowing: true,
        textWrap: "wrap",
    },
}

export default TAB_VARIANTS
