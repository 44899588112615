import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import AuthProvider from "@util/AuthProvider"
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import router from "routing"
import theme from "theme"
import { Fonts } from "theme/fonts"
import "./i18n"
import reportWebVitals from "./reportWebVitals"
import { store } from "./store"
import "./styles/index.scss"
import "./styles/extra.scss"

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <ChakraProvider theme={theme}>
                    <ColorModeScript />
                    <Fonts />
                    <RouterProvider router={router} />
                </ChakraProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>
)

reportWebVitals()

// No need for service workers. Unregister here
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
        .then((registration) => {
            registration.unregister()
        })
        .catch((error) => {
            console.error(error.message)
        })
}
