import { Box, useTheme, useToast } from "@chakra-ui/react"
import { LOGIN_SCREENS, REGISTRATION_STEP } from "@screens/onboarding/types"
import Footer from "components/footer"
import { FramerBox } from "components/motion"
import { AnimatePresence } from "framer-motion"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import useWindowDimensions from "hooks/useWindowDimensions"
import React from "react"
import { useTranslation } from "react-i18next"
import AmplitudeClient from "sdks/amplitude"
import { SMALL_SCREEN_WIDTH } from "theme/consts"
import AnimatedBackground from "./animatedBg"
import CreateNewPassword from "./createNewPassword"
import EmailVerify from "./emailVerify"
import ForgotPassword from "./forgotPassword"
import Login from "./login"
import Signup from "./signup"
import SignupLeft from "./signupLeft"

export type FormData = {
    email: string
    password: string
}

export default function Landing() {
    const { width } = useWindowDimensions()
    const theme = useTheme()
    const toast = useToast()
    const { fetchWrapper } = useFetchWrapper()
    const { t } = useTranslation("login")

    const threeTimeouts = React.useRef([
        React.useRef<NodeJS.Timeout>(),
        React.useRef<NodeJS.Timeout>(),
        React.useRef<NodeJS.Timeout>(),
    ])
    const isInitialLoad = React.useRef(true)
    const isTransitions = React.useRef(true)

    // state booleans are separate here to give more control over animation timings
    const [isLogin, setIsLogin] = React.useState(true)
    const [isEmailStage, setIsEmailStage] = React.useState(false)
    const [isForgotPassword, setIsForgotPassword] = React.useState(false)
    const [isCreateNewPassword, setIsCreateNewPassword] = React.useState(
        /^\/password\/reset(\/.*)?$/.test(window.location.pathname)
    )
    const [wasNewPasswordCreated, setNewPasswordCreated] = React.useState(false)
    const [isSignup, setIsSignup] = React.useState(false)
    const [currentEmail, setCurrentEmail] = React.useState("")
    const [isLoading, toggleLoading] = React.useState(false)
    const [bgWidth, setBgWidth] = React.useState("100%")
    const [streakOpacity, setStreakOpacity] = React.useState(0)

    const isMobile = width < theme.breakpoints.xl.split("em")[0] * 16

    async function onSubmitLogin(email: string, password: string) {
        toggleLoading(true)
        fetch("/login", {
            method: "POST",
            credentials: "include",
            headers: {
                "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email,
                password,
                device_id: "123", // todo
            }),
        })
            .then(async (response) => {
                // handle redirect, and redirect errors
                if (response.redirected) {
                    if (response.ok) {
                        window.location.href = response.url
                        return
                    }
                    const data = await response.json()
                    switch (data?.exception) {
                        case "EmailNotVerified":
                            toggleLoading(false)
                            transitionToEmailVerify()
                            return
                        case "UnderReview":
                            window.location.assign("/under-review")
                            return
                        default:
                            return Promise.reject(new Error("unknown error post-login redirect"))
                    }
                }

                toggleLoading(false)
                if (!response.ok) {
                    showErrorToast(response.status, true)
                }
            })
            .catch(() => toggleLoading(false))
    }
    async function onSubmitCreateAccount(email: string, password: string) {
        toggleLoading(true)
        const uuid = crypto.randomUUID().substring(0, 8)
        // Browser fingerprinting is problematic, and not fulled supported/required on our web platform.
        // For now random value used here.
        fetch("/register", {
            method: "POST",
            credentials: "include",
            headers: {
                "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email: email.trim(),
                password: password.trim(),
                device_id: uuid,
            }),
        })
            .then(async (res) => {
                if (!res.ok) {
                    showErrorToast(res.status, false)
                } else {
                    AmplitudeClient.logRegistrationEvent(LOGIN_SCREENS.SignUp, REGISTRATION_STEP.CREATE_ACCOUNT)
                    setCurrentEmail(email)
                    transitionThenEmailVerify()
                }
            })
            .catch(() =>
                toast({
                    title: t("login.error.server"),
                    description: t("signUp.failedCreation"),
                    status: "error",
                    duration: 10000,
                    isClosable: true,
                })
            )
            .finally(() => toggleLoading(false))
    }

    function showErrorToast(code: number, isLogin: boolean) {
        if (!toast.isActive(code)) {
            let content: { title: string; description: string }
            switch (code) {
                case 401: {
                    content = {
                        title: t("login.error.banned"),
                        description: t("login.error.bannedDesc"),
                    }
                    break
                }
                case 419: {
                    content = {
                        title: t("login.error.csrf"),
                        description: t("login.error.csrfDesc"),
                    }
                    break
                }
                // covers 422 and anything else
                default: {
                    content = {
                        title: t("login.error.server"),
                        description: isLogin ? t("login.error.invalid") : t("signUp.failedCreation"),
                    }
                    break
                }
            }
            toast({
                id: code,
                isClosable: true,
                status: "error",
                ...content,
            })
        }
    }

    // analytics
    React.useEffect(() => {
        AmplitudeClient.logScreenEvent(LOGIN_SCREENS.Login)
    }, [])

    // clear timeouts on unmount to avoid memory leaks
    React.useEffect(() => {
        return () => {
            threeTimeouts.current.forEach((timeoutRef) => clearTimeout(timeoutRef.current))
        }
    }, [])

    function transitionToSignup() {
        setIsLogin(false)
        threeTimeouts.current[0].current = setTimeout(() => {
            setBgWidth(isMobile ? "0%" : "50%")
            threeTimeouts.current[1].current = setTimeout(() => {
                setIsSignup(true)
                AmplitudeClient.logScreenEvent(LOGIN_SCREENS.SignUp)
                threeTimeouts.current[2].current = setTimeout(() => {
                    setStreakOpacity(1)
                }, 750)
            }, 750)
        }, 750)
    }

    function transitionToLogin() {
        setIsSignup(false)
        setStreakOpacity(0)
        threeTimeouts.current[0].current = setTimeout(() => {
            setBgWidth("100%")
            AmplitudeClient.logScreenEvent(LOGIN_SCREENS.Login)
            threeTimeouts.current[1].current = setTimeout(() => {
                setIsLogin(true)
            }, 750)
        }, 750)
    }

    function transitionToEmailVerify() {
        setIsLogin(false)
        threeTimeouts.current[0].current = setTimeout(() => {
            setBgWidth(isMobile ? "0%" : "50%")
            threeTimeouts.current[1].current = setTimeout(() => {
                AmplitudeClient.logScreenEvent(LOGIN_SCREENS.SignUp)
                threeTimeouts.current[2].current = setTimeout(() => {
                    setStreakOpacity(0)
                }, 750)
            }, 750)
        }, 750)

        setStreakOpacity(0)
        threeTimeouts.current[0].current = setTimeout(() => {
            setIsEmailStage(true)
            setIsSignup(true)
        }, 1500)
    }

    function transitionThenEmailVerify() {
        setIsSignup(false)
        setStreakOpacity(0)
        threeTimeouts.current[0].current = setTimeout(() => {
            setIsEmailStage(true)
            setIsSignup(true)
        }, 750)
    }

    function transitionThenNavToOnboarding() {
        setIsSignup(false)
        threeTimeouts.current[0].current = setTimeout(() => {
            setBgWidth("0%")
            threeTimeouts.current[1].current = setTimeout(() => {
                window.open("/onboarding", "_self")
            }, 750)
        }, 750)
    }

    async function sendVerificationEmail(type: REGISTRATION_STEP.RESEND_CODE | REGISTRATION_STEP.VERIFY_EMAIL) {
        await fetchWrapper("/email/verification?type=code", Method.GET, null, false, {
            "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
        })
        AmplitudeClient.logRegistrationEvent(LOGIN_SCREENS.EmailVerification, type)
    }

    React.useEffect(() => {
        if (isInitialLoad.current) {
            if (/^\/email\/verify$/.test(window.location.pathname)) {
                transitionToEmailVerify()
            }
            isInitialLoad.current = false
            return
        }

        isTransitions.current = false
        if (isMobile) {
            setBgWidth(isLogin ? "100%" : "0%")
        } else {
            setBgWidth(isLogin ? "100%" : "50%")
        }
        setTimeout(() => {
            isTransitions.current = true
        }, 0)
    }, [isMobile, width])

    return (
        <Box display="flex" h="full" w="full">
            <AnimatedBackground
                bgWidth={bgWidth}
                streakOpacity={streakOpacity}
                transitionTime={isTransitions.current ? 1 : 0}
            />
            <AnimatePresence>
                {isLogin && (
                    <FramerBox
                        paddingRight="1rem"
                        paddingLeft="1rem"
                        paddingBottom={"1rem"}
                        paddingTop={"6rem"}
                        display="flex"
                        flexDir="column"
                        flexGrow={1}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        // @ts-expect-errors stated by Chakra UI with framer-motion
                        transition={{ duration: isTransitions.current ? 1 : 0 }}
                    >
                        <Box maxW={SMALL_SCREEN_WIDTH} w="full" display="flex" flexDir="column" flexGrow={1} m="auto">
                            {isCreateNewPassword ? (
                                <CreateNewPassword
                                    setIsCreateNewPassword={setIsCreateNewPassword}
                                    setWasNewPasswordCreated={setNewPasswordCreated}
                                />
                            ) : isForgotPassword ? (
                                <ForgotPassword setIsForgotPassword={setIsForgotPassword} />
                            ) : (
                                <Login
                                    setIsForgotPassword={setIsForgotPassword}
                                    transition={transitionToSignup}
                                    onSubmit={onSubmitLogin}
                                    wasNewPasswordCreated={wasNewPasswordCreated}
                                    isLoading={isLoading}
                                />
                            )}
                        </Box>
                        <Footer linkColor="blue.70" textColor="bluePurple.30" />
                    </FramerBox>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {isSignup && (
                    <FramerBox
                        display="flex"
                        flexDir="row"
                        flexGrow={1}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        // @ts-expect-errors stated by Chakra UI with framer-motion
                        transition={{ duration: isTransitions.current ? 1 : 0 }}
                    >
                        <Box display={{ base: "none", xl: "flex" }} width="50%" pos="relative">
                            <SignupLeft isEmailStage={isEmailStage} transitionTime={isTransitions.current ? 1 : 0} />
                        </Box>
                        <Box
                            display="flex"
                            flexDir="column"
                            flexGrow={1}
                            width={{ base: "100%", xl: "50%" }}
                            paddingLeft="1rem"
                            paddingRight="1rem"
                            paddingBottom="1rem"
                        >
                            {isEmailStage ? (
                                <EmailVerify
                                    email={currentEmail}
                                    sendVerificationEmail={() => sendVerificationEmail(REGISTRATION_STEP.RESEND_CODE)}
                                    handleSuccess={transitionThenNavToOnboarding}
                                />
                            ) : (
                                <Signup
                                    transition={transitionToLogin}
                                    onSubmit={onSubmitCreateAccount}
                                    isLoading={isLoading}
                                />
                            )}
                            <Footer linkColor="blue.100" textColor="grey.dark.60" />
                        </Box>
                    </FramerBox>
                )}
            </AnimatePresence>
        </Box>
    )
}
