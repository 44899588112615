import { createApi } from "@reduxjs/toolkit/query/react"
import { API_SLICE_NAME, API_PATH, TAG_TYPES } from "./const"
import { baseQuery } from "@util/RTKApi"
import { IAccountDetails, IRestrictionsResponse } from "./types"

export const accountApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(API_PATH.account),
    tagTypes: [TAG_TYPES.accountDetails],
    endpoints: (builder) => ({
        getAccountDetails: builder.query<IAccountDetails, void>({
            query: () => API_PATH.accountDetails,
            transformResponse: (response: { data: IAccountDetails }) => response.data,
        }),
        getAccountBalanceRestrictions: builder.query<IRestrictionsResponse, void>({
            query: () => API_PATH.accountRestrictions,
        }),
    }),
})

export const { useGetAccountDetailsQuery, useGetAccountBalanceRestrictionsQuery } = accountApi
export const { getAccountDetails } = accountApi.endpoints
