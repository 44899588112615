export enum StakeCryptoAssets {
    ETH = "ETH",
    SOL = "SOL",
    ATOM = "ATOM",
}

export enum APIStakeActivityType {
    STAKED = "staked",
    BONDED = "bonded",
    REWARD = "reward",
    UNSTAKED = "unstaked",
    UNBONDED = "unbonded",
}

export enum APIStakeType {
    STAKE = "stake",
    UNSTAKE = "unstake",
}

export type StakingInfo = {
    asset: string
    bonding_period: string
    unbonding_period: string
    min_staking_amount: string
    reward_frequency: string
    unstakable: boolean
    auto_payout: boolean
    earn_unbonding: boolean
    lifetime_rewards: string
    compounds: boolean
    interest: boolean
    interest_range: string // i.e. "3.00 - 4.50%"
}

export interface APIStake {
    uuid: string
    asset: StakeCryptoAssets
    aggregate: string
    type: APIStakeType
    pending: boolean
    value: string
    asset_price: string
    completed_at: string
    completed_timer: number
    created_at: string
}

export interface APIStakeParams {
    aggregate?: boolean
    status?: "active" | "pending" | "total"
    type?: APIStakeType
}
