import TransactionsRow from "@components/transactions/transactionsRow"
import { TransactionCardProps } from ".."
import { formatDollar } from "@util/stringFormatting"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useMemo } from "react"

export const UnstakeCreated: React.FC<TransactionCardProps> = ({ transaction, viewMore, isLast }) => {
    const { increase_currency, increase_amount, asset, status, created_at_pst, rate, counter_asset } = transaction || {}

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const incAmount = useMemo(() => {
        return formatNumberFixedPrecision(increase_amount, Number(data?.precision), true)
    }, [increase_amount, increase_currency, data])

    return (
        <TransactionsRow
            transaction={{
                date: created_at_pst,
                description: `Unstake Created`,
                status,
                mainCurrency: increase_currency,
                mainAmount: incAmount,
                secAmount: formatDollar(String(Number(rate) * Number(increase_amount))),
                secCurrency: counter_asset,
            }}
            handleViewMore={viewMore}
            isLast={isLast}
        />
    )
}
