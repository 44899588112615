import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import { UserContext } from "@/util/authUserProvider"

type Props = {
    errorMessages: string[]
    errorCode?: string
    errorHeader?: string
    supportEmail?: string
    resetSurvey?: () => void
}

const SUPPORT_EMAIL_PLACEHOLDER = "<support-email>"

export default function SurveyError({ errorMessages, errorHeader, errorCode, resetSurvey, supportEmail }: Props) {
    const { t } = useTranslation("onboarding", { keyPrefix: "surveys" })
    const userProvider = React.useContext(UserContext)

    const [loading, setLoading] = React.useState(false)

    const supportString: Array<string> = React.useMemo(() => {
        const str = errorMessages.find((msg: string) => msg.includes(SUPPORT_EMAIL_PLACEHOLDER))
        if (str && supportEmail) {
            const split = str.split(SUPPORT_EMAIL_PLACEHOLDER)
            const insertedSplit = [split[0], SUPPORT_EMAIL_PLACEHOLDER, split[1]]
            return insertedSplit
        }
        return []
    }, [])

    return (
        <Box w="full">
            {errorHeader && (
                <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                    {errorHeader}
                </Text>
            )}
            {errorMessages.map((msg: string) => {
                if (!msg.includes(SUPPORT_EMAIL_PLACEHOLDER)) {
                    return (
                        <Text key={msg} textStyle="InterRegularBodySmall" mb="1rem">
                            {msg}
                        </Text>
                    )
                } else {
                    return <React.Fragment key={msg}></React.Fragment>
                }
            })}
            {supportString.length > 0 && (
                <Text textStyle="InterRegularBodySmall" mb="1rem">
                    {supportString.map((msg: string, i: number) => {
                        if (msg === SUPPORT_EMAIL_PLACEHOLDER && supportEmail) {
                            return (
                                <Text
                                    key={msg}
                                    as="span"
                                    color="blue.100"
                                    cursor={"pointer"}
                                    onClick={() =>
                                        window.open("mailto:" + supportEmail + "?subject=Failed Survey", "_blank")
                                    }
                                >
                                    {/* 2nd part is to not include a space if its follow by punctuation */}
                                    {supportEmail.trim()}
                                    {`${supportString[i + i].length > 1 ? " " : ""}`}
                                </Text>
                            )
                        }
                        return (
                            <Text key={msg} as="span">
                                {msg.trim() ?? ""}&nbsp;
                            </Text>
                        )
                    })}
                </Text>
            )}
            <StandardButton
                data-testid="restart-button"
                mt="2rem"
                w="full"
                type="button"
                isLoading={loading}
                onClick={
                    errorCode === "501"
                        ? resetSurvey
                        : async () => {
                              setLoading(true)
                              await userProvider.logout()
                              setLoading(false)
                          }
                }
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
            >
                {errorCode === "501" ? t("restart") : t("ok")}
            </StandardButton>
        </Box>
    )
}
