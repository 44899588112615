import { Checkbox as ChakraCheckbox, CheckboxProps, Text } from "@chakra-ui/react"

// Used to ensure that checkbox CSS styles are identical across slightly different implementations of the Checkbox component
export const getCheckboxStyles = () => ({
    iconColor: "blue.100",
    colorScheme: "white",
    borderColor: "grey.light.50",
    alignItems: "flex-start",
    _checked: {
        borderColor: "blue.100",
    },
    _hover: {
        borderColor: "blue.70",
    },
    _active: {
        borderColor: "blue.70",
        "* svg": {
            color: "blue.70",
        },
    },
    size: "lg",
    sx: {
        ".chakra-checkbox__control": {
            marginTop: "5px",
        },
        // Respond to parent hover/active states
        [`.checkbox-ancestor-pressable:hover &`]: {
            borderColor: "blue.70",
        },
        [`.checkbox-ancestor-pressable:active &`]: {
            borderColor: "blue.70",
            "* svg": {
                color: "blue.70",
            },
        },
    },
})

type Props = {
    text?: string
    defaultChecked?: boolean
    isChecked: boolean
    setValue?: (isChecked: boolean) => void
    disabled?: boolean
    isInvalid?: boolean
} & CheckboxProps

// NOTE error state is not implemented in this component. There should always be a string above a CTA button somewhere else

export default function Checkbox({
    text,
    isChecked,
    setValue,
    defaultChecked,
    isInvalid,
    disabled = false,
    ...rest
}: Props) {
    const styles = getCheckboxStyles()

    return (
        <ChakraCheckbox
            defaultChecked={defaultChecked ?? false}
            disabled={disabled}
            isChecked={isChecked}
            onChange={setValue ? (e) => setValue(e.target.checked) : undefined}
            {...rest}
            {...styles}
        >
            <Text textStyle="InterRegularBody" color="black">
                {text}
            </Text>
        </ChakraCheckbox>
    )
}
