import { Badge, Box, Button, Flex, HStack, Heading, Text, VStack, useDisclosure } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import SecondaryButton from "@components/ui/buttons/secondary"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FiEye } from "react-icons/fi"
import { MdArrowDownward, MdArrowForwardIos, MdArrowUpward } from "react-icons/md"
import FundDrawer, { FundType } from "./drawerFund"
import useRegion from "@hooks/useRegion"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import { ErrorModal } from "@components/ui/modals/errorModal"
import { TUserState } from "@redux/account/types"
import useSupport from "@hooks/useSupport"
import PlainModal from "@components/ui/modals/plain"
import CopyComponent from "@components/ui/copyField"

export default function Transfer() {
    const { t } = useTranslation(["app", "common"])
    const { data: userData } = useGetAccountDetailsQuery()
    const colorFormatter = useColorFormatConverter()
    const [selectedFundType, setSelectedFundType] = useState<undefined | FundType>()

    const [fundingDrawerOpen, toggleFundingDrawerOpen] = useState(false)
    const [withdrawDrawerOpen, toggleWithdrawDrawerOpen] = useState(false)
    const [dialogError, setDialogError] = useState<{ title: string; description: string } | undefined>(undefined)
    const { evaluateRestriction, isEvaluationState, userState } = useRestrictedNavigation()
    const { isOpen: isModalOpen, onOpen, onClose: onModalClose } = useDisclosure()
    const { isOpen: isIntSecOpen, onOpen: onIntSecOpen, onClose: onIntSecClose } = useDisclosure()
    const cancelIntSecRef = useRef<HTMLButtonElement>(null)
    const { isOpen: isIntAOpen, onOpen: onIntAOpen, onClose: onIntAClose } = useDisclosure()
    const cancelIntARef = useRef<HTMLButtonElement>(null)
    const { onbSupportEmail } = useSupport()

    const interac = userData?.features?.interac

    const TransferViaButton = useCallback((text: string, onClick: () => void) => {
        return (
            <Flex cursor="pointer" onClick={onClick} columnGap="0.25rem" alignItems="center">
                <Text textStyle="ManropeSemiBold" color="blue.100">
                    {text}
                </Text>
                <MdArrowForwardIos color={colorFormatter("blue.100")} size={16} />
            </Flex>
        )
    }, [])

    const { isCAUser: IsCanadian } = useRegion()

    const openFundingDrawer = (fundType: FundType | undefined) => {
        const isRestricted = evaluateRestriction(RESTRICTED_FLOWS.DEPOSIT, undefined, { returnBoolean: true })
        if (isRestricted) {
            return popupError()
        }
        setSelectedFundType(fundType)
        toggleFundingDrawerOpen(true)
    }

    const popupError = () => {
        if (isEvaluationState) {
            setDialogError({
                title: t("buysell.error.evaluation.title"),
                description: t("buysell.error.evaluation.message", { email: onbSupportEmail }),
            })
        } else if (userState === TUserState.VIEW_ONLY) {
            setDialogError({
                title: t("buysell.error.viewOnly.title"),
                description: t("buysell.error.viewOnly.message"),
            })
        }
        onOpen()
    }

    const closePopup = () => {
        setDialogError(undefined)
        onModalClose()
    }

    return (
        <Box>
            <Box display="flex" flexDir={{ base: "column", md: "row" }} rowGap="1rem" columnGap="1.5rem" mb="1.5rem">
                <Box
                    display="flex"
                    flex={1}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-between"}
                    rowGap={"1rem"}
                >
                    <Box color="black">
                        <Text textStyle="ManropeMedium4xLarge">{t("transfer", { ns: "common" })}</Text>
                        <Text textStyle="InterRegularBodySmall">{t("transfers.subtitle")}</Text>
                    </Box>
                </Box>
                <Box display="flex" flex={1} alignItems={"center"} justifyContent={"flex-end"} columnGap="0.5rem">
                    <StandardButton
                        flex={1}
                        type="button"
                        leftIcon={MdArrowDownward}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                        onClick={() => openFundingDrawer(undefined)}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("fund", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        flex={1}
                        type="button"
                        leftIcon={MdArrowUpward}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                        onClick={() => openFundingDrawer(undefined)}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("withdraw", { ns: "common" })}
                    </StandardButton>
                </Box>
            </Box>
            <Text mb="1rem" textStyle="ManropeSemiBoldXLarge" color="black">
                {t("transfers.fundingMethods")}
            </Text>
            <Flex direction={{ base: "column", lg: "row" }} gap={6} width="full" mb={{ base: "2.5rem", lg: "3rem" }}>
                <Box flex="1" borderWidth="1px" borderRadius="lg" p={"1.5rem"} bg="white" borderColor="grey.light.10">
                    <VStack align="stretch">
                        <Box borderBottomWidth={1} borderColor={"grey.light.10"} pb="0.75rem">
                            <Text textStyle="ManropeSemiboldBody" color="black">
                                {t("dollars", { ns: "common" })}
                            </Text>
                        </Box>
                        <Text textStyle="InterSmall" mt="0.75rem" mb="1.5rem" color="black">
                            {t("transfers.fundDollars")}
                        </Text>
                        <Flex
                            display={IsCanadian ? "flex" : "none"}
                            flexDir={{ base: "column", md: "row" }}
                            rowGap="0.75rem"
                            mb="1rem"
                        >
                            <Box flex={1}>
                                <Flex flexDir={{ base: "column", md: "row" }} gap={"0.75rem"} mb={2} mr="0.75rem">
                                    <Text textStyle="ManropeSemiboldBody" color="black">
                                        {t("transfers.interac")}
                                    </Text>
                                    <Badge
                                        textStyle="ManropeSemiBoldNano"
                                        bg="green.light.100"
                                        color="white"
                                        borderRadius="md"
                                        px={2}
                                        py={1}
                                        width="fit-content"
                                        h="fit-content"
                                        textTransform={"capitalize"}
                                    >
                                        {t("common:recommended")}
                                    </Badge>
                                </Flex>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.quick")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.noFee")}
                                </Text>
                            </Box>
                            <Flex
                                flexDir="column"
                                justifyContent={"center"}
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                            >
                                <StandardButton
                                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                    size="sm"
                                    onClick={() => openFundingDrawer(FundType.INTERAC)}
                                    width={"fit-content"}
                                >
                                    {t("transfers.fundInterac")}
                                </StandardButton>
                                <Flex
                                    cursor="pointer"
                                    color="blue.100"
                                    alignItems="center"
                                    justifyContent={"flex-end"}
                                    textStyle="ManropeSemiBoldSmall"
                                    columnGap="0.25rem"
                                    pt={3}
                                    onClick={onIntSecOpen}
                                >
                                    <Text textStyle={"ManropeSemiboldXSmall"}>{t("transfers.securityQA")}</Text>
                                    <FiEye color="blue.100" size={14} />
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            flexDir={{ base: "column", md: "row" }}
                            justifyContent={"space-between"}
                            alignItems={{
                                base: IsCanadian ? undefined : "flex-start",
                                md: IsCanadian ? undefined : "flex-end",
                            }}
                            gap="0.75rem"
                        >
                            <Box>
                                <Text textStyle="ManropeSemiboldBody" color="grey.dark.100" mb={2}>
                                    {t("transfers.wire")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.wireSubtitle")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {IsCanadian ? t("transfers.wireFees") : t("transfers.wireFeesUS")}
                                </Text>
                            </Box>
                            <Flex
                                flexDir={"column"}
                                justifyContent={"center"}
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                                w="fit-content"
                            >
                                {IsCanadian ? (
                                    <StandardButton
                                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                        size="sm"
                                        onClick={() => openFundingDrawer(FundType.WIRE)}
                                    >
                                        {t("transfers.fundWire")}
                                    </StandardButton>
                                ) : (
                                    TransferViaButton(t("transfers.fundWire"), () => openFundingDrawer(FundType.WIRE))
                                )}
                            </Flex>
                        </Flex>
                    </VStack>
                </Box>

                <Box
                    flex="1"
                    h={IsCanadian ? "fit-content" : undefined}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    bg="white"
                    borderColor="grey.light.10"
                >
                    <VStack align="stretch" spacing={4} h="full">
                        <Box borderBottomWidth={1} borderColor={"grey.light.10"} pb="0.75rem">
                            <Text textStyle="ManropeSemiboldBody" color="black">
                                {t("crypto", { ns: "common" })}
                            </Text>
                        </Box>
                        <Flex flex={1} flexDir="column" justifyContent="space-between">
                            <Text textStyle="InterRegular" color="black">
                                {t("transfers.cryptoSubtitle")}
                            </Text>
                            <Flex
                                flexDir={{ base: "column", md: "row" }}
                                rowGap="0.75rem"
                                justifyContent="space-between"
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                                mt={6}
                            >
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.cryptoFees")}
                                </Text>
                                {IsCanadian ? (
                                    <StandardButton
                                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                        size="sm"
                                        onClick={() => openFundingDrawer(FundType.CRYPTO)}
                                        w="fit-content"
                                    >
                                        {t("transfers.fundCrypto")}
                                    </StandardButton>
                                ) : (
                                    TransferViaButton(t("transfers.fundCrypto"), () =>
                                        openFundingDrawer(FundType.CRYPTO)
                                    )
                                )}
                            </Flex>
                        </Flex>
                    </VStack>
                </Box>
            </Flex>
            <Text mb="0.25rem" textStyle="ManropeSemiBoldXLarge" color="black">
                {t("transfers.withdrawalMethods")}
            </Text>
            <Text mb="1rem" textStyle="InterSmall" color="grey.light.80" width="100%" maxW="816px">
                {t("transfers.withdrawDisclaimer")}
            </Text>
            <Flex direction={{ base: "column", lg: "row" }} gap={6} width="full" mb={{ base: "2.5rem", lg: "3rem" }}>
                <Box flex="1" borderWidth="1px" borderRadius="lg" p={"1.5rem"} bg="white" borderColor="grey.light.10">
                    <VStack align="stretch">
                        <Box borderBottomWidth={1} borderColor={"grey.light.10"} pb="0.75rem">
                            <Text textStyle="ManropeSemiboldBody" color="black">
                                {t("dollars", { ns: "common" })}
                            </Text>
                        </Box>
                        <Text textStyle="InterSmall" mt="0.75rem" mb="1.5rem" color="black">
                            {t("transfers.withdrawDollars")}
                        </Text>

                        <Flex
                            display={IsCanadian ? "flex" : "none"}
                            flexDir={{ base: "column", md: "row" }}
                            rowGap="0.75rem"
                            mb="1rem"
                        >
                            <Box flex={1}>
                                <Flex flexDir={{ base: "column", md: "row" }} gap={"0.75rem"} mb={2} mr="0.75rem">
                                    <Text textStyle="ManropeSemiboldBody" color="black">
                                        {t("transfers.interac")}
                                    </Text>
                                    <Badge
                                        textStyle="ManropeSemiBoldNano"
                                        bg="green.light.100"
                                        color="white"
                                        borderRadius="md"
                                        px={2}
                                        py={1}
                                        width="fit-content"
                                        h="fit-content"
                                        textTransform={"capitalize"}
                                    >
                                        {t("common:recommended")}
                                    </Badge>
                                </Flex>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.quick")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.withdrawFees")}
                                </Text>
                            </Box>
                            <Flex
                                flexDir="column"
                                justifyContent={"center"}
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                            >
                                <StandardButton
                                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                    size="sm"
                                    w="fit-content"
                                >
                                    {t("transfers.withdrawInterac")}
                                </StandardButton>
                                <Flex
                                    cursor="pointer"
                                    onClick={onIntAOpen}
                                    color="blue.100"
                                    alignItems="center"
                                    justifyContent={"flex-end"}
                                    columnGap="0.25rem"
                                    textStyle="ManropeSemiBoldSmall"
                                    mt={3}
                                >
                                    <Text textStyle={"ManropeSemiboldXSmall"}>{t("transfers.securityAnswer")}</Text>
                                    <FiEye color="blue.100" size={14} />
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            flexDir={{ base: "column", md: "row" }}
                            alignItems={{
                                base: IsCanadian ? undefined : "flex-start",
                                md: IsCanadian ? undefined : "flex-end",
                            }}
                            justifyContent={"space-between"}
                            gap="0.75rem"
                        >
                            <Box>
                                <Text textStyle="ManropeSemiboldBody" color="grey.dark.100" mb={2}>
                                    {t("transfers.wire")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.wireSubtitle")}
                                </Text>
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {IsCanadian ? t("transfers.wireFees") : t("transfers.wireFeesUS")}
                                </Text>
                            </Box>
                            <Flex
                                flexDir={"column"}
                                justifyContent={"center"}
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                            >
                                {IsCanadian ? (
                                    <StandardButton
                                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                        size="sm"
                                        w="fit-content"
                                    >
                                        {t("transfers.withdrawViaWire")}
                                    </StandardButton>
                                ) : (
                                    TransferViaButton(t("transfers.withdrawViaWire"), () => {})
                                )}
                            </Flex>
                        </Flex>
                    </VStack>
                </Box>
                <Box
                    flex="1"
                    h={IsCanadian ? "fit-content" : undefined}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    bg="white"
                    borderColor="grey.light.10"
                >
                    <VStack align="stretch" spacing={4} h="full">
                        <Box borderBottomWidth={1} borderColor={"grey.light.10"} pb="0.75rem">
                            <Text textStyle="ManropeSemiboldBody" color="black">
                                {t("crypto", { ns: "common" })}
                            </Text>
                        </Box>
                        <Flex flex={1} flexDir="column" justifyContent="space-between">
                            <Text textStyle="InterRegular" color="black">
                                {t("transfers.sendCrypto")}
                            </Text>
                            <Flex
                                flexDir={{ base: "column", md: "row" }}
                                rowGap="0.75rem"
                                justifyContent="space-between"
                                alignItems={{ base: "flex-start", md: "flex-end" }}
                                mt={6}
                            >
                                <Text textStyle="InterSmall" color="grey.light.80">
                                    {t("transfers.cryptoFees")}
                                </Text>
                                {IsCanadian ? (
                                    <StandardButton
                                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                        size="sm"
                                        w="fit-content"
                                    >
                                        {t("transfers.withdrawCrypto")}
                                    </StandardButton>
                                ) : (
                                    TransferViaButton(t("transfers.withdrawCrypto"), () => {})
                                )}
                            </Flex>
                        </Flex>
                    </VStack>
                </Box>
            </Flex>
            <DashboardFooter />
            {fundingDrawerOpen && (
                <FundDrawer
                    isOpen={fundingDrawerOpen}
                    toggleSideDrawerOpen={() => toggleFundingDrawerOpen(false)}
                    fundType={selectedFundType}
                />
            )}
            <ErrorModal
                title={dialogError?.title}
                description={dialogError?.description}
                isOpen={isModalOpen}
                onClose={closePopup}
            />
            <PlainModal
                isOpen={isIntSecOpen}
                cancelRef={cancelIntSecRef}
                title={t("transfers.secQA")}
                onClose={onIntSecClose}
                buttons={[
                    {
                        children: t("common:close"),
                        onClick: onIntSecClose,
                        variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    },
                ]}
            >
                <VStack spacing={6}>
                    <Text textStyle={"InterRegularBody"}>{t("transfers.secQAInfo")}</Text>
                    <Box w="full">
                        <Text textStyle={"InterRegularBody"} mb={3}>
                            {t("transfers.secQ")}:
                        </Text>
                        <CopyComponent text={interac?.secret_question || ""} />
                    </Box>
                    <Box w="full">
                        <Text textStyle={"InterRegularBody"} mb={3}>
                            {t("transfers.secA")}:
                        </Text>
                        <CopyComponent text={interac?.secret_answer || ""} />
                    </Box>
                </VStack>
            </PlainModal>
            <PlainModal
                isOpen={isIntAOpen}
                cancelRef={cancelIntARef}
                title={t("transfers.secQA")}
                onClose={onIntAClose}
                buttons={[
                    {
                        children: t("common:close"),
                        onClick: onIntAClose,
                        variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    },
                ]}
            >
                <VStack spacing={6}>
                    <Text textStyle={"InterRegularBody"}>{t("transfers.secAInfo")}</Text>
                    <Box w="full">
                        <Text textStyle={"InterRegularBody"} mb={3}>
                            {t("transfers.secA")}:
                        </Text>
                        <CopyComponent text={interac?.secret_answer || ""} />
                    </Box>
                </VStack>
            </PlainModal>
        </Box>
    )
}
