import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import DotTyping from "./dot-typing"

type Props = {
    text: string
}

export default function LoadingSideDrawer({ text }: Props) {
    return (
        <Flex flexDir={"column"} flex={1} h="full" rowGap="1rem" justifyContent={"center"} alignItems={"center"}>
            <DotTyping />
            <Text textStyle="ManropeSemiboldBody" color="grey.light.90">
                {text}
            </Text>
        </Flex>
    )
}
