import { useTranslation } from "react-i18next"

export default function usePasswordValidation() {
    const { t } = useTranslation("login", { keyPrefix: "signUp" })

    function passwordValidation(password: string) {
        if (!/[A-Z]/.test(password)) {
            return t("error.capital")
        } else if (!/[a-z]/.test(password)) {
            return t("error.lower")
        } else if (!/[0-9]/.test(password)) {
            return t("error.number")
        } else if (!/[!@#$%^&*(){}]/.test(password)) {
            return t("error.special")
        } else if (!/^[a-zA-Z0-9!@#$%^&*(){}]+$/.test(password)) {
            return t("error.disallowed")
        }

        return null
    }

    return passwordValidation
}
