import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@util/RTKApi"
import { API_PATH, API_SLICE_NAME, TAG_TYPES } from "./const"
import { FetchLimitsParams, LimitsResponse } from "./types"

export const limitsApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(),
    tagTypes: [TAG_TYPES.LIMITS],
    endpoints: (builder) => ({
        getLimits: builder.query<LimitsResponse, FetchLimitsParams>({
            query: (params) => ({
                url: `${API_PATH.FETCH_LIMITS}/${params.currency}`,
                method: "GET",
            }),
        }),
    }),
})

export const { useGetLimitsQuery } = limitsApi
