import marketDetails from "./marketDetails.json"
import trade from "./trade.json"
import openOrders from "./openOrders.json"
import transactions from "./transactions.json"
import buysell from "./buysell.json"
import transfers from "./transfers.json"

export default {
    trade,
    marketDetails,
    openOrders,
    transactions,
    buysell,
    transfers,
}
