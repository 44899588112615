import { Option } from "@components/ui/types"
import { Select as ChakraReactSelect, GroupBase } from "chakra-react-select"
import { CustomComponents, SelectOption } from ".."

type SelectProps = {
    name: string
    options: Array<SelectOption>
    value: Option<any>
    onChange: (_: any) => void
    defaultValue?: SelectOption
    placeholder?: string
    size?: "sm" | "md" | "lg"
    disabled?: boolean
    label?: string
    hint?: string
    width?: number
    [rest: string]: any
}

export default function SelectFormless({
    options,
    name,
    value,
    onChange,
    placeholder,
    size,
    disabled,
    defaultValue,
    width,
    ...rest
}: SelectProps) {
    return (
        <ChakraReactSelect<SelectOption, true, GroupBase<SelectOption>>
            {...rest}
            data-testid={`select-formless-${name}`}
            value={value}
            onChange={onChange}
            name={name}
            options={options}
            placeholder={placeholder}
            components={CustomComponents}
            errorBorderColor="alert.error"
            focusBorderColor="blue.100"
            selectedOptionStyle="color"
            defaultValue={defaultValue}
            size={size ?? "sm"}
            chakraStyles={{
                control: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                    opacity: "1 !important",
                    fontSize: "16px",
                    fontFamily: "Inter",
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    border: "1px solid transparent !important",
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                    visibility: disabled ? "hidden" : "visible",
                }),
                menuList: (provided) => ({
                    ...provided,
                    px: "0.25rem",
                }),
                placeholder: (provided) => ({
                    ...provided,
                }),
                option: (provided, _state) => ({
                    ...provided,
                    backgroundColor: _state.isSelected ? "blue.100" : "white",
                    color: "black",
                    cursor: "pointer",
                    borderRadius: "6px",
                    fontSize: "16px",
                    fontFamily: "Inter",
                    mb: _state.options[_state.options.length - 1] === _state.data ? "0" : "0.25rem",
                    mt: _state.options[0] === _state.data ? "0" : "0.25rem",
                    py: "0.5rem",
                    px: "0.25rem",
                    "& svg": {
                        fill: "black",
                    },
                    _selected: {
                        backgroundColor: "blue.100",
                        color: "white",
                        "& svg": {
                            fill: "white",
                        },
                    },
                    _hover: {
                        backgroundColor: "blue.100",
                        color: "white",
                        "& svg": {
                            fill: "white",
                        },
                    },
                }),
            }}
        />
    )
}
