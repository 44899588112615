import { createApi } from "@reduxjs/toolkit/query/react"
import { SLICE_NAME, API_PATH, TAG_TYPES } from "./const"
import { baseQuery } from "@util/RTKApi"
import { BalancesV3Response } from "./types"
import { createSelector } from "@reduxjs/toolkit"

export const balancesApi = createApi({
    reducerPath: SLICE_NAME,
    baseQuery: baseQuery(API_PATH.BALANCES, "v3"),
    tagTypes: [TAG_TYPES.BALANCES],
    endpoints: (builder) => ({
        getBalances: builder.query<BalancesV3Response, undefined>({
            query: () => "",
            providesTags: (result) => (result ? [{ type: TAG_TYPES.BALANCES }] : []),
        }),
    }),
})

export const { useGetBalancesQuery } = balancesApi
