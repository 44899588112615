import React, { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { addCommasToNumber } from "@util/stringFormatting"
import { StakingInfo } from "@redux/stake/types"
import IconWrapper from "@assets/svgs/IconWrapper"
import { MdSwapVert } from "react-icons/md"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useStakes } from "@hooks/useStakes"

type Props = {
    asset: AssetDetailsFull
    availableAmount: number
    availableQuantity: number
    stakedAmount: number
    stakedQuantity: number
    stakedInfo: StakingInfo
    isCurrencyOrderSwapped: boolean
    toggleCurrencyOrderSwapped: React.Dispatch<React.SetStateAction<boolean>>
}

export default function StakedTab({
    asset,
    stakedAmount,
    stakedQuantity,
    availableAmount,
    availableQuantity,
    stakedInfo,
    isCurrencyOrderSwapped,
    toggleCurrencyOrderSwapped,
}: Props) {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })

    const { assetUnbondingStakesAggregate, assetBondingStakesAggregate } = useStakes(asset.symbol)

    const assetBondingStakesAmount = useMemo(() => {
        if (assetBondingStakesAggregate) {
            return formatNumberFixedPrecision(assetBondingStakesAggregate.value, Number(asset.precision), true)
        }
        return 0
    }, [assetBondingStakesAggregate, asset.precision])

    const assetBondingStakesFiat = useMemo(() => {
        if (assetBondingStakesAggregate && assetBondingStakesAmount) {
            const fiat = Number(assetBondingStakesAmount) * Number(asset.price)
            return addCommasToNumber(fiat.toFixed(2))
        }
        return "0.00"
    }, [assetBondingStakesAmount, asset.price])

    const assetUnbondingStakesAmount = useMemo(() => {
        if (assetUnbondingStakesAggregate) {
            return formatNumberFixedPrecision(assetUnbondingStakesAggregate.value, Number(asset.precision), true)
        }
        return "0"
    }, [assetUnbondingStakesAggregate, asset.precision])

    const assetUnbondingStakesFiat = useMemo(() => {
        if (assetUnbondingStakesAggregate && assetUnbondingStakesAmount) {
            const fiat = Number(assetUnbondingStakesAmount) * Number(asset.price)
            return addCommasToNumber(fiat.toFixed(2))
        }
        return "0.00"
    }, [assetUnbondingStakesAmount, asset.price])

    const LifetimeRewardsAmount = useMemo(() => {
        const num = Number(stakedInfo.lifetime_rewards)
        if (num) {
            return num * Number(asset.price)
        }
        return 0
    }, [stakedInfo])

    const LifetimeRewardsQuantity = useMemo(() => {
        if (stakedInfo.lifetime_rewards) {
            return addCommasToNumber(
                formatNumberFixedPrecision(Number(stakedInfo.lifetime_rewards), Number(asset.precision))
            )
        }
        return 0
    }, [stakedInfo])

    return (
        <Flex flexDir={{ base: "column", lg: "row" }} gap="1rem" mt="2rem">
            <Flex flexDir="column" flex={1} rowGap="1.5rem">
                <Flex flexDir={"column"}>
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                        {t("lifeRewards")}
                    </Text>
                    <Flex alignItems="center" columnGap="0.5rem" py="0.25rem">
                        <Text textStyle="ManropeSemibold2xLarge">
                            {isCurrencyOrderSwapped
                                ? LifetimeRewardsQuantity
                                : `$${addCommasToNumber(LifetimeRewardsAmount.toFixed(2))}`}
                        </Text>
                        <Box cursor="pointer" onClick={(e) => toggleCurrencyOrderSwapped(!isCurrencyOrderSwapped)}>
                            <IconWrapper icon={MdSwapVert} color={"blue.100"} size={18} />
                        </Box>
                    </Flex>
                    <Text textStyle="ManropeMediumBodySmall">
                        {!isCurrencyOrderSwapped
                            ? LifetimeRewardsQuantity
                            : `$${addCommasToNumber(LifetimeRewardsAmount.toFixed(2))}`}
                    </Text>
                </Flex>
                <Flex
                    flexDir={"column"}
                    borderColor="grey.light.10"
                    borderWidth={"1px"}
                    rowGap="0.5rem"
                    p="1rem"
                    borderRadius={"4px"}
                    justifyContent={"center"}
                    flex={1}
                >
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} columnGap="1rem">
                        <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                            {t("estAPR")}
                        </Text>
                        <Text textStyle="ManropeSemiboldXSmall">{stakedInfo.interest_range}</Text>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} columnGap="1rem">
                        <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                            {t("rewardsCycle")}
                        </Text>
                        <Text textStyle="ManropeSemiboldXSmall">
                            {t("everyHours", { num: stakedInfo.reward_frequency })}
                        </Text>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} columnGap="1rem">
                        <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                            {t("autoComp")}
                        </Text>
                        <Text textStyle="ManropeSemiboldXSmall">{stakedInfo.compounds ? "Yes" : "No"}</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box w="full" flex={1}>
                {/* Staked */}
                <Box
                    w="full"
                    display="flex"
                    flexDir="column"
                    borderBottomColor="grey.light.10"
                    borderBottomWidth={"1px"}
                    pb="1rem"
                >
                    <Flex w="full" justifyContent={"space-between"}>
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                            {t("stakedBalance")}
                        </Text>
                        <Text textStyle="ManropeSemiboldBodySmall">
                            {isCurrencyOrderSwapped
                                ? addCommasToNumber(
                                      Number(formatNumberFixedPrecision(stakedQuantity, Number(asset.precision)))
                                  )
                                : `$${addCommasToNumber(stakedAmount)}`}
                        </Text>
                    </Flex>
                    <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                        {!isCurrencyOrderSwapped
                            ? addCommasToNumber(
                                  Number(formatNumberFixedPrecision(stakedQuantity, Number(asset.precision)))
                              )
                            : `$${addCommasToNumber(stakedAmount)}`}
                    </Text>
                </Box>
                {/* Available */}
                <Box
                    w="full"
                    display="flex"
                    flexDir="column"
                    borderBottomColor="grey.light.10"
                    borderBottomWidth={"1px"}
                    py="1rem"
                >
                    <Flex w="full" justifyContent={"space-between"}>
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                            {t("availBalance")}
                        </Text>
                        <Text textStyle="ManropeSemiboldBodySmall">
                            {isCurrencyOrderSwapped
                                ? addCommasToNumber(
                                      formatNumberFixedPrecision(availableQuantity, Number(asset.precision))
                                  )
                                : `$${addCommasToNumber(availableAmount.toFixed(2))}`}
                        </Text>
                    </Flex>
                    <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                        {!isCurrencyOrderSwapped
                            ? addCommasToNumber(formatNumberFixedPrecision(availableQuantity, Number(asset.precision)))
                            : `$${addCommasToNumber(availableAmount.toFixed(2))}`}
                    </Text>
                </Box>

                {/* Bonding TODO values below */}
                <Box
                    w="full"
                    display="flex"
                    flexDir="column"
                    borderBottomColor="grey.light.10"
                    borderBottomWidth={"1px"}
                    py="1rem"
                >
                    <Flex w="full" justifyContent={"space-between"}>
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                            {t("bondingBalance")}
                        </Text>
                        <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                            {isCurrencyOrderSwapped ? assetBondingStakesAmount : `$${assetBondingStakesFiat}`}
                        </Text>
                    </Flex>
                    <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                        {!isCurrencyOrderSwapped ? assetBondingStakesAmount : `$${assetBondingStakesFiat}`}
                    </Text>
                </Box>

                {/* UnBonding TODO values below */}
                <Box
                    w="full"
                    display="flex"
                    flexDir="column"
                    borderBottomColor="grey.light.10"
                    borderBottomWidth={"1px"}
                    py="1rem"
                >
                    <Flex w="full" justifyContent={"space-between"}>
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                            {t("unbondingBalance")}
                        </Text>
                        <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                            {isCurrencyOrderSwapped ? assetUnbondingStakesAmount : `$${assetUnbondingStakesFiat}`}
                        </Text>
                    </Flex>
                    <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                        {!isCurrencyOrderSwapped ? assetUnbondingStakesAmount : `$${assetUnbondingStakesFiat}`}
                    </Text>
                </Box>
            </Box>
        </Flex>
    )
}
