import { accountApi } from "@redux/account/apiSlice"
import { assetDetailsApi } from "@redux/assetsDetails/apiSlice"
import authReducer from "@redux/auth/slice"
import { assetApi } from "@redux/asset/apiSlice"
import { balancesApi } from "@redux/balances/apiSlice"
import { fundApi } from "@redux/fund/apiSlice"
import { limitsApi } from "@redux/limits/apiSlice"
import { ordersApi } from "@redux/orders/apiSlice"
import { transactionsApi } from "@redux/transactions/apiSlice"
import { stakeApi } from "@redux/stake/apiSlice"
import { combineReducers } from "@reduxjs/toolkit"

const appReducer = combineReducers({
    [assetDetailsApi.reducerPath]: assetDetailsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [stakeApi.reducerPath]: stakeApi.reducer,
    [balancesApi.reducerPath]: balancesApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [fundApi.reducerPath]: fundApi.reducer,
    [limitsApi.reducerPath]: limitsApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    auth: authReducer,
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
