import { REG_SCREENS } from "../screens/onboarding/types"
import { IAccountDetails } from "@redux/account/types"

export const establishOnboardingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const riskStatement = data.verification.disclaimers.risk_statement

    const screens: (REG_SCREENS | null)[] = [
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        data.identity.first_name && data.identity.last_name ? null : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.citizenship !== "pending" ? null : REG_SCREENS.IsCanadian,
        data.verification.phone.is_verified ? null : REG_SCREENS.WhatPhone,
        data.verification.phone.is_verified ? null : REG_SCREENS.ConfirmPin,
        needsAddress ? REG_SCREENS.WhereLive : null,
        data.identity.occupation ? null : REG_SCREENS.Occupation,
        surveyScreens > 0 ? REG_SCREENS.SurveyIntro : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        riskStatement?.accepted_at ? null : REG_SCREENS.RiskStatement,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}

export const establishBusinessOnboardingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const needsBusinessAddress =
        !data.business?.address?.city || !data.business?.address?.postal_code || !data.business?.address?.street_address
    const needsBusinessAbout =
        !data.business?.government_id ||
        !data.business?.industry ||
        !data.business?.reg_type ||
        !data.business?.projected_quarter?.amount ||
        !data.business?.projected_quarter.currency
    const riskStatement = data.verification.disclaimers.risk_statement

    const screens: (REG_SCREENS | null)[] = [
        data.region ? null : REG_SCREENS.WhereIncorporated,
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        data.identity.first_name && data.identity.last_name ? null : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.phone ? null : REG_SCREENS.WhatPhone,
        needsAddress ? REG_SCREENS.WhereLive : null,
        data.business?.legal_name ? null : REG_SCREENS.BusinessName,
        data.business?.corporate_role ? null : REG_SCREENS.BusinessRole,
        data.business?.date_incorporated ? null : REG_SCREENS.WhenIncorporated,
        typeof data.business?.is_money_service !== "number" ? REG_SCREENS.IsMoneyService : null,
        needsBusinessAbout ? REG_SCREENS.AboutBusiness : null,
        needsBusinessAddress ? REG_SCREENS.WhereBusinessOffice : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        data.country === "CA" && !riskStatement?.accepted_at ? REG_SCREENS.RiskStatement : null,
        REG_SCREENS.BeInTouch,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}
