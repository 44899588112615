import { createSelector } from "@reduxjs/toolkit"
import { getAccountDetails } from "./apiSlice"
import { TUserState } from "./types"

const accountDetailsResult = getAccountDetails.select()

export const selectAccountDetails = createSelector(accountDetailsResult, (accountDetails) => accountDetails.data)

export const selectIsAccountEvaluationState = createSelector(selectAccountDetails, (accountDetails) => {
    const userState = accountDetails?.userState
    return (
        userState === TUserState.PENDING_BUSINESS_REVIEW ||
        userState === TUserState.PENDING_COMPLIANCE_REVIEW ||
        userState === TUserState.PRE_APPROVED
    )
})
