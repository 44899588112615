import { Box, HStack, Image, Text } from "@chakra-ui/react"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import { FramerBox } from "components/motion"
import useWindowDimensions from "hooks/useWindowDimensions"

type Props = {
    isEmailStage: boolean
    transitionTime: number
}

export default function SignupLeft({ isEmailStage, transitionTime }: Props) {
    const { width, height } = useWindowDimensions()

    return (
        <FramerBox
            pos="absolute"
            top="3.5rem"
            display="flex"
            bottom="0"
            zIndex={1}
            w="full"
            flexDir="column"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // @ts-expect-errors stated by Chakra UI with framer-motion
            transition={{ duration: transitionTime }}
        >
            <Box height="5rem" display="flex" flexGrow={isEmailStage ? 0 : 1} alignItems="flex-start">
                <HStack py="1.5rem" spacing="1rem" display="flex" pl="3.5rem">
                    <NetcoinsLogo color="white" width={31} />
                    <NetcoinsLogoText color="white" width={123} />
                </HStack>
            </Box>
            {isEmailStage ? (
                <Box w="full" display="flex" flexGrow={1} position="relative">
                    <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        width={`${width / 4 + 180}px`}
                        height={`${height / 2 + 168}px`}
                        display="flex"
                        justifyContent={"flex-start"}
                        alignItems="flex-start"
                        zIndex={2}
                    >
                        <Box
                            zIndex={1}
                            h="full"
                            w="full"
                            bgColor="darkBlue.100"
                            clipPath={"polygon(10px 320px, 360px 122px, 100% 40%, 100% 100%, 85% 100%)"}
                        />
                    </Box>
                    <Box display="flex" h="full" justifyContent={"center"} alignItems="center" m="auto" zIndex={3}>
                        <Image
                            src="/images/auth/envelope-code.png"
                            alt="verify-code"
                            objectFit={"contain"}
                            h="335px"
                            w="359px"
                            mb="8.5rem"
                        />
                    </Box>
                </Box>
            ) : (
                <Box display="flex" flexGrow={1} alignItems="flex-end" zIndex={3}>
                    <Image src="/images/auth/landing-chart.png" alt="landing-chart" objectFit={"cover"} />
                </Box>
            )}

            {!isEmailStage && (
                <Box position="absolute" top="8rem" left="6rem" display="flex" flexDir="column" zIndex={2}>
                    <Text textStyle="ManropeRegular7xLarge" color="white">
                        Fast.
                    </Text>
                    <Text textStyle="ManropeRegular7xLarge" color="white">
                        Simple.
                    </Text>
                    <Text textStyle="ManropeRegular7xLarge" color="white">
                        Easy.
                    </Text>
                </Box>
            )}
        </FramerBox>
    )
}
