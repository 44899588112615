import { useGetStakesQuery } from "@redux/stake/apiSlice"
import { APIStake, APIStakeType } from "@redux/stake/types"
import { useMemo } from "react"

export const getAssetAggregate = (aggregateList: Array<APIStake> | undefined, asset?: string) => {
    if (!aggregateList || aggregateList.length <= 0) {
        return undefined
    }

    const assetAggregateList = aggregateList?.filter((s) => s.asset === asset)

    if (assetAggregateList.length <= 0) {
        return undefined
    }

    return assetAggregateList[0]
}

export const useStakes = (asset?: string) => {
    const { data: allStakesAggregate } = useGetStakesQuery({ aggregate: true })
    const { data: allTotalStakedAggregate } = useGetStakesQuery({
        aggregate: true,
        status: "total",
    })
    const { data: allActiveStakesAggregate } = useGetStakesQuery({
        aggregate: true,
        status: "active",
    })
    const { data: allBondingStakesAggregate } = useGetStakesQuery({
        aggregate: true,
        type: APIStakeType.STAKE,
        status: "pending",
    })
    const { data: allUnbondingStakesAggregate } = useGetStakesQuery({
        aggregate: true,
        type: APIStakeType.UNSTAKE,
        status: "pending",
    })

    const assetStakesAggregate = getAssetAggregate(allStakesAggregate, asset)
    const assetTotalStakedAggregate = getAssetAggregate(allTotalStakedAggregate, asset)
    const assetActiveStakesAggregate = getAssetAggregate(allActiveStakesAggregate, asset)
    const assetBondingStakesAggregate = getAssetAggregate(allBondingStakesAggregate, asset)
    const assetUnbondingStakesAggregate = getAssetAggregate(allUnbondingStakesAggregate, asset)

    return {
        allStakesAggregate,
        assetStakesAggregate,
        assetActiveStakesAggregate,
        assetUnbondingStakesAggregate,
        assetBondingStakesAggregate,
        assetTotalStakedAggregate,
        allActiveStakesAggregate,
        allBondingStakesAggregate,
        allUnbondingStakesAggregate,
        allTotalStakedAggregate,
    }
}
