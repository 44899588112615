import { Box, Flex, Text } from "@chakra-ui/react"
import { AssetTableProps, TableHeader, renderDesktopTableContent, renderMobileTableContent } from "../OpenOrders"
import { Transaction } from "@redux/transactions/types"
import { useTranslation } from "react-i18next"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"

export default function ActivityTab({ arr, setSelectedArr, isCurrencyOrderSwapped }: AssetTableProps<Transaction>) {
    const { t } = useTranslation(["common", "app"])
    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    return (
        <Box minHeight="100px">
            <TableHeader isOpenOrder={false} />
            {arr.length ? (
                <>
                    <Box display={{ base: "block", md: "none" }}>
                        {renderMobileTableContent(
                            arr,
                            false,
                            t("viewMore"),
                            setSelectedArr,
                            isCurrencyOrderSwapped,
                            assetDetailsData
                        )}
                    </Box>
                    <Box display={{ base: "none", md: "block" }}>
                        {renderDesktopTableContent(
                            arr,
                            false,
                            t("viewMore"),
                            setSelectedArr,
                            isCurrencyOrderSwapped,
                            assetDetailsData
                        )}
                    </Box>
                </>
            ) : (
                <Flex flex={1} justifyContent={"center"} alignItems="center">
                    <Text color="grey.light.50" textStyle="ManropeSemiBold">
                        {t("marketDetails.emptyTxs", { ns: "app" })}
                    </Text>
                </Flex>
            )}
        </Box>
    )
}
