import {
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    InputRightElement,
    Text,
    TextProps,
    useTheme,
} from "@chakra-ui/react"
import { MdInfoOutline } from "react-icons/md"

export type FormFieldProps = {
    leftElement?: React.ReactElement
    rightElement?: React.ReactElement
    label?: string
    labelProps?: TextProps
    labelElement?: React.ReactElement
    errorMessage?: string
} & InputProps

export const FormField: React.FC<FormFieldProps> = ({
    size,
    label,
    labelProps,
    isRequired,
    labelElement,
    leftElement,
    rightElement,
    isDisabled,
    isInvalid,
    errorMessage,
    ...rest
}) => {
    const theme = useTheme()

    return (
        <>
            {label && (
                <Flex marginBottom={1}>
                    <Text textStyle={"ManropeMediumBodySmall"} {...labelProps}>
                        {label}
                    </Text>
                    {isRequired && (
                        <Text color={"blue.100"} textStyle={"InterSubtitle"} marginX={0.5} lineHeight={"20px"}>
                            *
                        </Text>
                    )}
                    {labelElement}
                </Flex>
            )}
            <InputGroup size={size}>
                {leftElement && (
                    <InputLeftElement width={"auto"} marginLeft={4} pointerEvents="none">
                        {leftElement}
                    </InputLeftElement>
                )}
                <Input
                    paddingStart={leftElement ? 10 : 4}
                    paddingEnd={4}
                    textStyle={"ManropeMediumBody"}
                    color={isDisabled ? "grey.light.50" : "black"}
                    errorBorderColor="red.light.100"
                    focusBorderColor="blue.100"
                    borderColor={isDisabled ? "grey.light.20" : "grey.light.10"}
                    isInvalid={isInvalid || errorMessage !== undefined}
                    {...rest}
                />
                {rightElement && (
                    <InputRightElement width={"auto"} marginRight={4} pointerEvents="none">
                        {rightElement}
                    </InputRightElement>
                )}
            </InputGroup>
            {errorMessage && (
                <Flex alignItems="center" marginTop={1}>
                    <MdInfoOutline
                        style={{ color: theme.colors.red.light["100"], verticalAlign: "middle", width: 14, height: 14 }}
                    />
                    <Text textStyle={"ManropeMediumXSmall"} color="black" marginLeft={2}>
                        {errorMessage}
                    </Text>
                </Flex>
            )}
        </>
    )
}
