import { Flex, Image, Text } from "@chakra-ui/react"
import { Option } from "@components/ui/types"
import useImage from "@hooks/useImage"
export type CoinOptionProps<T extends string | number> = {
    option: Option<T>
    selected: boolean
    onClick: () => void
}

export const CoinOption = <T extends string | number>({ option, selected, onClick }: CoinOptionProps<T>) => {
    const path = "coins/" + option.value.toString().toLowerCase() + "-mobile.png"
    const { image } = useImage(path)
    return (
        <Flex
            align="center"
            p={3}
            cursor="pointer"
            transition="all 0.2s"
            onClick={onClick}
            _hover={{
                bg: "blue.100",
                color: "white",
            }}
        >
            {image && (
                <Image
                    src={image}
                    width={"42px"}
                    height={"42px"}
                    mr={3}
                    alt={`${option.label} icon`}
                    borderRadius="full"
                />
            )}

            <Flex direction="column">
                <Text textStyle={"ManropeSemiboldBodySmall"}>{option.label}</Text>
                {option.desc && (
                    <Text color={"grey.light.50"} textStyle={"ManropeSemiboldXSmall"} _groupHover={{ color: "white" }}>
                        {option.desc}
                    </Text>
                )}
            </Flex>
        </Flex>
    )
}
