import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

type Props = {
    asset?: string
}

export default function MarketDetailsGraph({}: Props) {
    return (
        <Box w="full" h={{ base: "250px", lg: "450px" }} bgColor="bluePurple.10" pos="relative" mb="3rem">
            <Text pos="absolute" top="50%" left="50%" transform="translate(-50%,-50%)" textStyle="ManropeSemiboldBody">
                Graph will go here
            </Text>
        </Box>
    )
}
