import { Flex, Text, Button } from "@chakra-ui/react"

export type Props = {
    currentPage: number
    totalPages: number
    onPreviousPage: () => void
    onNextPage: () => void
}

export default function TablePaginator({ currentPage, totalPages, onPreviousPage, onNextPage }: Props) {
    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center" padding="1rem">
            <Button
                onClick={onPreviousPage}
                isDisabled={currentPage === 1}
                variant="outline"
                px={2}
                py={1}
                size={"sm"}
                textStyle={"ManropeSemiboldBodySmall"}
            >
                Previous
            </Button>
            <Text textStyle={"ManropeSemiboldBodySmall"}>
                Page {currentPage} of {totalPages}
            </Text>
            <Button
                onClick={onNextPage}
                isDisabled={currentPage === totalPages}
                variant="outline"
                px={2}
                py={1}
                size={"sm"}
                textStyle={"ManropeSemiboldBodySmall"}
            >
                Next
            </Button>
        </Flex>
    )
}
