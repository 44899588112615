export const SLICE_NAME = "orders"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    ORDERS: "orders",
    ORDERS_ID: "orders_id",
    QUOTE: "quote",
    LIMIT_QUOTE: "limit_quote",
} as const

export const API_PATH = {
    ORDERS: "orders",
    QUOTE: "quote",
    LIMIT_QUOTE: "order/limit/preview",
    CREATE_LIMIT_ORDER: "order",
    CREATE_INSTANT_ORDER: "execute",
} as const

// This is not available through any API, so it's unfortunately hardcoded here
// TODO: Make this configurable via ENV
export const MINIMUM_ORDER_FIAT_AMOUNT = 10
