import { Box, Text, VStack, Button, List, ListItem, ListIcon } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import useSupport from "@hooks/useSupport"
import { useGetLimitsQuery } from "@redux/limits/apiSlice"
import { DrawerFundContext } from "@screens/dashboard/transfer/drawerFund/DrawerFundContext"
import { formatDollar } from "@util/stringFormatting"
import { useTranslation } from "react-i18next"

export const CAWireDisclaimers: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { caWireDisclaimers, setCAWireDisclaimers, setErrorMessage } = DrawerFundContext.useContainer()
    const { data: limitsData } = useGetLimitsQuery({
        currency: "CAD",
    })

    const depositLimits = limitsData?.wire.deposit

    const { supportEmail } = useSupport()

    const handleCheckboxChange = (name: keyof typeof caWireDisclaimers, isChecked: boolean) => {
        setErrorMessage("")
        setCAWireDisclaimers((prev) => ({ ...prev, [name]: isChecked }))
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithWire", { ns: "transfer" })}</Text>
            <Box my={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("fund.acceptDisclaimers", { ns: "transfer" })}</Text>
            </Box>
            <VStack spacing={6} alignContent={"flex-start"}>
                <Checkbox
                    text={t("fund.cadWireDisclaimers.disclaimer1", {
                        ns: "transfer",
                        depositThreshold: formatDollar(depositLimits?.fee_threshold || ""),
                        depositFee: formatDollar(depositLimits?.fee || ""),
                    })}
                    name="disclaimer1"
                    isChecked={caWireDisclaimers.disclaimer1}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer1", isChecked)}
                />
                <Checkbox
                    text={t("fund.cadWireDisclaimers.disclaimer2", { ns: "transfer" })}
                    name="disclaimer2"
                    isChecked={caWireDisclaimers.disclaimer2}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer2", isChecked)}
                />
                <Checkbox
                    text={t("fund.cadWireDisclaimers.disclaimer3", { ns: "transfer" })}
                    name="disclaimer3"
                    isChecked={caWireDisclaimers.disclaimer3}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer3", isChecked)}
                />
                <Checkbox
                    text={t("fund.cadWireDisclaimers.disclaimer4", { ns: "transfer" })}
                    name="disclaimer3"
                    isChecked={caWireDisclaimers.disclaimer4}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer4", isChecked)}
                />
            </VStack>
            <Text textStyle={"ManropeSemiboldBody"} mt={6} mb={3}>
                {t("fund.doNotAccept", { ns: "transfer" })}
            </Text>
            <List>
                <Text textStyle={"InterRegularBody"}>
                    {"\u2022 "}
                    {t("fund.doNotAcceptOptions.checks", { ns: "transfer" })}
                </Text>
                <Text textStyle={"InterRegularBody"}>
                    {"\u2022 "}
                    {t("fund.doNotAcceptOptions.bankDrafts", { ns: "transfer" })}
                </Text>
                <Text textStyle={"InterRegularBody"}>
                    {"\u2022 "}
                    {t("fund.doNotAcceptOptions.cash", { ns: "transfer" })}
                </Text>
                <Text textStyle={"InterRegularBody"}>
                    {"\u2022 "}
                    {t("fund.doNotAcceptOptions.unknownWire", { ns: "transfer" })}
                </Text>
            </List>
            <Box p={4} borderWidth={1} borderRadius={"md"} borderColor={"grey.light.10"} mt={6}>
                <InfoAlert variant={ENUM_INFO_ALERT_VARIANT.GREEN}>
                    {t("fund.businessAccountApproval", { ns: "transfer" })}
                </InfoAlert>
                <Text mt={2} textStyle={"InterRegularBodySmall"}>
                    {t("fund.businessAccountApprovalInstructions", { ns: "transfer", supportEmail: supportEmail })}
                </Text>
            </Box>
        </Box>
    )
}
