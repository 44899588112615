import { Box, Button, Flex, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import FilterDrawer from "@components/filterDrawer"
import DashboardFooter from "@components/footer/dashboard"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import Checkbox from "@components/ui/checkbox"
import MultiSelect from "@components/ui/multiselect"
import TablePaginator from "@components/ui/paginator/tablePaginator"
import SingleSelect from "@components/ui/select/singleSelect"
import { Option } from "@components/ui/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useGetTransactionsQuery } from "@redux/transactions/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import { RiFilter3Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import DrawerTransactionDetails from "./drawerTransactionDetails"
import TransactionCard from "./transactionCard"

const SortByOptions = [
    { value: "desc", label: "Newest to Oldest" },
    { value: "asc", label: "Oldest to Newest" },
]

const OrderTypeOptions: Option<string>[] = [
    { value: "buy", label: "Buy Orders" },
    { value: "sell", label: "Sell Orders" },
    { value: "deposit", label: "Deposits" },
    { value: "withdraw", label: "Withdrawals" },
    { value: "reward", label: "Rewards" },
    { value: "stake", label: "Staking" },
]

export const Transactions: React.FC = () => {
    const { asset } = useParams()
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [processingState, setProcessingState] = useState(false)

    const [sortByOption, setSortByOption] = useState<Option<string>>(SortByOptions[0])
    const [selectedOrderTypeOptions, setSelectedOrderTypeOptions] = useState<Option<string>[]>([])
    const [selectedCoinOptions, setSelectedCoinOptions] = useState<Option<string>[]>([])

    const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

    const onFilterDrawerOpen = () => setFilterDrawerOpen(true)
    const toggleSideDrawerOpen = () => setFilterDrawerOpen(!isFilterDrawerOpen)

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(1, prev - 1))
    }

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(totalPages, prev + 1))
    }

    const typesFilter = useMemo(() => {
        if (selectedOrderTypeOptions.length === 0) {
            return ""
        }
        return selectedOrderTypeOptions.map((option) => option.value).join(",")
    }, [selectedOrderTypeOptions])

    const assetFilter = useMemo(() => {
        if (selectedCoinOptions.length === 0) {
            return ""
        }
        return selectedCoinOptions.map((option) => option.value).join(",")
    }, [selectedCoinOptions])

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)
    const { data: transactionsData, isLoading } = useGetTransactionsQuery({
        assets: assetFilter,
        types: typesFilter,
        page: currentPage,
        statuses: processingState ? "Processing,Pending" : "",
        limit: 10,
        sort: sortByOption.value as "asc" | "desc",
    })

    const coinOptions: Option<string>[] = useMemo(() => {
        if (assetDetailsData) {
            return Object.keys(assetDetailsData).map((key) => ({
                value: key,
                label: assetDetailsData[key].name,
                desc: assetDetailsData[key].symbol,
            }))
        }

        return []
    }, [assetDetailsData])

    useMemo(() => {
        if (transactionsData) {
            setTotalPages(Math.ceil(transactionsData.total / 10))
        }
    }, [transactionsData])

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const navigateToTrade = () => {
        navigate(ROUTES.DASHBOARD_TRADE)
    }

    const navigateToMarketDetails = () => {
        navigate(buildRouteWithParams(ROUTES.MARKET_DETAILS, { asset: selectedAsset?.symbol || "" }))
    }

    const clearFilters = () => {
        setSelectedOrderTypeOptions([])
        setSelectedCoinOptions([])
        setProcessingState(false)
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [selectedOrderTypeOptions, selectedCoinOptions, processingState, sortByOption])

    return (
        <Box display={"flex"} flexDir={"column"} h="full">
            <FilterDrawer
                isOpen={isFilterDrawerOpen}
                toggleSideDrawerOpen={toggleSideDrawerOpen}
                orderTypes={OrderTypeOptions}
                coins={coinOptions}
                selectedOrderTypes={selectedOrderTypeOptions}
                setSelectedOrderTypes={setSelectedOrderTypeOptions}
                selectedCoins={selectedCoinOptions}
                setSelectedCoins={setSelectedCoinOptions}
            />
            <BreadCrumbGroup
                mb="0.5rem"
                breadCrumbs={[
                    { text: t("trade.trade"), onClick: navigateToTrade },
                    { text: selectedAsset?.name || "", onClick: navigateToMarketDetails },
                    { text: t("transactions.transactions") },
                ]}
            />
            <Box display={"flex"} flexDir={"column"} height={"full"} flexGrow={1}>
                <Box
                    display="flex"
                    flexDir={{ base: "column", md: "column", lg: "row" }}
                    rowGap="1rem"
                    columnGap="1.5rem"
                    mb="1.5rem"
                    alignItems={{ base: "normal", md: "flex-end", lg: "flex-end" }}
                    whiteSpace={"normal"}
                >
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection={{ base: "column", md: "column", lg: "row" }}
                        alignItems="center"
                        justifyContent={"space-between"}
                        rowGap={"1rem"}
                    >
                        <Box flex={1}>
                            <Text textStyle="ManropeMedium4xLarge" mb={2}>
                                {t("transactions.title")}
                            </Text>
                            <Text textStyle={"InterRegularBodySmall"}>{t("transactions.subTitle")}</Text>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    mb={"1.5rem"}
                    justifyContent={"space-between"}
                >
                    <Box display={{ base: "flex", lg: "none" }}>
                        <Button
                            onClick={onFilterDrawerOpen}
                            variant="outline"
                            borderColor="gray.200"
                            borderRadius="md"
                            py={2}
                            px={4}
                            bg="white"
                            _hover={{ bg: "gray.50" }}
                            width="auto"
                        >
                            <Flex align="center">
                                <RiFilter3Fill size={14} />
                                <Text ml={2} textStyle={"ManropeMediumBodySmall"}>
                                    Filters
                                </Text>
                            </Flex>
                        </Button>
                    </Box>
                    <Box display={{ base: "none", lg: "flex" }} flexDirection={"row"} alignItems={"center"}>
                        <Box mr={6}>
                            <Text textStyle={"ManropeMediumBodySmall"}>Filter By</Text>
                        </Box>
                        <Box mr={3}>
                            <MultiSelect
                                selectedOptions={selectedOrderTypeOptions}
                                options={OrderTypeOptions}
                                placeholder="Order Type"
                                setSelectedOptions={setSelectedOrderTypeOptions}
                            />
                        </Box>
                        <Box mr={3}>
                            <MultiSelect
                                selectedOptions={selectedCoinOptions}
                                options={coinOptions}
                                setSelectedOptions={setSelectedCoinOptions}
                                placeholder="Coin"
                                search
                            />
                        </Box>
                        <Box>
                            <Checkbox
                                text="Processing"
                                setValue={() => setProcessingState(!processingState)}
                                isChecked={processingState}
                            />
                        </Box>
                        {(processingState || selectedOrderTypeOptions.length > 0 || selectedCoinOptions.length > 0) && (
                            <Box>
                                <StandardButton
                                    flexGrow={1}
                                    type="button"
                                    leftIcon={MdClose}
                                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE}
                                    onClick={clearFilters}
                                    size="md"
                                    textStyle="ManropeMediumBodySmall"
                                >
                                    Clear Filters
                                </StandardButton>
                            </Box>
                        )}
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Box mr={3} display={{ base: "none", lg: "block" }}>
                            <Text textStyle={"ManropeMediumBodySmall"}>Sort By</Text>
                        </Box>
                        <Box>
                            <SingleSelect
                                options={SortByOptions}
                                preSelected={sortByOption}
                                placeholder="Sort By"
                                onSelect={setSortByOption}
                            />
                        </Box>
                    </Box>
                </Box>
                <Flex flexDir="column" flexGrow={1}>
                    <TableContainer
                        flexGrow={1}
                        overflowY="auto"
                        pos="relative"
                        minHeight="300px"
                        display="flex"
                        flexDir="column"
                        justifyContent={"space-between"}
                    >
                        <Table variant="simple" layout="fixed" w="full" pos="relative">
                            <Thead w="full">
                                <Tr
                                    sx={{
                                        "& th": {
                                            textTransform: "none",
                                        },
                                    }}
                                    borderBottom="1px solid #636366"
                                >
                                    <Th display={{ base: "none", md: "table-cell", lg: "table-cell" }}>Date</Th>
                                    <Th
                                        py={{ base: "0.25rem", sm: "0.5rem", md: "1.5rem", lg: "1.5rem" }}
                                        px={{ base: "0rem", sm: "0rem", md: "1.5rem", lg: "1.5rem" }}
                                    >
                                        Transaction
                                    </Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody w="full" h="full">
                                {transactionsData?.transactions.map((transaction, index) => (
                                    <TransactionCard
                                        key={`${transaction.created_at}-${index}`}
                                        transaction={transaction}
                                        viewMore={() => setSelectedTransaction(transaction)}
                                        isLast={index === transactionsData.transactions.length - 1}
                                    />
                                ))}
                            </Tbody>
                        </Table>
                        {totalPages > 1 && (
                            <TablePaginator
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPreviousPage={handlePreviousPage}
                                onNextPage={handleNextPage}
                            />
                        )}
                    </TableContainer>
                    <DashboardFooter />
                </Flex>

                <Box display="block" w="full">
                    {isLoading ? (
                        <Text mt="5rem" textAlign="center">
                            Loading...
                        </Text>
                    ) : !transactionsData?.transactions?.length ? (
                        <Box textAlign={"center"} mt="5rem">
                            <Box
                                display="inline-block"
                                p="1rem"
                                borderRadius={"0.5rem"}
                                bgColor="grey.dark.10"
                                mb="0.5rem"
                            >
                                <MdOutlineSearch size={24} color={"#48484A"} />
                            </Box>
                            <Text>No results</Text>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <DrawerTransactionDetails
                transaction={selectedTransaction}
                isOpen={!!selectedTransaction}
                toggleSideDrawerOpen={() => setSelectedTransaction(null)}
            />
        </Box>
    )
}
