export const SLICE_NAME = "transactions"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    TRANSACTIONS: "transactions",
} as const

export const API_PATH = {
    TRANSACTIONS: "transactions",
} as const
