import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "@util/RTKApi"
import { API_PATH, API_SLICE_NAME, TAG_TYPES } from "./const"
import {
    CAWireDepositDetailsResponse,
    DepositAddressParams,
    DepositAddressResponse,
    USWireDepositDetailsResponse,
} from "./types"

export const fundApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(),
    tagTypes: [TAG_TYPES.DEPOSIT_ADDRESS, TAG_TYPES.CA_WIRE_DEPOSIT],
    endpoints: (builder) => ({
        getDepositAddress: builder.query<DepositAddressResponse, DepositAddressParams>({
            query: (params) => ({
                url: `${API_PATH.DEPOSIT_ADDRESS}/${params.currency}`,
                method: "GET",
            }),
        }),
        getCAWireDepositDetails: builder.query<CAWireDepositDetailsResponse, undefined>({
            query: () => ({
                url: `${API_PATH.CA_WIRE_DEPOSIT}`,
                method: "GET",
            }),
        }),
        getUSWireDepositDetails: builder.query<USWireDepositDetailsResponse, undefined>({
            query: () => ({
                url: `${API_PATH.US_WIRE_DEPOSIT}`,
                method: "GET",
            }),
        }),
    }),
})

export const { useGetDepositAddressQuery, useGetCAWireDepositDetailsQuery, useGetUSWireDepositDetailsQuery } = fundApi
