import { Box, TableColumnHeaderProps, Text, Th } from "@chakra-ui/react"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { MdOutlineUnfoldMore } from "react-icons/md"

export type Props = {
    text: string
    onClick: () => void
} & TableColumnHeaderProps

export default function ThSorting({ text, onClick, ...rest }: Props) {
    const colorConverter = useColorFormatConverter()

    return (
        <Th {...rest}>
            <Text
                whiteSpace={"pre-wrap"}
                display="inline-block"
                color="grey.dark.70"
                verticalAlign={"middle"}
                mr="0.5rem"
            >
                {text}
            </Text>
            <Box
                onClick={onClick}
                height={"24px"}
                width={"24px"}
                borderRadius="4px"
                display="inline-block"
                verticalAlign={"middle"}
                pos={"relative"}
                cursor="pointer"
                _hover={{ bgColor: "bluePurple.10" }}
                _active={{ bgColor: "bluePurple.20" }}
            >
                <Box pos={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
                    <MdOutlineUnfoldMore size={18} color={colorConverter("grey.dark.80")} />
                </Box>
            </Box>
        </Th>
    )
}
