export const SLICE_NAME = "stake"

export const TAG_TYPES = {
    STAKING_INFO: "stakingInfo",
    STAKES: "stakes",
} as const

export const API_PATH = {
    STAKES: "stake",
} as const
