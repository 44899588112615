export enum ENUM_INFO_ALERT_VARIANT {
    DEFAULT = "DEFAULT",
    BLUE = "BLUE",
    GREEN = "GREEN",
    ORANGE = "ORANGE",
    RED = "RED",
}

type STATEFUL_PROPERTIES = {
    bg: string
    color: string
}

const INFO_ALERT_VARIANTS: Record<ENUM_INFO_ALERT_VARIANT, STATEFUL_PROPERTIES> = {
    DEFAULT: {
        bg: "grey.light.10",
        color: "grey.light.30",
    },
    BLUE: {
        bg: "blue.10",
        color: "blue.100",
    },
    GREEN: {
        bg: "green.light.10",
        color: "green.light.100",
    },
    ORANGE: {
        bg: "yellow.light.10",
        color: "yellow.light.100",
    },
    RED: {
        bg: "red.light.10",
        color: "red.light.100",
    },
}

export default INFO_ALERT_VARIANTS
