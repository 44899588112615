export const SLICE_NAME = "limits"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    LIMITS: "limits",
} as const

export const API_PATH = {
    FETCH_LIMITS: "fund/wire/limits",
} as const
