import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Text, VStack, Flex } from "@chakra-ui/react"
import CopyComponent from "@components/ui/copyField"
import useSupport from "@hooks/useSupport"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import { useTranslation } from "react-i18next"
import { MdOutlineVerifiedUser } from "react-icons/md"

export const InteracInstructions: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { depositEmail } = useSupport()
    const { data: accountData } = useGetAccountDetailsQuery()

    const accountNumber = accountData?.account_number
    const interacDetails = accountData?.features?.interac

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithInterac", { ns: "transfer" })}</Text>
            <VStack spacing={6} alignContent={"flex-start"} mt={7}>
                <Text textStyle={"ManropeSemiboldBody"}>
                    {t("fund.interacInstructions.sendOnline", { ns: "transfer" })}
                </Text>
                <Flex width={"100%"} flexDirection={"column"}>
                    <Text mb={3} textStyle={"InterRegularBody"}>
                        {t("fund.interacInstructions.copyMemo", { ns: "transfer" })}
                    </Text>
                    <CopyComponent text={accountNumber || ""} />
                </Flex>
                <Flex width={"100%"} flexDirection={"column"}>
                    <Text mb={3} textStyle={"InterRegularBody"}>
                        {t("fund.interacInstructions.sendETo", { ns: "transfer" })}
                    </Text>
                    <CopyComponent text={depositEmail || ""} />
                </Flex>
                <VStack spacing={6} padding={6} borderRadius={"md"} borderWidth={1} borderColor={"grey.light.10"}>
                    <Flex w="full">
                        <Box
                            borderRadius={"md"}
                            alignSelf={"stretch"}
                            bg={"green.light.10"}
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                            minW={"72px"}
                            mr={3}
                        >
                            <ReactIcon icon={MdOutlineVerifiedUser} size={44} color={"green.light.100"} />
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBody"}>
                                {t("fund.interacSecurity.securityReq", { ns: "transfer" })}
                            </Text>
                            <Text textStyle={"InterRegularBodySmall"}>
                                {t("fund.interacSecurity.useInfo", { ns: "transfer" })}
                            </Text>
                        </Box>
                    </Flex>
                    <Flex width={"full"} flexDirection={"column"}>
                        <Text mb={3} textStyle={"InterRegularBody"}>
                            {t("fund.interacSecurity.secQ", { ns: "transfer" })}
                        </Text>
                        <CopyComponent text={interacDetails?.secret_question || ""} />
                    </Flex>
                    <Flex width={"full"} flexDirection={"column"}>
                        <Text mb={3} textStyle={"InterRegularBody"}>
                            {t("fund.interacSecurity.secA", { ns: "transfer" })}
                        </Text>
                        <CopyComponent text={interacDetails?.secret_answer || ""} />
                    </Flex>
                </VStack>
            </VStack>
        </Box>
    )
}
