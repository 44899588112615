import { Box, Flex, useBreakpointValue } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import Tabs from "@components/ui/tabs"
import { ENUM_TAB_VARIANTS } from "@components/ui/tabs/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { IOrder } from "@redux/orders/types"
import { StakingInfo } from "@redux/stake/types"
import { Transaction } from "@redux/transactions/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ActivityTab from "./Activity"
import HoldingsTab from "./Holdings"
import OpenOrdersTab from "./OpenOrders"
import StakedTab from "./Staked"
import { formatForPricePrecision } from "@util/numericalFormatting"

export interface TransactionTableProps {
    asset: AssetDetailsFull
    activity: Transaction[]
    openOrders: IOrder[]
    openOrdersTotalAmount?: number
    openOrdersTotalQuantity?: number
    balanceQuantity: number
    balanceAmount: number
    staked: number
    stakingInfo?: StakingInfo
    setSelectedTx: (_: Transaction) => void
    setSelectedOrder: (_: IOrder) => void
    isDrawerOpen: boolean
}

export const AssetStatsTable: React.FC<TransactionTableProps> = ({
    asset,
    staked,
    activity,
    openOrders,
    openOrdersTotalAmount,
    openOrdersTotalQuantity,
    balanceQuantity,
    balanceAmount,
    stakingInfo,
    setSelectedOrder,
    setSelectedTx,
    isDrawerOpen,
}) => {
    const { navigate } = useRestrictedNavigation()
    const { t } = useTranslation(["app", "common"])
    const isMdBreakpoint = useBreakpointValue({ base: false, md: true })

    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [isCurrencyOrderSwapped, toggleCurrencyOrderSwapped] = useState(false)

    const TabHeaders = useMemo(() => {
        return {
            HOLDINGS: isMdBreakpoint && !isDrawerOpen ? t("common:myHoldings") : t("common:holdings"),
            OPENORDERS: isMdBreakpoint && !isDrawerOpen ? t("common:openOrders") : t("common:orders"),
            STAKING: isMdBreakpoint && !isDrawerOpen ? t("common:stakedOrders") : t("common:staked"),
            ACTIVITY: t("common:activity"),
        }
    }, [isMdBreakpoint, isDrawerOpen])

    const ShownOrderTabs = useMemo(() => {
        const arr = Object.values(TabHeaders)
        return stakingInfo ? arr : arr.filter((tab) => tab !== TabHeaders.STAKING)
    }, [stakingInfo, TabHeaders])

    const StakedAmount = useMemo(() => {
        const str = (Number(asset.price) * staked).toFixed(2)
        return Number(str)
    }, [asset.price, staked])

    const AvailableAmount = useMemo(() => {
        // TODO does StakedAmount include bonding and unbonding stakes?
        // TODO incorporate processing funds
        // TODO how to incorporate frozen funds
        return balanceAmount - (openOrdersTotalAmount ?? 0) - StakedAmount
    }, [balanceAmount, StakedAmount])

    const AvailableQuantity = useMemo(() => {
        return AvailableAmount / Number(asset.price)
    }, [AvailableAmount])

    const TabPanels = useMemo(() => {
        return [
            <HoldingsTab
                asset={asset}
                canStake={asset.restrictions.can_stake}
                balanceAmount={balanceAmount}
                balanceQuantity={balanceQuantity}
                availableAmount={AvailableAmount}
                availableQuantity={AvailableQuantity}
                stakedQuantity={staked}
                stakedAmount={StakedAmount}
                openOrdersTotalAmount={openOrdersTotalAmount}
                openOrdersTotalQuantity={openOrdersTotalQuantity}
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                toggleCurrencyOrderSwapped={toggleCurrencyOrderSwapped}
                dayPerf={{ amount: 0, percentage: 0 }} // TODO
            />,
            <OpenOrdersTab
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                arr={openOrders}
                setSelectedArr={setSelectedOrder}
            />,
            stakingInfo ? (
                <StakedTab
                    isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                    toggleCurrencyOrderSwapped={toggleCurrencyOrderSwapped}
                    availableAmount={AvailableAmount}
                    availableQuantity={AvailableQuantity}
                    asset={asset}
                    stakedAmount={StakedAmount}
                    stakedQuantity={staked}
                    stakedInfo={stakingInfo}
                />
            ) : null,
            <ActivityTab
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                arr={activity}
                setSelectedArr={setSelectedTx}
            />,
        ].filter(Boolean) as JSX.Element[]
    }, [
        asset,
        balanceQuantity,
        openOrders,
        staked,
        stakingInfo,
        activity,
        isCurrencyOrderSwapped,
        toggleCurrencyOrderSwapped,
        StakedAmount,
        balanceAmount,
        TabHeaders,
    ])

    const handleNavigation = (tab: string) => {
        if (tab === TabHeaders.ACTIVITY) {
            navigate(buildRouteWithParams(ROUTES.ASSET_TRANSACTIONS, { asset: asset.symbol || "" }))
        } else if (tab === TabHeaders.OPENORDERS) {
            navigate(buildRouteWithParams(ROUTES.ASSET_OPEN_ORDERS, { asset: asset.symbol || "" }))
        }
    }

    return (
        <Flex flexDir={"column"} h="full" w="full">
            <Box flex={1} w="full" alignSelf={{ base: "unset", lg: "flex-end" }}>
                <Tabs
                    tabIndex={currentTabIndex}
                    setTabIndex={setCurrentTabIndex}
                    name={"market-details"}
                    variant={ENUM_TAB_VARIANTS.SECONDARY}
                    tabs={ShownOrderTabs}
                    tabPanels={TabPanels}
                    p="0"
                    h="full"
                />
            </Box>
            {((ShownOrderTabs[currentTabIndex] === TabHeaders.OPENORDERS && openOrders.length) ||
                (ShownOrderTabs[currentTabIndex] === TabHeaders.ACTIVITY && activity.length)) && (
                <Flex justifyContent="center" mt={2}>
                    <StandardButton
                        type="button"
                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                        onClick={() => handleNavigation(ShownOrderTabs[currentTabIndex])}
                        textStyle="ManropeSemiboldBodySmall"
                        size="sm"
                    >
                        {ShownOrderTabs[currentTabIndex] === TabHeaders.OPENORDERS
                            ? t("marketDetails.viewOpenOrders", { asset: asset.symbol.toUpperCase() })
                            : t("marketDetails.viewActivity", { asset: asset.symbol.toUpperCase() })}
                    </StandardButton>
                </Flex>
            )}
        </Flex>
    )
}
