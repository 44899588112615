export const SLICE_NAME = "assetsDetails"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TAG_TYPES = {
    ASSET_DETAILS: "assetsDetails",
} as const

export const API_PATH = {
    ASSETS_DETAILS: "assets/details",
} as const
