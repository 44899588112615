import { useState, useEffect } from "react"
import { ReactNode } from "react"
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent as ChakraDrawerContent,
    Flex,
} from "@chakra-ui/react"
import { motion, AnimatePresence } from "framer-motion"
import StandardButton, { StandardButtonProps } from "components/ui/buttons/standard"
import { HEADER_HEIGHT } from "@components/header/dashboard"

const MotionDrawerContent = motion(ChakraDrawerContent)

type SideDrawerProps = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "full"
    header?: ReactNode
    children: ReactNode
    footerButton?: StandardButtonProps
    extraFooter?: ReactNode
    contentPadding?: number
}

export const SIDE_DRAWER_MAX_WIDTH = 525

export default function SideDrawer({
    isOpen,
    toggleSideDrawerOpen,
    size = "md",
    header,
    children,
    footerButton,
    contentPadding = 8,
    extraFooter,
}: SideDrawerProps) {
    const [shouldRender, setShouldRender] = useState(isOpen)

    useEffect(() => {
        if (isOpen) {
            setShouldRender(true)
        }
    }, [isOpen])

    const handleAnimationComplete = (definition: string) => {
        if (definition === "exit") {
            setShouldRender(false)
        }
    }

    return (
        <Drawer
            isOpen={shouldRender}
            placement="right"
            onClose={toggleSideDrawerOpen}
            size={size}
            closeOnOverlayClick={false}
        >
            <AnimatePresence>
                {isOpen && (
                    <>
                        <DrawerOverlay
                            mt={size === "full" ? `0px` : `${HEADER_HEIGHT}px`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        />
                        <MotionDrawerContent
                            py={contentPadding}
                            initial={{ x: "100%" }}
                            animate={{ x: 0 }}
                            exit={{ x: "100%" }}
                            mt={size === "full" ? `0px` : `${HEADER_HEIGHT}px`}
                            transition={{ duration: 0.75, ease: "easeInOut" }}
                            onAnimationComplete={handleAnimationComplete}
                            maxWidth={size === "md" ? `${SIDE_DRAWER_MAX_WIDTH}px` : "auto"}
                        >
                            <DrawerHeader px={contentPadding} py={0}>
                                {header}
                            </DrawerHeader>
                            <DrawerBody display="flex" py={0} px={contentPadding} overflowY={"auto"}>
                                <Flex flexDir="column" flex={1}>
                                    {children}
                                </Flex>
                            </DrawerBody>
                            <DrawerFooter mt="2rem" px={contentPadding} py={0}>
                                <Box width={"100%"}>
                                    {extraFooter}
                                    <Box width="100%" display="flex" justifyContent="space-between">
                                        {footerButton && (
                                            <StandardButton width={"100%"} {...footerButton}>
                                                {footerButton.children}
                                            </StandardButton>
                                        )}
                                    </Box>
                                </Box>
                            </DrawerFooter>
                        </MotionDrawerContent>
                    </>
                )}
            </AnimatePresence>
        </Drawer>
    )
}
