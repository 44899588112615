import aave from "./aave.png"
import ada from "./ada.png"
import algo from "./algo.png"
import amp from "./amp.png"
import arb from "./arb.png"
import atom from "./atom.png"
import avax from "./avax.png"
import axs from "./axs.png"
import bal from "./bal.png"
import bat from "./bat.png"
import bnb from "./bnb.png"
import bonk from "./bonk.png"
import btc from "./btc.png"
import chz from "./chz.png"
import cro from "./cro.png"
import comp from "./comp.png"
import crv from "./crv.png"
import dai from "./dai.png"
import doge from "./doge.png"
import dot from "./dot.png"
import dydx from "./dydx.png"
import etc from "./etc.png"
import eth from "./eth.png"
import enj from "./enj.png"
import eos from "./eos.png"
import fil from "./fil.png"
import ftm from "./ftm.png"
import gala from "./gala.png"
import grt from "./grt.png"
import hbar from "./hbar.png"
import inj from "./inj.png"
import knc from "./knc.png"
import link from "./link.png"
import lrc from "./lrc.png"
import ltc from "./ltc.png"
import mkr from "./mkr.png"
import mana from "./mana.png"
import near from "./near.png"
import omg from "./omg.png"
import paxg from "./paxg.png"
import qnt from "./qnt.png"
import ren from "./ren.png"
import rune from "./rune.png"
import sand from "./sand.png"
import shib from "./shib.png"
import snx from "./snx.png"
import sol from "./sol.png"
import sui from "./sui.png"
import sushi from "./sushi.png"
import tia from "./tia.png"
import usdc from "./usdc.png"
import usdt from "./usdt.png"
import usdp from "./usdp.png"
import ust from "./ust.png"
import uni from "./uni.png"
import uma from "./uma.png"
import wif from "./wif.png"
import xlm from "./xlm.png"
import xrp from "./xrp.png"
import xtz from "./xtz.png"
import yfi from "./yfi.png"
import zrx from "./zrx.png"
import aave_mobile from "./aave-mobile.png"
import ada_mobile from "./ada-mobile.png"
import algo_mobile from "./algo-mobile.png"
import arb_mobile from "./arb-mobile.png"
import atom_mobile from "./atom-mobile.png"
import avax_mobile from "./avax-mobile.png"
import axs_mobile from "./axs-mobile.png"
import bat_mobile from "./bat-mobile.png"
import bonk_mobile from "./bonk-mobile.png"
import btc_mobile from "./btc-mobile.png"
import chz_mobile from "./chz-mobile.png"
import cro_mobile from "./cro-mobile.png"
import comp_mobile from "./comp-mobile.png"
import crv_mobile from "./crv-mobile.png"
import dai_mobile from "./dai-mobile.png"
import doge_mobile from "./doge-mobile.png"
import dot_mobile from "./dot-mobile.png"
import dydx_mobile from "./dydx-mobile.png"
import etc_mobile from "./etc-mobile.png"
import eth_mobile from "./eth-mobile.png"
import enj_mobile from "./enj-mobile.png"
import eos_mobile from "./eos-mobile.png"
import ftm_mobile from "./ftm-mobile.png"
import gala_mobile from "./gala-mobile.png"
import grt_mobile from "./grt-mobile.png"
import hbar_mobile from "./hbar-mobile.png"
import inj_mobile from "./inj-mobile.png"
import knc_mobile from "./knc-mobile.png"
import link_mobile from "./link-mobile.png"
import lrc_mobile from "./lrc-mobile.png"
import ltc_mobile from "./ltc-mobile.png"
import mkr_mobile from "./mkr-mobile.png"
import mana_mobile from "./mana-mobile.png"
import near_mobile from "./near-mobile.png"
import paxg_mobile from "./paxg-mobile.png"
import qnt_mobile from "./qnt-mobile.png"
import ren_mobile from "./ren-mobile.png"
import rune_mobile from "./rune-mobile.png"
import sand_mobile from "./sand-mobile.png"
import shib_mobile from "./shib-mobile.png"
import sol_mobile from "./sol-mobile.png"
import sui_mobile from "./sui-mobile.png"
import sushi_mobile from "./sushi-mobile.png"
import tia_mobile from "./tia-mobile.png"
import usdc_mobile from "./usdc-mobile.png"
import usdt_mobile from "./usdt-mobile.png"
import uni_mobile from "./uni-mobile.png"
import wif_mobile from "./wif-mobile.png"
import xlm_mobile from "./xlm-mobile.png"
import xrp_mobile from "./xrp-mobile.png"
import xtz_mobile from "./xtz-mobile.png"
import yfi_mobile from "./yfi-mobile.png"
import zrx_mobile from "./zrx-mobile.png"

export const imageMap = {
    aave,
    ada,
    algo,
    amp,
    arb,
    atom,
    avax,
    axs,
    bal,
    bat,
    bnb,
    bonk,
    btc,
    chz,
    cro,
    comp,
    crv,
    dai,
    doge,
    dot,
    dydx,
    etc,
    eth,
    enj,
    eos,
    fil,
    ftm,
    gala,
    grt,
    hbar,
    inj,
    knc,
    link,
    lrc,
    ltc,
    mkr,
    mana,
    near,
    omg,
    paxg,
    qnt,
    ren,
    rune,
    sand,
    shib,
    snx,
    sol,
    sui,
    sushi,
    tia,
    usdc,
    usdt,
    usdp,
    ust,
    uni,
    uma,
    wif,
    xlm,
    xrp,
    xtz,
    yfi,
    zrx,
    aave_mobile,
    ada_mobile,
    algo_mobile,
    arb_mobile,
    atom_mobile,
    avax_mobile,
    axs_mobile,
    bat_mobile,
    bonk_mobile,
    btc_mobile,
    chz_mobile,
    cro_mobile,
    comp_mobile,
    crv_mobile,
    dai_mobile,
    doge_mobile,
    dot_mobile,
    dydx_mobile,
    etc_mobile,
    eth_mobile,
    enj_mobile,
    eos_mobile,
    ftm_mobile,
    gala_mobile,
    grt_mobile,
    hbar_mobile,
    inj_mobile,
    knc_mobile,
    link_mobile,
    lrc_mobile,
    ltc_mobile,
    mkr_mobile,
    mana_mobile,
    near_mobile,
    paxg_mobile,
    qnt_mobile,
    ren_mobile,
    rune_mobile,
    sand_mobile,
    shib_mobile,
    sol_mobile,
    sui_mobile,
    sushi_mobile,
    tia_mobile,
    usdc_mobile,
    usdt_mobile,
    uni_mobile,
    wif_mobile,
    xlm_mobile,
    xrp_mobile,
    xtz_mobile,
    yfi_mobile,
    zrx_mobile,
}
