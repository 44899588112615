import { addThousandSeparator } from "./stringFormatting"

export const MAX_WHOLE_DIGITS = 12
export const FIAT_PRECISION = 2

export const formatCryptoCurrencyPrecision = (
    value: number,
    precision: number = 2,
    removeTrailingZeros?: boolean,
    minPrecision: number = FIAT_PRECISION
) => {
    const minimumPrecision = removeTrailingZeros ? minPrecision : precision

    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: minimumPrecision,
        maximumFractionDigits: precision < minPrecision ? minPrecision : precision,
    }).format(value)
}

export function formatNumberFixedPrecision(
    value: string | number,
    precision: number = 6,
    removeZerosAtTheEnd: boolean = false,
    minPrecision: number = 0
): string {
    // Convert the input value to a number
    const num = typeof value === "string" ? parseFloat(value) : value

    // Check if the value is a valid number
    if (isNaN(num)) {
        return value.toString()
    }

    // Format the number with the specified precision
    let formatted = num.toFixed(precision)

    // Remove trailing zeros if the option is set and ensure minPrecision is respected
    if (removeZerosAtTheEnd) {
        formatted = parseFloat(formatted).toString()
        const decimals = formatted.split(".")[1] || ""
        const decimalsCount = Math.max(minPrecision, decimals.length)
        formatted = num.toFixed(decimalsCount)
    }

    // Separate integer and decimal parts
    let [integerPart, decimalPart] = formatted.split(".")

    // Add commas to the integer part for thousands separators
    integerPart = addThousandSeparator(integerPart)

    // Return the formatted number with commas and the decimal part
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart
}

export const formattedNumToFloat = (numberString: string) => {
    if (!numberString) {
        return 0
    }

    // remove commas from the number string
    const strippedString = numberString.replace(/,/g, "")

    // parse the stripped string as a float
    const floatNumber = parseFloat(strippedString)

    // return the parsed float
    return floatNumber
}

export const removeCommas = (val: string, precision: number) => {
    return val.replace(new RegExp("(\\.\\d{" + precision + "})\\d*", "g"), "$1")
}

export const commaStringToFloat = (val: string, precision: number) => {
    const newVal = removeCommas(val, precision)
    const valueFormattedToFloat = formattedNumToFloat(newVal)
    return valueFormattedToFloat
}

export const formatStringWithCommas = (val: string | undefined, precision: number) => {
    if (!val) {
        return ""
    }

    // Remove all non-numeric and non-decimal characters
    const numericVal = val.replace(/[^0-9.]/g, "")

    // Split the value by the decimal point
    const parts = numericVal.split(".")

    let formattedVal = parts[0] ?? ""
    // Format the integer part of the value with commas
    formattedVal = formattedVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    // Add the decimal part back to the formatted value
    if (parts.length > 1) {
        // Limit the decimal precision to maxPrecision
        formattedVal += `.${(parts[1] ?? "").slice(0, precision)}`
    }

    return formattedVal
}

export function formatForPricePrecision(value: string | number, precision: number | string): number {
    const prec = typeof precision === "string" ? parseInt(precision) : precision
    const str = typeof value === "string" ? parseFloat(value) : value
    return parseFloat(str.toFixed(prec))
}
