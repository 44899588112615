import { createApi } from "@reduxjs/toolkit/query/react"
import { API_SLICE_NAME, API_PATH, TAG_TYPES } from "./const"
import { baseQuery } from "@util/RTKApi"
import { TransactionParams, TransactionsResponse } from "./types"

export const transactionsApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(API_PATH.TRANSACTIONS),
    tagTypes: [TAG_TYPES.TRANSACTIONS],
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionsResponse, TransactionParams>({
            query: ({
                types = "",
                page = 1,
                limit = 10,
                sort = "desc",
                statuses = "",
                assets = "",
            }: TransactionParams) => {
                const params = new URLSearchParams()

                params.append("page", page.toString())
                params.append("limit", limit.toString())

                return `?sort=created_at,${sort}&limit=${limit}&page=${page}&types=${types}&statuses=${statuses}&assets=${assets}`
            },
            providesTags: (result) => (result ? [{ type: TAG_TYPES.TRANSACTIONS }] : []),
        }),
    }),
})

export const { useGetTransactionsQuery } = transactionsApi
